import React from "react";

const PrimaryInput = ({ onValueChange, placeHolder, ...props }) => {
  return (
    <input
      className=" relative  w-full bg-[#F5F7F7] py-2 px-3 border border-[#E0E0E0] rounded placeholder:text-[#999999] text-[14px] leading-6 text-[#666666] outline-[#E2631B]"
      type="text"
      onChange={(e) => onValueChange(e.target.value)}
      placeholder={placeHolder} //"Type tag name here"
      {...props}
    />
  );
};

export { PrimaryInput };
