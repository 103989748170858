import React, { useEffect, useState } from "react";
import Modal from "../../common/Modal";
import SearchNselect from "./searchNselect";
import useAPI from "../../common/UseApi";

export function AddMLS({ handleOpen, open, mlsList, mlsStates }) {
  const { post, get } = useAPI();
  // eslint-disable-next-line
  const [mls, setMls] = useState({});
  const [state, setstate] = useState({});
  const [mls_fields, setsetMls_fields] = useState([]);
  const [page, setPage] = useState(1);

  const addMls = async () => {
    const results = await post(
      "/api/core/account/mls",
      { mls: mls.id },
      () => {}
    ); // For POST request

    if (results) moveToPage2(results?.customer_mls_id);
  };

  const updateMls = () => {
    post(
      "/api/core/account/mls/update_credentials",
      {
        cred_values: mls_fields.map((item) => ({
          cred_value: item.val,
          id: item.id,
        })),
      },
      () => {}
    );
  };

  const moveToPage2 = async (customer_mls_cred) => {
    const credentials = await get(
      `/api/core/account/mls/credentials/${customer_mls_cred}`,
      () => {
        setPage(2);
      }
    );
    if (credentials) setsetMls_fields(credentials);
  };

  useEffect(() => {}, [state]);
  useEffect(() => {}, [mls]);

  const handleChange = (id, event) => {
    // Update the state with the new value for the modified input field
    const newFields = mls_fields.map((field) => {
      if (field.id === id) {
        return { ...field, val: event.target.value };
      }
      return field;
    });

    setsetMls_fields(newFields);
  };

  return (
    <>
      <Modal handleOpen={handleOpen} open={open} title={"Add a new MLS"}>
        {page === 1 && (
          <section>
            <div className="pb-8">
              <p className="font-[600] text-[14px] text-[#999999]">
                Select your state <span className=" text-red-700">*</span>
              </p>
              <SearchNselect
                zIndex={`z-[10]`}
                onValueChange={setstate}
                list={mlsStates}
              />
            </div>

            <div className={"pb-8 "}>
              <p className="font-[600] text-[14px] text-[#999999]">
                Select your Mls <span className=" text-red-700">*</span>
              </p>
              <SearchNselect
                zIndex={`z-[0]`}
                onValueChange={setMls}
                mls={true}
                list={mlsList
                  .filter((obj) => obj.state && obj.state.trim())
                  .filter((obj) => {
                    if (!state.name || state.name === " ") return true;
                    return obj.state && state && obj.state === state?.name;
                  })}
              />
            </div>

            <div>
              <p className="mb-8 leading-5 text-[16px] text-[#666666] font-normal">
                Enter your MLS credentials:{" "}
              </p>

              {/* <div className="">
              <p className="font-[600] text-[14px] text-[#999999]">
                MLS Login <span className=" text-red-700">*</span>
              </p>
              <input required title="" type="text" className="loginInput" />
            </div>

            <div className="">
              <p className="font-[600] text-[14px] text-[#999999]">
                MLS Password <span className=" text-red-700">*</span>
              </p>
              <input
                required
                title=""
                type="password"
                placeholder={""}
                className="loginInput"
              />
            </div> */}
            </div>

            <div className="flex justify-between">
              <button
                className=" flex justify-center items-center py-[6px] px-3 w-[124px] h-[40px] bg-[#E8EDF0] rounded-lg mb-4  "
                onClick={handleOpen}
              >
                <p className="text-[14px] font-[500] leading-[28px] text-[#666666]">
                  Cancel
                </p>
              </button>
              <button
                className=" flex justify-center items-center py-[6px] px-3 w-[124px] h-[40px] bg-[#F27935] hover:bg-[#E2631B] rounded-lg mb-4  "
                onClick={addMls}
              >
                <p className="text-[14px] font-[500] leading-[28px] text-[#FFFFFF]">
                  Next
                </p>
              </button>
            </div>
          </section>
        )}

        {page === 2 && (
          <section className=" overflow-y-scroll max-h-[500px]">
            <div>
              {mls_fields.map((field) => (
                <div key={field.id}>
                  <label
                    className="font-[600] text-[14px] text-[#999999]"
                    htmlFor={field.field}
                  >
                    {field.field}:
                  </label>
                  <input
                    className="loginInput"
                    id={field.field}
                    type={
                      field.field.toLowerCase().includes("password")
                        ? "password"
                        : "text"
                    }
                    value={field.val}
                    onChange={(e) => handleChange(field.id, e)}
                  />
                </div>
              ))}
            </div>

            <div className="flex justify-end">
              <button
                className=" flex justify-center items-center py-[6px] px-3 w-[124px] h-[40px] bg-[#F27935] hover:bg-[#E2631B] rounded-lg mb-4  "
                onClick={updateMls}
              >
                <p className="text-[14px] font-[500] leading-[28px] text-[#FFFFFF]">
                  Update
                </p>
              </button>
            </div>
          </section>
        )}
      </Modal>
    </>
  );
}
