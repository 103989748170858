import React, { useEffect, useState } from "react";
import useAPI from "../../common/UseApi";
import { PrimaryButtons, SecondaryButtons } from "../../common/buttons";

const ExpiredFilters = ({ zipSearh, myZips }) => {
  const [inputValue, setInputValue] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [userZips, setUserZips] = useState([]);
  const { get, post } = useAPI();

  useEffect(() => {
    fetchMyZips();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMyZips = async () => {
    const results = await get(`/api/core/myzips`);

    setUserZips(results.zips);
  };

  const addZip = async (type, locality, state, zip_code) => {
    await post(
      "/api/core/addzips",
      {
        type,
        locality,
        state,
        zip_code,
      },
      () => {
        fetchMyZips();
      }
    );
  };

  const RemoveZip = async (zip_code) => {
    await post(
      "/api/core/removezip",
      {
        zip_code,
      },
      () => {
        fetchMyZips();
      }
    );
  };

  const RemoveAllZip = () => {
    for (let index = 0; index < userZips.length; index++) {
      RemoveZip(userZips[index].zip_code);
    }
  };

  const requestSearch = async () => {
    const results = await get(
      `api/core/zipsearch?term=${inputValue}`,
      () => {}
    );
    setSearchResult(results);
  };

  return (
    <>
      <section className=" min-h-[406px] bg-white rounded-[8px] p-[16px] md:p-[32px] mb-[16px] border-[#EBEBEB] border-[1px]">
        <div className="flex justify-between items-center">
          <p className="whitespace-nowrap text-[#666666] text-[20px] sm:text-[28px] leading-[28px] font-[500] mb-[32px]">
            Expired Filter
          </p>
        </div>

        <section className="flex w-full gap-8 md:flex-row flex-col">
          <div className="flex-[2]">
            <div className="flex w-full gap-4 h-7 items-center mb-6 ">
              <div className=" h-7 ">
                <p className=" whitespace-nowrap text-[#666666] font-[700] text-[16px] ">
                  Search for areas
                </p>
              </div>

              <div className="h-[0px]  min-w-[200] w-full  border-t border-[#E0E0E0]"></div>
            </div>

            <div className="flex-[1] flex gap-3 h-9 my-6">
              <input
                required
                title=" Last Name"
                type="text"
                placeholder={" Search by county, city, or zip code"}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                className="loginInput h-9"
              />

              <PrimaryButtons onClick={requestSearch} label={"Search"} />
            </div>

            <div className=" max-h-[420px] overflow-scroll min-h-[100px] min-w-[200] w-full  border-t border-[#E0E0E0]">
              <div id="expiredSearchResults" className=" w-full h-[500px]">
                {searchResult.map((item, index) => {
                  return (
                    <div className="flex justify-center items-start sm:justify-between min-h-[76px] sm:items-center p-4 gap-2 flex-col sm:flex-row border-b border-[#E0E0E0]">
                      <div>
                        <p className=" font-semibold text-[16px] text-[#666666] leading-5 gap-2">
                          {`${item.locality}  ${
                            item.type === "county" ? "COUNTY" : ""
                          }, ${item.state}`}
                          <span className=" font-bold text-[#F27935] ml-2">
                            {item.zip_code
                              ? item.zip_code
                              : item.max_zip === item.min_zip
                              ? item.min_zip
                              : `${item.min_zip} - ${item.max_zip}`}
                          </span>
                        </p>
                        <p className=" text-[#999999] leading-5 text-[12px] font-normal">
                          {` ${item.zip_count || 1} matching zip code${
                            item.max_zip === item.min_zip ? "" : "s"
                          }`}
                        </p>
                      </div>
                      <SecondaryButtons
                        label={"Add"}
                        onClick={(e) =>
                          addZip(
                            item.type,
                            item.locality,
                            item.state,
                            item?.zip_code
                          )
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="flex-[2]">
            <div className="flex w-full gap-4 h-7 items-center mb-6 ">
              <div className=" h-7 ">
                <p className=" whitespace-nowrap text-[#666666] font-[700] text-[16px] ">
                  Your Expired Zip Codes
                </p>
              </div>

              <div className="h-[0px]  min-w-[200] w-full  border-t border-[#E0E0E0]"></div>
              <p
                className=" whitespace-nowrap text-[#F27935]  hover:text-[#E2631B] cursor-pointer font-[900] text-[16px] "
                onClick={(e) => RemoveAllZip()}
              >
                Clear
              </p>
            </div>
            <div className="h-9 my-6">
              <p className="text-[#999999] font-[400] text-[14px]  ">
                We will only show you expired data for the following areas:{" "}
              </p>
            </div>
            <div className=" max-h-[420px] overflow-scroll min-h-[100px] min-w-[200] w-full  border-t border-[#E0E0E0]">
              <div id="expiredSearchResults" className=" w-full h-[500px]">
                {userZips.map((item, index) => {
                  return (
                    <div className="flex justify-center items-start sm:justify-between min-h-[76px] sm:items-center p-4 gap-2 flex-col sm:flex-row border-b border-[#E0E0E0]">
                      <div>
                        <p className=" font-semibold text-[16px] text-[#666666] leading-5 gap-2">
                          {`${item?.locality}, ${item.state} `}
                          <span className=" font-bold text-[#F27935] ml-2">
                            {item.zip_code}
                          </span>
                        </p>
                        <p className=" text-[#999999] leading-5 text-[12px] font-normal">
                          {item.county} COUNTY
                        </p>
                      </div>
                      <SecondaryButtons
                        label={"Remove"}
                        onClick={(e) => RemoveZip(item.zip_code)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>

        <div className=" flex justify-end gap-3 mt-12">
          <button className="w-[121px] h-[40px] px-3 py-[6px] text-[#666666] bg-[#E8EDF0] text-[14px] font-normal leading-7 rounded-lg">
            Revert Changes
          </button>
          <button className="w-[121px] h-[40px] px-3 py-[6px] bg-[#F27935] text-[#FFFFFF] text-[14px] font-normal leading-7 rounded-lg ">
            Save Changes
          </button>
        </div>
      </section>
    </>
  );
};

export default ExpiredFilters;
