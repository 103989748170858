import React from "react";
import PageLayout from "../components/common/PageLayout";
import { PrimaryButtons } from "../components/common/buttons";
import { Link } from "react-router-dom";
import HandPhone from "../assets/img/Hands - Phone 1.png";

function FouroFour() {
  return (
    <PageLayout>
      <div className="h-[90vh] w-full flex flex-col justify-center items-center gap-2">
        <img src={HandPhone} alt="" />
        <h1 className=" leading-[57px] font-[600] text-[48px] text-[#7A7A7A]  mb-[14px] text-center">
          404 Page Not Found
        </h1>
        <p className="leading-[24px] font-[400] text-[20px] text-[#747474] max-w-[364px] text-center">
          The page you’re looking for doesn’t exist. Let’s get you back on
          track.
        </p>
        <Link to={"/expired"} className="mt-3">
          <PrimaryButtons label={"Go Home"} />
        </Link>
      </div>
    </PageLayout>
  );
}

export default FouroFour;
