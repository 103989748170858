import { useEffect, useState } from "react";

const useMediaQuery = (query) => {
  const [isUp, setIsUp] = useState(false);

  useEffect(() => {
    const matcher = window.matchMedia(query);
    const onChange = (m) => setIsUp(m.matches);
    onChange(matcher);
    matcher.addEventListener("change", onChange);
    return () => matcher.removeEventListener("change", onChange);
  }, [query]);

  return isUp;
};
export default useMediaQuery;
