import React, { useState } from "react";
import PageLayout from "../components/common/PageLayout";
import logo from "../assets/img/landvoiceLogo.png";
import spinner from "../assets/img/spinner-third.svg";
import ErrorMessage from "../components/common/ErrorMessage";
import useAPI from "../components/common/UseApi";
import ErrorBoundary from "../components/utils/error/ErrorBoundary";
//import { useNavigate } from "react-router-dom";
import { NavLink, useParams } from "react-router-dom";
import { OutlineButtons } from "../components/common/buttons";
const ResetPassword = () => {
  const { error, loading, post, clearErrorMessage } = useAPI();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { token } = useParams();
  const [done, setDone] = useState(false);

  //const navigate = useNavigate();

  const handleUsernameChange = (event) => {
    setPassword(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const loginFunc = () => {
    post(
      "/api/auth/password/reset/" + token,
      { new_password1: password, new_password2: confirmPassword },
      () => {
        setDone(true);
      }
    ); // For POST request
  };
  return (
    <ErrorBoundary>
      <PageLayout hideSideBar={true} pageBG={"bg-hero-pattern"}>
        <div className="flex justify-center items-center h-[60px] w-screen top-0 left-0 fixed bg-[#212A33]">
          <img src={logo} width={165} height={28} alt="landvoice logo" />
        </div>

        <div className="flex justify-center items-center w-full min-h-[calc(100vh)] md:h-[calc(100vh)] pt-[60px] overflow-y-scroll">
          {loading ? (
            <div className="h-[348px] w-[420px]  bg-[#FFFFFF] m-4 md:m-8 md:py-16 py-8  px-4 md:px-8 rounded-lg  border-[1px] border-[#E0E0E0]">
              <div className="w-full flex flex-col items-center gap-2 h-[88px] ">
                <p className=" leading-8 text-[28px] font-[600] text-center text-[#666666]">
                  Resetting your password...
                </p>
                <p className="font-[400] text-[16px] leading-[20px] text-[#999999] mb-8">
                  Just a moment! This shouldn’t take long.
                </p>

                <div className="h-[125px] w-full flex justify-center items-center">
                  <img className="animate-spin" src={spinner} alt="spinner" />
                </div>
              </div>
            </div>
          ) : done ? (
            <div className="h-[348px] w-[420px]  bg-[#FFFFFF] m-4 md:m-8 md:py-16 py-8  px-4 md:px-8 rounded-lg  border-[1px] border-[#E0E0E0]">
              <div className="w-full flex flex-col items-center gap-2 h-[88px] ">
                <p className=" leading-8 text-[28px] font-[600] text-center text-[#666666]">
                  Reset successful
                </p>

                {/* <div className="h-[125px] w-full flex justify-center items-center">
                  <img className="animate-spin" src={spinner} alt="spinner" />
                </div> */}
                <NavLink to={"/signin/"}>
                  <OutlineButtons
                    className={"bg-red500"}
                    label={"Go Back To Login"}
                  />
                </NavLink>
              </div>
            </div>
          ) : (
            <div className=" w-[420px] min-h-[50px] bg-[#FFFFFF] m-4 md:m-8 md:py-16 py-8  px-4 md:px-8 rounded-lg  border-[1px] border-[#E0E0E0]">
              <div className="w-full flex flex-col items-center gap-2 h-[88px] ">
                <p className=" leading-8 text-[28px] font-[600] text-center text-[#666666]">
                  Reset your Password!
                </p>
                <p className="font-[400] text-[16px] leading-[20px] text-[#999999] mb-8">
                  Enter a new A Password below{" "}
                </p>
              </div>
              {error ? <ErrorMessage message={"There was an error."} /> : null}
              {error ? (
                <p className="text-[14px] font-[600] leading-[20px] pb-2 text-[#999999]">
                  New Password
                </p>
              ) : null}
              <input
                required
                value={password}
                onChange={(event) => {
                  handleUsernameChange(event);
                  clearErrorMessage(event);
                }}
                title="Password"
                type="password"
                placeholder={"enter new password"}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    loginFunc();
                  }
                }}
                className="loginInput"
              />
              {error ? (
                <p className="text-[14px] font-[600] leading-[20px] pb-2 text-[#999999]">
                  {" "}
                  Confirm New Password
                </p>
              ) : null}
              <input
                required
                value={confirmPassword}
                onChange={(event) => {
                  handlePasswordChange(event);
                  clearErrorMessage(event);
                }}
                type="Password"
                placeholder={"Confirm New Password"}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    loginFunc();
                  }
                }}
                className="loginInput"
              />
              <button
                disabled={
                  password && confirmPassword && password === confirmPassword
                    ? false
                    : true
                }
                className=" w-full h-[40px] bg-[#F27935] hover:bg-[#E2631B] rounded-lg mb-4 disabled:bg-gray-500"
                onClick={loginFunc}
              >
                <p className="text-[14px] font-[600] leading-[28px] text-[#FFFFFF]">
                  Reset Password
                </p>
              </button>
            </div>
          )}
        </div>
      </PageLayout>
    </ErrorBoundary>
  );
};

export default ResetPassword;
