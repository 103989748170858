import React from "react";
import { Link } from "react-router-dom";
import pdfDownload from "../../common/pdfDownload";

const ExpiredScripts = () => {
  const scripts = [
    {
      name: "Expired Objections",
      description: "Responses for working with Expired listings",
      link: "https://cdn.landvoice.com/pdf/Expired+Objection.pdf",
    },
    {
      name: "Expired Steps",
      description: "Understanding and working with Expired Listings.",
      link: "https://cdn.landvoice.com/pdf/Expired+Steps.pdf",
    },
    {
      name: "How to work with Expired listings",
      description: "Steps and scripts for working with Expired listings",
      link: "https://cdn.landvoice.com/pdf/How+to+work+with+expired+listings.pdf",
    },
  ];

  return (
    <section className=" min-h-[406px] bg-white rounded-[8px] p-[32px] mb-[16px] border-[#EBEBEB] border-[1px]">
      <p className=" text-[#666666] text-[28px] leading-[28px] font-[500] mb-[32px]">
        Expired Scripts
      </p>

      <div className="min-h-[282px] ">
        {scripts.map((item, index) => {
          return (
            <div
              className=" min-h-[94px] rounded-[8px] border-b-[1px] border-[#EBEBEB] p-[16px] flex justify-between flex-col sm:flex-row"
              key={`Expired${index}`}
            >
              <div>
                <Link to={`${item.link}`} target="_blank">
                  {" "}
                  <p className="text-[20px] text-[#666666] font-[600] leading-[28px] md:leading-[28px] cursor-pointer">
                    {item.name}
                  </p>
                </Link>
                <p className="text-[16px] text-[#666666] font-[400] leading-[28px] md:leading-[28px]">
                  {item.description}
                </p>
              </div>

              <Link
                onClick={() => {
                  pdfDownload(item.link, item.name);
                }}
                className=" mt-[16px] md:mt-[0px] h-[40px] w-[99px] bg-[#E8EDF0] rounded-[8px] px-[16px] py-[6px] flex justify-center items-center hover:bg-[#D5DDE1]"
              >
                <p className=" text-[14px] leading-[28px] font-[400] text-[#272829]">
                  {"Download "}
                </p>
              </Link>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default ExpiredScripts;
