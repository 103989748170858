import React from "react";
import { FaTimesCircle } from "react-icons/fa";

const Modal = ({
  children,
  handleOpen,
  open,
  title,
  mxWidth = "md:max-w-[695px] ",
}) => {
  return (
    <>
      {open ? (
        <div
          className={
            "fixed  left-0 top-0 right-0 bottom-0 w-screen h-screen z-40 flex justify-center p-4"
          }
        >
          <div
            onClick={handleOpen}
            className=" absolute left-0 top-0 right-0 bottom-0 w-screen h-screen z-50 bg-black opacity-[0.75] "
          >
            {" "}
          </div>
          <div
            id="modal_content"
            className={
              " my-auto m-4  min-w-[250px]   rounded-lg  w-full  md:min-h-[200px] top-[80px] mx-auto bg-white z-50 p-4 lg:p-8 " +
              mxWidth
            }
          >
            <div className=" flex justify-between pb-8">
              <p className=" text-[28px] text-[#666666]">{title}</p>{" "}
              <FaTimesCircle
                onClick={handleOpen}
                className=" h-8 w-8 text-[#666666] hover:text-[#E2631B]"
              />
            </div>
            {children}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Modal;
