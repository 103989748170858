import React, { useEffect, useState } from "react";
import Modal from "../../common/Modal";
import useAPI from "../../common/UseApi";

const EditMls = ({ handleOpen, open, customer_mls_cred }) => {
  const { post, get } = useAPI();
  const [mls_fields, setsetMls_fields] = useState([]);

  const updateMls = () => {
    post(
      "/api/core/account/mls/update_credentials",
      {
        cred_values: mls_fields.map((item) => ({
          cred_value: item.val,
          id: item.id,
        })),
      },
      () => {
        handleOpen();
      }
    );
  };
  useEffect(() => {
    const getCreditials = async () => {
      const credentials = await get(
        `/api/core/account/mls/credentials/${customer_mls_cred}`,
        () => {}
      );
      if (credentials) setsetMls_fields(credentials);
    };

    getCreditials();
    // eslint-disable-next-line
  }, [customer_mls_cred]);

  const handleChange = (id, event) => {
    // Update the state with the new value for the modified input field
    const newFields = mls_fields.map((field) => {
      if (field.id === id) {
        return { ...field, val: event.target.value };
      }
      return field;
    });

    setsetMls_fields(newFields);
  };

  return (
    <>
      <Modal handleOpen={handleOpen} open={open} title={"Edit MLS"}>
        <div>
          <p className="mb-8 leading-5 text-[16px] text-[#666666] font-normal">
            Enter your MLS credentials:{" "}
          </p>
        </div>

        <section className=" overflow-y-scroll max-h-[500px]">
          <div>
            {mls_fields.map((field) => (
              <div key={field.id}>
                <label
                  className="font-[600] text-[14px] text-[#999999]"
                  htmlFor={field.field}
                >
                  {field.field}:
                </label>
                <input
                  className="loginInput"
                  id={field.field}
                  type={
                    field.field.toLowerCase().includes("password")
                      ? "password"
                      : "text"
                  }
                  value={field.val}
                  onChange={(e) => handleChange(field.id, e)}
                />
              </div>
            ))}
          </div>
        </section>

        <div className="flex justify-between">
          <button
            className=" flex justify-center items-center py-[6px] px-3 w-[124px] h-[40px] bg-[#E8EDF0] rounded-lg mb-4  "
            onClick={() => handleOpen(0, false)}
          >
            <p className="text-[14px] font-[500] leading-[28px] text-[#666666]">
              Cancel
            </p>
          </button>
          <button
            className=" flex justify-center items-center py-[6px] px-3 w-[124px] h-[40px] bg-[#F27935] hover:bg-[#E2631B] rounded-lg mb-4  "
            onClick={() => {
              updateMls();
            }}
          >
            <p className="text-[14px] font-[500] leading-[28px] text-[#FFFFFF]">
              Update
            </p>
          </button>
        </div>
      </Modal>
    </>
  );
};

export default EditMls;
