import React from "react";

const CustomCheckbox = ({ checked, onChange, title }) => {
  return (
    <label className="inline-flex items-center cursor-pointer">
      <div
        className={`relative w-5 h-5 border-2 rounded-[4px] ${
          checked ? "bg-[#fa7532] border-[#fa7532]" : "bg-white"
        }`}
      >
        <input
          type="checkbox"
          className="opacity-0 absolute h-0 w-0"
          checked={checked}
          onChange={onChange}
        />
        {checked && (
          <svg
            className="absolute inset-0 w-full h-full text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round-[8px]"
              strokeLinejoin="round-[8px]"
              strokeWidth="3"
              d="M5 13l4 4L19 7"
            />
          </svg>
        )}
      </div>
      <p className="text-[14px] leading-5  text-[#666666] ml-3">{title}</p>
    </label>
  );
};

const Checkbox = ({ title, state, stateChange, ...props }) => {
  const handleCheckboxChange = () => {
    stateChange(!state);
  };

  return (
    <CustomCheckbox
      checked={state}
      onChange={handleCheckboxChange}
      title={title}
    />
  );
};

export default Checkbox;
