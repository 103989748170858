import React, { useEffect, useState } from "react";
import { SecondaryButtons, PrimaryButtons } from "./buttons";
import { HDivider } from "./amenities";
import Modal from "./Modal";
import useAPI from "./UseApi";

import { Close } from "@landvoice/icons";
import { PrimaryInput } from "./inputFields";

const AddTags = ({ handleOpen, open, tags = [], postTo, updateTags }) => {
  const [currentTags, setCurrentTags] = useState([]);

  const [newTags, setNewTags] = useState(``);
  const { post } = useAPI();

  const clear = () => {
    setNewTags("");

    handleOpen({ id: 0 });
  };

  useEffect(() => {
    setCurrentTags(tags);
    // eslint-disable-next-line
  }, [tags]);

  const saveTags = () => {
    post(
      postTo,
      {
        tags: [...newTags.split(" "), ...currentTags].filter(
          (item) => item !== "" && item !== " "
        ),
      },
      () => {
        updateTags(
          [...newTags?.split(" "), ...currentTags].filter(
            (item) => item !== "" && item !== " "
          )
        );
        clear();
      }
    );
  };

  const removeTag = (tag) => {
    let index = currentTags.indexOf(tag);
    if (index !== -1) {
      currentTags.splice(index, 1);
      setCurrentTags([...currentTags]);
    }
  };
  return (
    <Modal
      handleOpen={clear}
      open={open}
      mxWidth={"max-w-[600px]"}
      title={"Add Tags"}
    >
      <div className="  max-w-[552px] min-h-[176px] py-4 ">
        <div className="flex justify-between gap-2 h-9 my-8">
          <PrimaryInput
            value={newTags}
            onChange={(e) => {
              setNewTags(e.target.value);
            }}
            placeHolder={"Type tag name here"}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                if (newTags) {
                  setCurrentTags([...currentTags, ...newTags.split(" ")]);
                  setNewTags("");
                }
              }
            }}
          />
          <SecondaryButtons
            label={"Add"}
            onClick={() => {
              if (newTags) {
                setCurrentTags([...currentTags, ...newTags.split(" ")]);
                setNewTags("");
              }
            }}
          />
        </div>
        <HDivider />
        <div className=" mt-8">
          {currentTags.length ? (
            <p className="text-[#999999] font-semibold leading-5 text-[14px]">
              Currently applied tags:
            </p>
          ) : (
            <p className="text-[#C2C2C2] italic leading-5 text-[14px]">
              No tags currently applied.
            </p>
          )}{" "}
          <div className=" flex flex-wrap">
            {currentTags.map((tag, index) => {
              return (
                <div
                  key={`tag ${index}`}
                  className="flex items-center justify-between h-6 min-w-[50px] bg-[#EBEBEB] px-4 gap-1 rounded-[15.56px]"
                >
                  <p className="text-[11px] font-bold text-[#666666] leading-4">
                    {tag}
                  </p>
                  <div onClick={() => removeTag(tag)}>
                    <Close className={"text-[12px]"} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className=" flex justify-between mt-8">
        <SecondaryButtons label={"Cancel"} onClick={clear} />
        <PrimaryButtons label={"Save"} onClick={saveTags} />
      </div>
    </Modal>
  );
};

export default AddTags;
