const getDate = (adjust = 0) => {
  // Get the current date
  let currentDate = new Date();
  // Subtract one day
  currentDate.setDate(currentDate.getDate() + adjust);

  // Get the individual components of the date
  let year = currentDate.getFullYear();
  let month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  let day = String(currentDate.getDate()).padStart(2, "0");

  // Format the date as "MM/DD/YYYY"
  return `${month}/${day}/${year}`;
};
const formattedDate = getDate(-30);
const todaysDate = getDate(0);

export { formattedDate, todaysDate };
