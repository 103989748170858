import React, { useEffect, useState } from "react";
import PageLayout from "../components/common/PageLayout";
import Selection from "../components/utils/account/select";
import {
  sortingOptions,
  leadFilterOptions,
  sortListings,
  // eslint-disable-next-line
  handlePrintAll,
} from "../components/utils/selectionData";
import { Close } from "@landvoice/icons";
import { Filter } from "@landvoice/icons";
import {
  ContactCard,
  MlsTitleAmenities,
  OwnerContacts,
  removeParamFromString,
} from "../components/common/amenities";
import AddNotes from "../components/common/addNotes";
import AddTags from "../components/common/addTags";
import FilterPage from "../components/common/filterPage";
import useAPI from "../components/common/UseApi";
import {
  PropertyTypeEnum,
  StatusTypeEnum,
} from "../components/utils/selectionData";
import Pagination from "../components/common/pagination";
import { useAuth } from "../components/utils/auth/assist/authContext";
import pdfDownload from "../components/common/pdfDownload";
import DetailsPage from "../components/common/detailsPage";
import { useLocation } from "react-router-dom"; // Assuming you're using React Router

import NoAccessPage from "../components/common/noAccessPage";
import ErrorPopUp from "../components/common/errorPopUp";
import PrintListView from "../components/common/PrintListView";
import PrintOneView from "../components/common/PrintOneView";
// import DatePickerValue from "../components/common/datepick.js";

function Expired() {
  const [isUsingBatchId, setIsUsingBatchId] = useState(true);
  const [batchId, setBatchId] = useState("");
  const [notesOpen, setNotesOpen] = useState(false);
  const [tagsOpen, setTagsOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [warningOpen, setWarningOpen] = useState(false);
  const [currentMLS, setCurrentMLS] = useState("");
  const [currentMLSNotes, setCurrentMLSNotes] = useState("");
  const [currentMLSTags, setCurrentMLSTags] = useState([]);
  const [perPage, setPerPage] = useState({ value: "25", id: 0 });
  const [isDownloading, setIsDownloading] = useState(false);
  const [listing, setListing] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [listingForPrint, setListingForPrint] = useState([]);
  const [isPrintAll, setIsPrintAll] = useState(false);
  const [isPrintOne, setIsPrintOne] = useState(false);
  const [itemsToPrint, setItemsToPrintsPrintOne] = useState(0);
  // eslint-disable-next-line
  const [isCalledForPrint, setIsCalledForPrint] = useState(false);

  const location = useLocation();

  const startStatus = batchId ? [] : ["X", "W", "C"];

  const handleNotes = ({ id, notes }) => {
    setNotesOpen((cur) => !cur);
    setCurrentMLS(id);
    setCurrentMLSNotes(notes);
  };
  const handleTags = ({ id, tags }) => {
    if (!tagsOpen) {
      setCurrentMLS(id);
      setCurrentMLSTags(tags);
    }
    setTagsOpen((cur) => !cur);
  };

  const updateTagsInListing = (newTagsArray) => {
    const listingId = currentMLS;
    setListing((currentListings) =>
      currentListings.map((listing) => {
        // Check if this is the listing to update
        if (listing?.id === listingId) {
          // Clone the listing to avoid direct mutations and update the tags array
          return { ...listing, tags: [...newTagsArray] };
        }
        // Return the listing unchanged if it's not the one to update
        return listing;
      })
    );
  };

  const updateNotes = (newNotes) => {
    const listingId = currentMLS;
    setListing((currentListings) =>
      currentListings.map((listing) => {
        // Check if this is the listing to update
        if (listing?.id === listingId) {
          // Clone the listing to avoid direct mutations and update the tags array
          return { ...listing, notes: newNotes };
        }
        // Return the listing unchanged if it's not the one to update
        return listing;
      })
    );
  };

  const handleDetals = ({ id, bool }) => {
    setCurrentMLS(id);
    setDetailsOpen((cur) => !cur);
  };

  const handleWarning = () => {
    setWarningOpen((cur) => !cur);
  };

  useEffect(() => {
    setListing(listing);
  }, [listing]);

  const handleDownloads = () => {
    setIsDownloading((cur) => !cur);
  };
  const handleFilter = () => setFilterOpen((cur) => !cur);
  const [apiData, setApiData] = useState([]);
  const { get } = useAPI();
  const [link, setLink] = useState(``);
  const [filters, setFilters] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sorting, setSorting] = useState("Date (Newest)");
  const [showing, setShowing] = useState("Show all ");
  const [expiredStatuses, setExpiredStatuses] = useState(startStatus);
  const [sort, setSort] = useState("");
  const [leadsFilter, setLeadsFIlter] = useState("");
  const [leadsFilterLoading, setLeadsFIlterLoading] = useState(false);
  const [sortLoading, setSortLoading] = useState(false);

  const { user } = useAuth();

  const mls = apiData;

  const statusMap = expiredStatuses
    .map((element) => `status=${element}`)
    .join("&");

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has("batch_id")) {
      setBatchId(`&batch_id=${searchParams.get("batch_id")}`);
    } else {
      setBatchId("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentPage && perPage.value && sort) {
      const searchParams = new URLSearchParams(location.search);

      if (searchParams.has("batch_id") && isUsingBatchId) {
        setBatchId(`&batch_id=${searchParams.get("batch_id")}`);

        setFilters([
          ...filters,
          { batchId: `&batch_id=${searchParams.get("batch_id")}` },
        ]);
      } else {
        setBatchId("");
      }

      fetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    link,
    currentPage,
    perPage.value,
    sort,
    leadsFilter,
    statusMap,
    isUsingBatchId,
  ]);

  useEffect(() => {
    setLeadsFIlterLoading(true);
  }, [leadsFilter]);

  useEffect(() => {
    setSortLoading(true);
  }, [sort]);

  useEffect(() => {
    setSort(sortListings(sorting));
    if (showing === "Show all ") setLeadsFIlter("");

    if (showing === "Show only with email")
      setLeadsFIlter("lead_filter=email-only");

    if (showing === "Show only without phone numbers")
      setLeadsFIlter("lead_filter=no-phones");

    if (showing === "Show only with phone numbers")
      setLeadsFIlter("lead_filter=phones-only");
  }, [sorting, showing]);

  const fetch = async () => {
    if (user?.expireds) {
      const results = await get(
        `/api/expired/listing/?offset=${
          (currentPage - 1) * perPage.value
        }&limit=${
          perPage.value
        }&${link}&${leadsFilter}&${sort}&${expiredStatuses
          .map((element) => `status=${element}`)
          .join("&")}${isUsingBatchId ? batchId : ""}`
      );
      setApiData(results);
      setLeadsFIlterLoading(false);
      setSortLoading(false);
      setListing(results.listings);
      setItemsToPrintsPrintOne(results.total_records);
    }
  };

  const fetchForPrint = async () => {
    if (isDownloading) return;
    if (detailsOpen) {
      window.print();
    }
    if (user?.expireds && !detailsOpen) {
      setIsCalledForPrint(false);
      handleDownloads();
      const results = await get(
        `/api/expired/listing/?offset=${0}&limit=${itemsToPrint}&${link}&${leadsFilter}&${sort}&${expiredStatuses
          .map((element) => `status=${element}`)
          .join("&")}${isUsingBatchId ? batchId : ""}`
      );
      setListingForPrint(results.listings);
    }
  };

  useEffect(() => {
    if (!isDownloading) return;
    if (listingForPrint.length > 0) {
      window.print();
      handleDownloads();
      setIsPrintAll(false);
      setIsPrintOne(false);
    }
    // eslint-disable-next-line
  }, [listingForPrint]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if 'P' is pressed along with 'Control' or 'Command'
      if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === "p") {
        setIsCalledForPrint(true);
        event.preventDefault();

        setIsPrintAll(true);
        setTimeout(fetchForPrint, 500);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line
  }, [listing]);

  useEffect(() => {
    const totalItems = apiData?.total_records;
    const itemsPerPage = perPage.value;

    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const pagesArray = [];

    for (let i = 1; i <= totalPages; i++) {
      const pageObject = { name: i.toString() };
      pagesArray.push(pageObject);
    }

    setPageList(pagesArray);
  }, [apiData?.total_records, perPage.value]);

  return (
    <section className="max-h-screen hideScrollBar">
      <PageLayout detailsOpen={false}>
        <ErrorPopUp
          open={warningOpen}
          title={"warning"}
          data={
            "You are currently planning to print more than 1,000 records. (334+ pages) Do you want to edit your search criteria before printing?"
          }
          handleOpen={handleWarning}
          proceed={fetchForPrint}
        />
        <FilterPage
          handleOpen={handleFilter}
          open={filterOpen}
          title={"Expired Filters"}
          filter={setFilters}
          params={setLink}
          expiredStatuses={setExpiredStatuses}
        />
        <AddNotes
          handleOpen={handleNotes}
          open={notesOpen}
          postTo={`/api/expired/listing/${currentMLS}`}
          notes={currentMLSNotes}
          updateNotes={updateNotes}
          where={"expired"}
        />
        <AddTags
          handleOpen={handleTags}
          open={tagsOpen}
          postTo={`/api/expired/listing/${currentMLS}/tags`}
          tags={currentMLSTags}
          updateTags={updateTagsInListing}
        />
        <DetailsPage
          id={currentMLS}
          handleOpen={handleDetals}
          open={detailsOpen}
          updateListTags={updateTagsInListing}
          updateListNotes={updateNotes}
        />

        <div className="hideScrollBar">
          <div className="min-h-[120px] mb-[16px] pt-8 print:pt-0 pb-3 print:hidden hideScrollBar ">
            <div className=" flex justify-start items-baseline gap-3">
              <h1 className=" leading-[57px] font-[600] text-[48px] text-[#666666]  mb-[14px]">
                Expireds
              </h1>
              <p className="text-[#666666] leading-[19.2px] font-[400] text-[16px]">
                Showing {mls?.listings?.length || "--"} of{" "}
                {apiData?.total_records || "--"}
              </p>
            </div>

            <p className="leading-[24px] font-[400] text-[16px] text-[#999999] max-w-[800px]">
              Your subscription level:{" "}
              <span className="font-[700]">
                {user?.expired_pro ? "Expired Pro" : "Expired Basic"}
              </span>
            </p>
          </div>

          {user?.expireds ? (
            <section className="">
              <div className=" flex gap-3 md:justify-between min-h-10 w-full mt-4 flex-wrap print:hidden">
                <div className=" flex gap-3 flex-wrap print:hidden">
                  <div
                    className=" items-center flex gap-3 px-4 py-[6px] h-10 w-[114px]  rounded-lg border-[1px] border-[#EBEBEB] leading-6 text-[14px] text-[#666666]"
                    onClick={handleFilter}
                  >
                    {" "}
                    <Filter />
                    Filters
                  </div>
                  <div className=" h-10 min-w-[145px]  rounded-lg ">
                    <Selection
                      rounded={"8px"}
                      SelectionColor={false}
                      selecionHeight={"36px"}
                      setIsActive={() => {}}
                      list={leadFilterOptions}
                      initialSelection={0}
                      flowWidth={true}
                      onValueChange={setShowing}
                      isDownload={true}
                      isDoenloading={leadsFilterLoading}
                    />
                  </div>
                  <div className=" h-10 min-w-[145px]  rounded-lg ">
                    <Selection
                      headding={"Sort By:"}
                      setIsActive={() => {}}
                      list={sortingOptions}
                      initialSelection={2}
                      rounded={"8px"}
                      SelectionColor={false}
                      selecionHeight={"36px"}
                      flowWidth={true}
                      onValueChange={setSorting}
                      isDownload={true}
                      isDoenloading={sortLoading}
                    />
                  </div>
                </div>

                <div className=" flex gap-4 print:hidden">
                  <div className=" h-10 min-w-[145px]  rounded-lg ">
                    <Selection
                      isFunctional={true}
                      positionLeft={false}
                      headding={"Downloads"}
                      isDownload={true}
                      isDoenloading={isDownloading}
                      setIsActive={() => {}}
                      list={[
                        {
                          name: "Download As CSV",
                          clickableFunction: () => {
                            handleDownloads();
                            pdfDownload(
                              `/api/expired/listing/csv/?offset=${
                                (currentPage - 1) * perPage.value
                              }&limit=${
                                apiData?.total_records
                              }&${link}&${leadsFilter}&${sort}&${expiredStatuses
                                .map((element) => `status=${element}`)
                                .join("&")}`,
                              "expiredlisting",
                              "csv",
                              handleDownloads
                            );
                          },
                        },
                        {
                          name: "Print All Records",
                          clickableFunction: () => {
                            if (apiData?.total_records >= 1000) {
                              setWarningOpen(true);
                            } else {
                              fetchForPrint();
                              setIsPrintAll(true);
                            }
                          },
                        },
                        {
                          name: "Print 1 Record/Page",
                          clickableFunction: () => {
                            if (apiData?.total_records >= 1000) {
                              setWarningOpen(true);
                            } else {
                              fetchForPrint();
                              setIsPrintOne(true);
                            }
                          },
                        },
                      ]}
                      initialSelection={0}
                      rounded={"8px"}
                      SelectionColor={false}
                      check={false}
                      selecionHeight={"36px"}
                      flowWidth={true}
                      onValueChange={() => {}}
                    />
                  </div>
                </div>
              </div>
              <div className="print:hidden min-h-[28px] w-full px-4 flex gap-3 mt-4 flex-wrap">
                <p className=" font-semibold text-[14px] text-[#999999] leading-6 ">
                  Active Filters:
                </p>
                {filters.map((filter, index) => (
                  <>
                    {Object.entries(filter).map(([key, value]) =>
                      value &&
                      value !== "Any" &&
                      value !== "" &&
                      key !== "tags" &&
                      value !== "searchDatType" ? (
                        <div
                          key={index}
                          className="flex items-center gap-2 justify-between h-[24px] min-w-[59px] rounded-full bg-[#EBEBEB] py-1 px-2"
                        >
                          <p
                            key={key}
                            className="text-[11px] text-[#666666] font-bold leading-4"
                          >
                            {key}:{" "}
                            {value !== undefined && key === "batchId"
                              ? value.split("=")[1]
                              : value !== undefined && key !== "batchId"
                              ? value
                              : "N/A"}
                          </p>
                          <div
                            className=" opacity-50 hover:opacity-100"
                            onClick={() => {
                              if (key === "batchId") {
                                setIsUsingBatchId(false);
                              }

                              if (key !== "status") {
                                setFilters(
                                  filters.filter((item) => item[key] !== value)
                                );

                                const newLink = removeParamFromString(
                                  String(link),
                                  key === "from"
                                    ? "from_date"
                                    : key === "to"
                                    ? "to_date"
                                    : key === "spr feet"
                                    ? "sq_ft"
                                    : key === "postal codes"
                                    ? "postalCode"
                                    : key === "min price"
                                    ? "min_price"
                                    : key === "max price"
                                    ? "max_price"
                                    : key,
                                  value
                                );

                                setLink(
                                  newLink.replace(
                                    `&${
                                      key === "from"
                                        ? "from_date"
                                        : key === "to"
                                        ? "to_date"
                                        : key === "spr feet"
                                        ? "sq_ft"
                                        : key === "postal codes"
                                        ? "postalCode"
                                        : key === "min price"
                                        ? "min_price"
                                        : key === "max price"
                                        ? "max_price"
                                        : key
                                    }=${encodeURIComponent(String(value))}`,
                                    ""
                                  )
                                );
                              }
                            }}
                          >
                            <Close className={"text-[12px]"} />
                          </div>
                        </div>
                      ) : null
                    )}
                  </>
                ))}
              </div>
              <section
                className={`hideScrollBar mt-4 print:mt-0 print:hidden `}
              >
                {listing?.map((property, index) => {
                  return (
                    <div
                      id={index}
                      className="break-inside-avoid py-3 px-4 w-full flex flex-col flex-wrap md:flex-row gap-0 lg:gap-4 border-b-[1px] border-[#ccc] min-h-[242px] rounded-lg"
                    >
                      <MlsTitleAmenities
                        key={"listing " + index}
                        openDetails={handleDetals}
                        id={property?.id}
                        title={`${property?.property?.street_address}, ${property?.property?.city}, ${property?.property?.state} ${property?.property?.postal_code}`}
                        beds={property?.bedrooms}
                        bath={property?.baths}
                        SqrFeet={property?.square_feet.toLocaleString("en-US", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2, // You can adjust this based on your requirements
                        })}
                        lotSize={property?.acres}
                        buildDate={property?.year_built}
                        type={PropertyTypeEnum[property?.property_type]}
                        statusDate={property?.status_date}
                        status={StatusTypeEnum[property?.status]}
                        price={parseFloat(property?.list_price).toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 0,
                          }
                        )}
                        dom={property?.days_on_market}
                        notes={property?.notes}
                        tags={property?.tags}
                        hasDom={true}
                        where={"Expired"}
                        mls_number={property?.mls_number}
                        openNotes={() =>
                          handleNotes({
                            id: property?.id,
                            notes: property?.notes,
                            objectIndex: index,
                          })
                        }
                        openTags={() =>
                          handleTags({
                            id: property?.id,
                            tags: property?.tags,
                            objectIndex: index,
                          })
                        }
                        mapLink={`https://www.google.com/maps/place/${property?.property?.street_address}+${property?.property?.city}+${property?.property?.state}+${property?.property?.postal_code}
                  `}
                      />

                      <OwnerContacts
                        owner={{
                          ...property?.owner_information,
                          ...property?.social_media,
                          email: property?.social_media?.email,
                        }}
                      />

                      {property?.contacts.length > 0 && (
                        <div className="lg:flex   flex-col h-full xl:py-6 sm:min-w-[320px] xl:w-[320px]">
                          <p className=" text-[14px] text-[#666666] leading-6 font-semibold mb-4">
                            Contacts ({property?.contacts.length})
                          </p>
                          <div className=" w-[calc(100vw-32px)] overflow-scroll xl:w-[380px] hideScrollBar">
                            <div className={`w-[550px] xl:w-[370px]`}>
                              <div className=" grid xl:grid-cols-2 grid-cols-3 gap-3 ">
                                {property?.contacts
                                  // .slice(0, 4)
                                  .map(
                                    (item, index) =>
                                      index <= 3 && (
                                        <ContactCard
                                          id={`contatct ${index}`}
                                          name={`${item.first_name} ${item.last_name}`}
                                          number={item.phone}
                                          dnc={item.phone_dnc}
                                          type={item?.phone_type}
                                          property_id={property?.property?.id}
                                          list={"expired"}
                                          hidden={{
                                            hidden: item?.hidden,
                                            tell: item.phone,
                                          }}
                                        />
                                      )
                                  )}
                                {/* {property?.contacts.length > 3 && (
                                  <div className=" h-[60.5px] w-[150px] flex justify-center items-center">
                                    <p className=" text-[12px] font-bold  text-#666666] leading-4">
                                      See All{" "}
                                      {`(${property?.contacts.length - 3})`}
                                    </p>
                                  </div>
                                )} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </section>

              {isPrintAll && !detailsOpen && (
                <section className="  gap-6  print:mt-0 hidden print:flex flex-col ">
                  {listingForPrint?.map((property, index) => {
                    return (
                      <PrintListView
                        property={property}
                        title={`${property?.property?.street_address}, ${property?.property?.city}, ${property?.property?.state} ${property?.property?.postal_code}`}
                        key={"listing " + index}
                        beds={property?.bedrooms}
                        bath={property?.baths}
                        SqrFeet={property?.square_feet.toLocaleString("en-US", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2, // You can adjust this based on your requirements
                        })}
                        lotSize={property?.acres}
                        buildDate={property?.year_built}
                        type={PropertyTypeEnum[property?.property_type]}
                        price={parseFloat(property?.list_price).toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 0,
                          }
                        )}
                        dom={property?.days_on_market}
                        where="Expired"
                      />
                    );
                  })}
                </section>
              )}

              {isPrintOne && (
                <section className="hidden print:block">
                  {listingForPrint.map((property, index) => {
                    return (
                      <PrintOneView
                        index={index}
                        property={property}
                        title={`${property?.property?.street_address}, ${property?.property?.city}, ${property?.property?.state} ${property?.property?.postal_code}`}
                        key={"listing " + index}
                        beds={property?.bedrooms}
                        bath={property?.baths}
                        SqrFeet={property?.square_feet.toLocaleString("en-US", {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2, // You can adjust this based on your requirements
                        })}
                        lotSize={property?.acres}
                        buildDate={property?.year_built}
                        type={PropertyTypeEnum[property?.property_type]}
                        price={parseFloat(property?.list_price).toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 0,
                          }
                        )}
                        dom={property?.days_on_market}
                        where="Expired"
                      />
                    );
                  })}
                </section>
              )}

              {pageList.length > 0 && (
                <div className="h-[px] w-full flex  print:hidden    gap-6 items-center justify-between sm:flex-col lg:flex-row">
                  <div className="min-w-[93px]">
                    <Selection
                      headding={"per page"}
                      setIsActive={() => {}}
                      list={[
                        {
                          name: "25",
                          clickableFunction: () => {
                            setPerPage({ value: "25", id: 0 });
                          },
                        },
                        {
                          name: "50",
                          clickableFunction: () => {
                            setPerPage({ value: "50", id: 1 });
                          },
                        },
                        {
                          name: "100",
                          clickableFunction: () => {
                            setPerPage({ value: "100", id: 2 });
                          },
                        },
                      ]}
                      initialSelection={perPage.id}
                      rounded={"8px"}
                      SelectionColor={false}
                      selecionHeight={"36px"}
                      flowWidth={true}
                      above={true}
                      onValueChange={() => {}}
                    />
                  </div>

                  <Pagination
                    pages={pageList}
                    currentPage={currentPage}
                    perPage={perPage}
                    onPageChange={setCurrentPage}
                    link={link}
                  />
                </div>
              )}
            </section>
          ) : (
            <NoAccessPage />
          )}
        </div>
      </PageLayout>
    </section>
  );
}

export default Expired;
