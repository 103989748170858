import React, { useEffect, useState } from "react";
import PageLayout from "../components/common/PageLayout";
import { Divider, HDivider } from "../components/common/amenities";
import Selection from "../components/utils/account/select";
import { FaPlus } from "react-icons/fa";
import { PrimaryButtons } from "../components/common/buttons";
import useAPI from "../components/common/UseApi";
import { UploadFile, DownloadFile } from "@landvoice/icons";
import ViewExpiredUploads from "../components/utils/expiredUploads/viewExpiredUploads";
import pdfDownload from "../components/common/pdfDownload";
import Cookies from "universal-cookie";
import ErrorMessage from "../components/common/ErrorMessage";
import ErrorPopUp from "../components/utils/expiredUploads/ErrorPopUp";
import { NavLink } from "react-router-dom";
import { useAuth } from "../components/utils/auth/assist/authContext";
import NoAccessPage from "../components/common/noAccessPage";

const ExpiredUploadPage = () => {
  // eslint-disable-next-line
  const { get } = useAPI();
  // eslint-disable-next-line
  const [mlsDetails, setMlsDetails] = useState({});
  const [mlsList, setMlsList] = useState([]);
  const [previousUploads, setPreviousUploads] = useState([]);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [errorPopUpOpen, setErrorPopUpOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [CSRFToken, setCSRFToken] = useState("0");
  const [mls, setMls] = useState(""); // Assuming mls is a string field
  const [uuid, setUuid] = useState(""); // Assuming mls is a string field
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [longErrorMessage, setLongErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  // eslint-disable-next-line
  const { user } = useAuth();
  const statusCodeToErrorMessage = {
    400: "Bad Request - The uploaded file could not be read, there were errors with one or more fields.  Check the format and try again.",
    401: "Unauthorized - You are not logged in.",
    403: "Forbidden - You do not have permission to perform this action.",
    404: "Not Found - The requested resource was not found.",
    500: "Internal Server Error - Something went wrong on our end.",
    // Add more status codes and messages as needed
  };

  const generateRandomString = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const handleDetals = ({ data }) => {
    setDetailsOpen((cur) => !cur);
    setMlsDetails(data);
  };

  const handleErrorPopUp = () => {
    setErrorPopUpOpen((cur) => !cur);
  };
  useEffect(() => {
    const cookies = new Cookies();
    const cookieValue = cookies.get("csrftoken");
    setCSRFToken(cookieValue);
    const tonken = generateRandomString(64);

    setUuid(tonken);
  }, []);
  useEffect(() => {
    const getMlsList = async () => {
      const mlsData = await get("api/core/account/mls");

      setMlsList(mlsData.filter((mls) => mls.upload_allowed === true));
    };

    const getPreviouslyUploaded = async () => {
      // eslint-disable-next-line
      const previoussData = await get("/api/expired/");
      setPreviousUploads(previoussData);
    };
    getPreviouslyUploaded();
    getMlsList();

    const interval = setInterval(getPreviouslyUploaded, 10000); // 10000 milliseconds = 10 seconds

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleMlsChange = (event) => {
    setMls(event);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append("upload_file", file);
    formData.append("mls", mlsList.find((obj) => obj.name === mls)?.mls_id);
    formData.append("upload_token", uuid);

    try {
      setLoading(true);
      setUploadComplete(false);
      setLongErrorMessage("");
      setErrorMessage("");
      setError(false);
      const response = await fetch("/api/expired/", {
        method: "POST",
        body: formData,
        headers: {
          "X-CSRFToken": CSRFToken,
        },
      });
      setError(!true);

      if (response.ok) {
        setUploadComplete(true);
      } else {
        setErrorMessage(
          statusCodeToErrorMessage[response.status] ||
            "An unexpected error occurred. Please try again."
        );
        response.json().then((err) => {
          if (err.msg) {
            setLongErrorMessage(err.msg);
          } else {
          }
        });
        setError(true);
        // setErrorMessage(
        //   statusCodeToErrorMessage[response.status] ||
        //     "An unexpected error occurred. Please try again."
        // );
      }
    } catch (error) {
      setError(true);
      setErrorMessage("there was an error with uploading your file.");
      throw error;
      // Handle error
    } finally {
      setLoading(false);
    }
  };

  const [dragging, setDragging] = useState(false);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      // Handle file upload here

      setFile(e.dataTransfer.files[0]);
      e.dataTransfer.clearData();
    }
  };

  return (
    <PageLayout>
      {" "}
      <div className="min-h-[120px] mb-[16px] pt-8 pb-3">
        <ViewExpiredUploads
          open={detailsOpen}
          data={mlsDetails}
          handleOpen={handleDetals}
        />

        <ErrorPopUp
          open={errorPopUpOpen}
          data={longErrorMessage || errorMessage}
          handleOpen={handleErrorPopUp}
        />
        <div className=" flex justify-start items-baseline gap-3">
          <h1 className=" leading-[57px] font-[600] text-[48px] text-[#666666]  mb-[14px]">
            Expired File Upload
          </h1>
          <p className="text-[#666666] leading-[19.2px] font-[400] text-[16px]"></p>
        </div>

        {user?.expired_upload ? (
          <section>
            <div
              onDragEnter={handleDragIn}
              onDragLeave={handleDragOut}
              onDragOver={handleDrag}
              onDrop={handleDrop}
              className={`w-full border-2   min-h-[364px] rounded-2xl p-8 flex gap-16 flex-wrap ${
                dragging
                  ? "border-dashed border-[#f27a35a6] border-3"
                  : "border-[#EBEBEB]"
              }`}
            >
              <div className="max-h-[300px] w-[231px] flex flex-col gap-2 justify-center items-center">
                <p className=" leading-6 text-[20px] text-[#999999]">
                  Lookups Remaining:{" "}
                </p>
                <p className=" leading-[57.6px] text-[48px] text-[#666666]">
                  {previousUploads?.credits?.toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2, // You can adjust this based on your requirements
                  })}
                </p>
              </div>
              <Divider height="max-h-[500px]" />
              <div className="flex flex-col gap-4 max-w-[458px]">
                <div className=" flex gap-4 max-w-[458px] items-center  h-7">
                  <p className=" text-[16px] text-[#666666] whitespace-nowrap leading-7 font-bold">
                    Upload a File
                  </p>

                  <HDivider />
                </div>
                {error ? (
                  <ErrorMessage
                    message={
                      <p>
                        {errorMessage}{" "}
                        {longErrorMessage ? (
                          <span
                            className=" font-semibold underline cursor-pointer"
                            onClick={handleErrorPopUp}
                          >
                            {" "}
                            see more{" "}
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                    }
                  />
                ) : null}
                {uploadComplete ? (
                  <div className=" min-h-[44px] w-full bg-[#c3fac9] mb-8 rounded-md p-3">
                    <p className=" text-[14px] font-[400] leading-[20px] text-[#19a82c]">
                      {
                        "Upload completed, your file is currently being processed."
                      }
                    </p>
                  </div>
                ) : null}
                <div className=" flex flex-col gap-4">
                  {/* --------------*******------------- */}
                  <div className="flex flex-col gap-1 max-w-[425px]">
                    <p className=" font-semibold text-[14px] leading-5 text-[#999999] h-5">
                      1. Select your MLS{" "}
                    </p>

                    <Selection
                      rounded={"8px"}
                      SelectionColor={false}
                      selecionHeight={"36px"}
                      setIsActive={() => {}}
                      list={mlsList}
                      initialSelection={0}
                      flowWidth={true}
                      onValueChange={handleMlsChange}
                    />
                  </div>
                  {/* ------------ */}

                  <div className="flex flex-col gap-1 max-w-[320px]">
                    <p className=" font-semibold text-[14px] leading-5 text-[#999999]">
                      2. Select your file{" "}
                    </p>

                    <p className=" text-[12px] leading-4 text-[#999999]">
                      Files must be in the .csv format. The exact format depends
                      on the output of your MLS export.
                    </p>
                    <div className=" flex gap-5 w-full lg:min-w-[500px] md:flex-row flex-col">
                      <label className="flex w-[125px] h-10 items-center justify-center bg-[#E8EDF0] text-[#666666] px-3 py-2 rounded-lg gap-2 cursor-pointer">
                        <FaPlus />
                        <span className="text-[14px] font-semibold leading-7">
                          Choose File
                        </span>
                        <input
                          onChange={handleFileChange}
                          type="file"
                          className="hidden"
                        ></input>
                      </label>
                      <p className=" text-[14px] text-[#666]"> {file?.name}</p>
                    </div>
                  </div>

                  <HDivider />
                  <div className=" flex gap-4">
                    <div className=" min-w-[100px] gap-4 flex">
                      <PrimaryButtons
                        label={"Upload"}
                        width={"min-w-[100px]"}
                        onClick={handleSubmit}
                        downloading={loading}
                      />
                    </div>
                    <p className=" text-[12px] leading-4 text-[#999999]">
                      Please only click the upload button once. Clicking
                      multiple times may result in the upload being submitted
                      multiple times.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className=" w-full overflow-x-auto mt-8">
              <table className="min-w-[786px] w-full   border-none">
                <thead>
                  <tr className="bg-gray-100 m-0 p-0 border-y-[1px]">
                    <th className="border-none text-[14px] font-semibold leading-5 text-[#666666] p-2 text-left">
                      Name
                    </th>
                    <th className="border-none text-[14px] font-semibold leading-5 text-[#666666] p-2 text-left">
                      Uploaded
                    </th>
                    <th className="border-none text-[14px] font-semibold leading-5 text-[#666666] p-2 text-left">
                      MLS
                    </th>
                    <th className="border-none text-[14px] font-semibold leading-5 text-[#666666] p-2 text-left">
                      Status
                    </th>
                    <th className="border-none text-[14px] font-semibold leading-5 text-[#666666] p-2 text-left">
                      Total
                    </th>
                    <th className="border-none text-[14px] font-semibold leading-5 text-[#666666] p-2 text-left">
                      Subscribed
                    </th>
                    <th className="border-none text-[14px] font-semibold leading-5 text-[#666666] p-2 text-left">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {previousUploads?.batches?.map((row, index) => (
                    <tr className=" border-b-[1px] h-[52px]" key={index}>
                      <td className="border-none text-[14px] font-semibold leading-5 text-[#666666] p-2">
                        {row?.uploaded_file}
                      </td>
                      <td className="border-none text-[14px] font-semibold leading-5 text-[#999999] p-2">
                        {row.complete_date}
                      </td>
                      <td className="border-none text-[14px] font-semibold leading-5 text-[#666666] p-2">
                        {row.id}
                      </td>
                      <td className="border-none text-[14px] font-semibold leading-5 text-[#666666] p-2">
                        {row.step}
                      </td>
                      <td className="border-none text-[14px] font-semibold leading-5 text-[#666666] p-2">
                        {row.total_records}
                      </td>
                      <td className="border-none text-[14px] font-semibold leading-5 text-[#666666] p-2">
                        {row.subscribed_records}
                      </td>
                      <td className="border-none max-w-[220px] text-[14px] font-semibold leading-5 text-[#666666] p-2 grid grid-cols-2 gap-8 ">
                        <NavLink to={`/expired?batch_id=${row.id}`}>
                          <div
                            // onClick={(e) => {
                            //   handleDetals({ data: row });
                            // }}
                            className=" flex gap-2 hover:cursor-pointer"
                          >
                            {" "}
                            <UploadFile /> View
                          </div>
                        </NavLink>
                        <div
                          onClick={() => {
                            pdfDownload(
                              `/api/expired/listing/csv?batch_id=${row?.id}`,
                              row?.uploaded_file.split(".")[0],
                              "csv"
                            );
                          }}
                          className=" flex gap-2 hover:cursor-pointer"
                        >
                          <DownloadFile className="text-[16px]" /> Download
                        </div>

                        {/* Add other action buttons as needed */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>
        ) : (
          <NoAccessPage product="Upload Expireds" />
        )}
      </div>
    </PageLayout>
  );
};

export default ExpiredUploadPage;
