import React, { useEffect, useReducer, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import PeriodNavigation from "./PeriodNavigation";
import CalendarMonth from "./CalendarMonth";
import MonthSelector from "./MonthSelector";

import { calendarContainerCommon } from "./DateSelector.styles";
import {
  getDateFormat,
  getValidDateFormats,
  maskUserInput,
  formatDatetime,
  formatDate,
} from "./utils/helpers";
import { reducer, init } from "./utils/reducer";
import { DATE_FORMAT, UIMODE } from "./utils/constants";
import { PrimaryInput } from "../common/inputFields";

/**
 * @summary DateSelector component. It's possible to enable up to 2 timepickers. `onChange` is the only required prop to make it functional.
 * @param {string} value - The dateselector initial value. Provide a string in the following formats YYYY-MM-DD | YYYY-MM-DD HH:MM AM/PM | YYYY-MM-DD HH:MM AM/PM HH:MM AM/PM
 * @param {func} onChange - The only required prop to make the dateselector useful. It returns a string based on dateFormat. Default format is MM/DD/YYYY
 * @param {bool} enableTimePicker - Enable the timepicker option. Selected time will be sent to onChange.
 * @param {bool} enableEndTimePicker - Enable the second timepicker option. Selected time will be sent to onChange.
 * @param {boolean} enableDateInput - Enable the input for users to type in the date.
 * @param {string} dateFormat - Provide a momentjs date format to override the default one (MM-DD-YYYY). See https://momentjs.com/docs/#/displaying/format/ for available formats.
 * @param {string} containerClassName - className for the outermost HTML element.
 * @param {boolean} error - Shows a message if date is invalid.
 * */
const DateSelector = ({
  value,
  onChange,
  enableTimePicker,
  enableEndTimePicker,
  dateFormat,
  containerClassName,
  error,
  minDate,
  maxDate,
}) => {
  const initialReducerData = {
    value,
    dateFormat,
    enableTimePicker,
    enableEndTimePicker,
  };

  const [state, dispatch] = useReducer(reducer, initialReducerData, init);

  const oldValue = useRef(
    formatDate(value.split(" ")[0], getDateFormat(dateFormat))
  );
  const isMount = useRef(true);

  const handleUpdatedInitialDate = (dateProp) => {
    if (isMount.current === false) return;

    isMount.current = false;

    if (moment(dateProp, getValidDateFormats(dateFormat)).isValid()) {
      const newValidDate = moment(dateProp, getValidDateFormats(dateFormat));

      dispatch({
        type: "SET_ALL_DATES",
        payload: {
          impureDate: formatDate(dateProp.split(" ")[0], dateFormat),
          temporaryDate: newValidDate,
          navigationDate: newValidDate,
        },
      });
    } else {
      dispatch({
        type: "SET_IMPURE_DATE",
        payload: {
          date: formatDate(dateProp.split(" ")[0], dateFormat),
        },
      });
    }
  };

  /**
   * @summary How to handle user input. It trims, remove non-numeric characters, and add forward slashes based on dateFormat.
   * @param {string} rawUserInput - It's a date you can't rely on. It's the raw value of the input. It might be valid, invalid, empty, letters etc
   */

  useEffect(() => {
    if (value === "") {
      dispatch({
        type: "SET_EMPTY_DATE",
      });
      dispatch({
        type: "CLOSE_TIME_PICKER",
      });
    }

    handleUpdatedInitialDate(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    const formattedDate = formatDatetime(state, getDateFormat(dateFormat));
    // Solution to avoid OnChange being called twice.
    if (oldValue.current !== formattedDate) {
      oldValue.current = formattedDate;
      onChange({
        date: state.impureDate,
        startTime:
          enableTimePicker && state.showTimePicker
            ? state.temporaryStartTime
            : "",
        endTime:
          enableEndTimePicker && state.showEndTimePicker
            ? state.temporaryEndTime
            : "",
        formattedDate,
        temporaryDate: state.temporaryDate,
        navigationDate: state.navigationDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.impureDate,
    state.temporaryStartTime,
    state.temporaryEndTime,
    state.showTimePicker,
    state.showEndTimePicker,
  ]);

  useEffect(() => {
    if (error) {
      dispatch({
        type: "INVALID_IMPURE_DATE",
        payload: {
          dateFormatError: getDateFormat(dateFormat),
        },
      });
    } else {
      dispatch({
        type: "CLEAR_DATE_FORMAT_ERROR",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleDayClick = (day) => {
    dispatch({
      type: "SET_CLICKED_DATE",
      payload: {
        temporaryDate: day,
        impureDate: moment(day, getValidDateFormats(dateFormat)).format(
          getDateFormat(dateFormat)
        ),
      },
    });
  };

  const handleDayClickFronMonth = (month) => {
    dispatch({
      type: "SET_CLICKED_DATE",
      payload: {
        temporaryDate: moment(month).startOf("month"),
        impureDate: moment(month)
          .startOf("month")
          .format(getDateFormat(dateFormat)),
      },
    });
  };
  const handleUserInput = (rawUserInput) => {
    if (rawUserInput === "") {
      dispatch({
        type: "SET_EMPTY_DATE",
      });
    } else {
      const isUserAddingChar = rawUserInput.length > state.impureDate.length;
      const format = getDateFormat(dateFormat);
      const trimmedUserInput = rawUserInput.slice(0, format.length);
      const allowedCharsUserInput = trimmedUserInput.replace(/[^0-9/-]/gi, "");

      let formattedUserInput = "";
      if (isUserAddingChar) {
        formattedUserInput = maskUserInput(allowedCharsUserInput, format);
      } else {
        formattedUserInput = allowedCharsUserInput;
      }

      if (
        moment(formattedUserInput, getValidDateFormats(dateFormat)).isValid()
      ) {
        const newValidDate = moment(
          formattedUserInput,
          getValidDateFormats(dateFormat)
        );
        dispatch({
          type: "SET_ALL_DATES",
          payload: {
            impureDate: formattedUserInput,
            temporaryDate: newValidDate,
            navigationDate: newValidDate,
          },
        });
      } else {
        dispatch({
          type: "SET_IMPURE_DATE",
          payload: {
            date: formattedUserInput,
          },
        });
      }
    }
  };

  const handleNavigationClick = (period) => {
    dispatch({
      type: "SET_NAVIGATION_DATE",
      payload: {
        navigationDate: period.clone(),
      },
    });
  };

  const handleMonthClick = (month) => {
    dispatch({
      type: "CLICK_NAVIGATION_MONTH_DATE",
      payload: {
        navigationDate: month.clone(),
      },
    });

    handleDayClickFronMonth(month);
  };

  const handleChangeUI = () => {
    switch (state.uiMode) {
      case UIMODE.DAY:
        dispatch({
          type: "SET_UI_MODE",
          payload: {
            uiMode: UIMODE.MONTH,
          },
        });
        break;
      case UIMODE.MONTH:
        dispatch({
          type: "SET_UI_MODE",
          payload: {
            uiMode: UIMODE.DAY,
          },
        });
        break;
      default:
        dispatch({
          type: "SET_UI_MODE",
          payload: {
            uiMode: UIMODE.DAY,
          },
        });
    }
  };
  const getDatePlaceholder = () => {
    if (enableTimePicker && state.showTimePicker) {
      return "Date";
    }

    return getDateFormat(dateFormat);
  };

  const renderInputUI = () => (
    <>
      <div className="tw-flex tw-flex-row tw-flex-wrap">
        {
          <div className={""}>
            <PrimaryInput
              type="text"
              placeholder={getDatePlaceholder()}
              value={state.impureDate}
              onChange={(e) => handleUserInput(e.target.value)}
              error={state.dateFormatError}
              data-test-date-input
              data-cy="date-selector-input"
            />
          </div>
        }
      </div>
    </>
  );

  const renderPickerUI = () => {
    switch (state.uiMode) {
      case UIMODE.DAY:
        return (
          <CalendarMonth
            temporaryDate={state.temporaryDate}
            navigationDate={state.navigationDate}
            handleDayClick={handleDayClick}
            minDate={minDate}
            maxDate={maxDate}
          />
        );
      case UIMODE.MONTH:
        return (
          <MonthSelector
            temporaryDate={state.temporaryDate}
            navigationDate={state.navigationDate}
            handleMonthClick={handleMonthClick}
            minDate={minDate}
            maxDate={maxDate}
            noYear
          />
        );
      default:
        return null;
    }
  };

  return (
    <div
      data-test-dateselector-container
      className={`${calendarContainerCommon} ${containerClassName}`}
    >
      {state.uiMode === UIMODE.DAY && renderInputUI()}
      <PeriodNavigation
        navigationDate={state.navigationDate}
        handleNavigationClick={handleNavigationClick}
        handleChangeUI={handleChangeUI}
        uiMode={state.uiMode}
        minDate={minDate}
        maxDate={maxDate}
      />
      {renderPickerUI()}
    </div>
  );
};

DateSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  enableTimePicker: PropTypes.bool,
  enableEndTimePicker: PropTypes.bool,
  dateFormat: PropTypes.string,
  containerClassName: PropTypes.string,
  error: PropTypes.bool,
  enableDateInput: PropTypes.bool,
  minDate: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
};

DateSelector.defaultProps = {
  value: moment().format(DATE_FORMAT),
  onChange: () => {},
  enableTimePicker: false,
  enableEndTimePicker: false,
  dateFormat: "",
  containerClassName: "",
  error: false,
  enableDateInput: true,
  minDate: null,
  maxDate: null,
};

export default DateSelector;
