import React, { useEffect, useState } from "react";

import {
  Bath,
  Bed,
  Grid,
  Tree,
  CalendarClock,
  Building,
  Tag,
  // eslint-disable-next-line
  Close,
  // eslint-disable-next-line
  Phone,
  ViewEditNote,
  Add,
  Mail,
  InstagramDisabled,
  FacebookDisabled,
  XDisabled,
  LinkedInDisabled,
  // eslint-disable-next-line
  DncPills,
  // eslint-disable-next-line
  HomeOwner,
  // eslint-disable-next-line
  HomeAgent,
  XActive,
  // eslint-disable-next-line
  HomeOther,
  FacebookActive,
  LinkedInActive,
  InstagramActive,
} from "@landvoice/icons";
import useLongPress from "./UseLongPress";
import { Link, NavLink } from "react-router-dom";
import useAPI from "./UseApi";

const StatusTag = ({ status, date, where }) => {
  function formatDate(inputDate) {
    const parts = inputDate.split("-");
    const formattedDate = `${parseInt(parts[1], 10)}/${parseInt(
      parts[2],
      10
    )}/${parts[0]}`;
    return formattedDate;
  }

  return status === "" ? (
    <></>
  ) : (
    <div
      className={
        ` h-[28px] w-fit rounded-[6px]  py-[2px] px-2 gap-[6px] pb-2 lg:mb-4 ` +
        (status.toLocaleLowerCase() === "active"
          ? "bg-[#FAE6E6]"
          : status.toLocaleLowerCase() === "expired" ||
            status.toLocaleLowerCase() === "canceled" ||
            status.toLocaleLowerCase() === "withdrawn" ||
            status.toLocaleLowerCase() === "lis pendens" ||
            status.toLocaleLowerCase() === "notice of default" ||
            status.toLocaleLowerCase() === "fsbo"
          ? "bg-[#daf4e2]"
          : status.toLocaleLowerCase() === "notice of trustee sale" ||
            status.toLocaleLowerCase() === "notice of sale" ||
            status.toLocaleLowerCase() === "pending" ||
            status.toLocaleLowerCase() === "sold" ||
            status.toLocaleLowerCase() === "off Market" ||
            status.toLocaleLowerCase() === "unknown"
          ? "bg-[#FAF1E6]"
          : status.toLocaleLowerCase() === "bank owned"
          ? "bg-[#ECEFEF]"
          : "bg-transparent")
      }
    >
      <p
        className={
          `text-[14px] font-semibold leading-6 text-center whitespace-nowrap ` +
          (status.toLocaleLowerCase() === "active"
            ? "text-[#E5342E]"
            : status.toLocaleLowerCase() === "expired" ||
              status.toLocaleLowerCase() === "canceled" ||
              status.toLocaleLowerCase() === "withdrawn" ||
              status.toLocaleLowerCase() === "lis pendens" ||
              status.toLocaleLowerCase() === "notice of default" ||
              status.toLocaleLowerCase() === "fsbo"
            ? "text-[#36B25F]"
            : status.toLocaleLowerCase() === "notice of trustee sale" ||
              status.toLocaleLowerCase() === "notice of sale" ||
              status.toLocaleLowerCase() === "pending" ||
              status.toLocaleLowerCase() === "sold" ||
              status.toLocaleLowerCase() === "off Market" ||
              status.toLocaleLowerCase() === "unknown"
            ? "text-[#E5941B]"
            : status.toLocaleLowerCase() === "bank owned"
            ? "text-[#666666]"
            : " text-transparent")
        }
      >
        {`${status}${date ? ": " : ""} ${date ? formatDate(date) : ""}`}
      </p>
    </div>
  );
};

const DistressRate = ({ rate }) => {
  return (
    <>
      <div className="flex lg:gap-4 gap-2 items-center">
        <p className=" font-semibold text-[12px] lg:leading-4 md:leading-3 text-[#999999]">
          Level of Distress
        </p>
        <div className="flex gap-1">
          <div
            className={`h-2 md:w-20 w-14 rounded-lg bg-[#F6E3D2] ${
              parseInt(rate) > 0 ? "bg-[#F6E3D2]" : "bg-[#F5F7F7]"
            }`}
          ></div>
          <div
            className={`h-2 md:w-20 w-14 rounded-lg  ${
              parseInt(rate) > 1 ? "bg-[#FDCFB8]" : "bg-[#F5F7F7]"
            }`}
          ></div>
          <div
            className={`h-2 md:w-20 w-14 rounded-lg  ${
              parseInt(rate) > 2 ? "bg-[#F6A77F]" : "bg-[#F5F7F7]"
            }`}
          ></div>
          <div
            className={`h-2 md:w-20 w-14 rounded-lg  ${
              parseInt(rate) > 3 ? "bg-[#FA7532]" : "bg-[#F5F7F7]"
            }`}
          ></div>
        </div>
      </div>
    </>
  );
};

const InfoIcon = ({ icon, text, textSize = "12px" }) => {
  return (
    <div className={` flex min-w-[54px] gap-[4px] text-[#666666]`}>
      {icon}
      <p className={`text-[${textSize}]`}>{text}</p>
    </div>
  );
};

const Divider = ({ height = "h-4" }) => {
  return <div className={`w-[2px] ${height} bg-[#EBEBEB]`} />;
};

const HDivider = () => {
  return <div className="h-[2px] w-full max-w-[800px] bg-[#EBEBEB]" />;
};

const InfoText = ({ title, info, color = "text-[#999999]" }) => {
  return (
    <div className="h-[46px] min-w-[30px]  ">
      <p className="text-[12px] text-[#999999] whitespace-nowrap">{title}</p>
      <p className={`text-[16px]  leading-6 mt-2 whitespace-nowrap ${color}`}>
        {info}
      </p>
    </div>
  );
};

const InfoIconButton = ({ title, icon, buttonText, func, edit = false }) => {
  return (
    <div className=" h-[46px] min-w-[10px] cursor-pointer" onClick={func}>
      <p className="text-[12px] text-[#999999]">{title}</p>
      <div className={edit ? " editInfoButton" : "addInfoButton"}>
        {edit ? null : <Add style={{ height: "8px" }} />}
        <p className="text-[11px] font-bold text-center mx-auto leading-4 whitespace-nowrap">
          {buttonText}
        </p>
      </div>
    </div>
  );
};

const ContactCard = ({
  name,
  number,
  dnc,
  icon = "",
  hidden,
  click,
  property_id,
  list,
  type,
}) => {
  const { post } = useAPI();
  const [Hidden, setHidden] = useState(hidden);
  // eslint-disable-next-line
  const [phoneNumber, setPhoneNumber] = useState(number);
  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent the default context menu behavior
  };

  useEffect(() => {
    setHidden(hidden);
    // eslint-disable-next-line
  }, [number]);

  const onLongPress = () => {
    const phoneUrl = `tel:${number}`;
    window.location.href = phoneUrl;
  };

  const onClick = (click = () => {}) => {
    click();

    post(
      `/api/${list}/hide-phone`,
      {
        phone: number,
        property_id,
        hidden: !Hidden.hidden,
      },
      () => {}
    );
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };
  const longPressEvent = useLongPress(
    onLongPress,
    () => {
      onClick(click);
      setHidden({ hidden: !Hidden.hidden, tell: hidden.tell });
    },
    defaultOptions
  );

  return (
    <div className=" relative  flex w-fit ">
      <div
        onContextMenu={handleContextMenu}
        {...longPressEvent}
        // onClick={() => {
        //   onClick();

        //   setHidden(!Hidden);
        // }}
        className={
          "h-[60.5px]  min-w-[150px] border rounded-lg py-[8px]  border-[#999999] border-r-0 rounded-r-none pl-[8px] flex gap-[4px] items-center text-[#666666] " +
          (Hidden.hidden
            ? "opacity-50 crossOut"
            : " hover:text-[#F27935] hover:border-[#F27935] ")
        }
      >
        <div className="flex flex-col gap-[4px]">
          {name && name !== " " ? (
            <p className="font-bold text-[11px]  leading-4">
              {name.toUpperCase()}
            </p>
          ) : (
            <p className="font-bold text-[12px]  leading-4 italic">
              no name found
            </p>
          )}
          <div className={`flex gap-1 items-end`}>
            {
              //icon &&
              type === "M" && (
                <SvgMobile
                  className={`md:text-[12px] text-[12px] ${
                    dnc
                      ? "text-[#FA423C] fill-[#FA423C] "
                      : "text-[#36B25F] fill-[#36B25F]"
                  }`}
                />
              )
            }
            {
              //icon &&
              type === "B" && (
                <SvgBusiness
                  className={`md:text-[12px] text-[12px] ${
                    dnc
                      ? "text-[#FA423C] fill-[#FA423C] "
                      : "text-[#36B25F] fill-[#36B25F]"
                  }`}
                />
              )
            }
            {
              //icon &&
              type === "R" && (
                <SvgHome
                  className={`md:text-[12px] text-[12px] ${
                    dnc
                      ? "text-[#FA423C] fill-[#FA423C] "
                      : "text-[#36B25F] fill-[#36B25F]"
                  }`}
                />
              )
            }
            <div className={Hidden.hidden ? " relative" : ""}>
              <p
                className={
                  `  text-[11px]  leading-3  relative text-nowrap ` +
                  (dnc
                    ? "text-[#FA423C] fill-[#FA423C] "
                    : "text-[#36B25F] fill-[#36B25F] font-[700]") +
                  (Hidden.hidden ? " " : "")
                }
              >
                {" "}
                {formatPhoneNumber(number)}
              </p>
            </div>
            {dnc && (
              <p className="text-[10px]  leading-3  text-[#FA423C] font-bold">
                DNC
              </p>
            )}
          </div>
        </div>
      </div>
      <div
        onClick={() => {
          if (!Hidden.hidden) copyTextToClipboard(number);
        }}
        className={
          "h-[60.5px] max-w-[25px] min-w-[25px] border rounded-lg  border-l-0 border-[#999999]  rounded-l-none flex justify-center items-center text-[#666666] " +
          (Hidden.hidden
            ? "opacity-50 crossOut"
            : " hover:text-[#F27935] hover:border-[#F27935] hover:bg-[#f27a3520] ")
        }
      >
        <svg
          width="12"
          height="12"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1023_30675)">
            <path
              d="M23.5594 3.31078L20.6883 0.439687C20.4094 0.158016 20.025 0 19.6265 0H11.9578C10.3008 0 8.95779 1.34344 8.95779 3L8.95871 15C8.99998 16.6594 10.3453 18 12 18H21C22.65 18 24 16.65 24 15V4.37109C24 3.97359 23.8406 3.59203 23.5594 3.31078ZM21.75 15C21.75 15.4142 21.4142 15.75 21 15.75H11.9578C11.5435 15.75 11.2078 15.4142 11.2078 15V3.00609C11.2078 2.59191 11.5436 2.25609 11.9578 2.25609H17.9578L18 4.5C18 5.32828 18.6717 6 19.5 6H21.7078V15H21.75ZM12.75 21C12.75 21.4142 12.4142 21.75 12 21.75H2.95779C2.54351 21.75 2.20779 21.4142 2.20779 21L2.24904 9.00469C2.24904 8.5905 2.58486 8.25469 2.99904 8.25469H7.49998V6H2.99951C1.34248 6 -0.000488281 7.34297 -0.000488281 9L-2.89062e-05 21C7.42187e-05 22.6547 1.34342 24 2.99998 24H12C13.65 24 15 22.65 15 21V19.5H12.7922L12.75 21Z"
              fill="#666666"
            />
          </g>
          <defs>
            <clipPath id="clip0_1023_30675">
              <rect width="24" height="24" fill="#666666" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  );
};

const MlsTitleAmenities = ({
  title,
  beds,
  bath,
  SqrFeet,
  lotSize,
  buildDate,
  type,
  price = 0,
  dom,
  notes = [],
  tags = [],
  openNotes,
  openTags,
  status,
  statusDate,
  publisheDAte,
  auctionDate,
  all = true,
  where,
  mls_number,
  openDetails = () => {},
  hasDom = false,
  id,
  mapLink,
  isDetails = false,
  isDistressed = false,
  distressedLevel = 0,
}) => {
  return (
    <div
      className={`md:min-w-[200px] lg:w-[550px] max-w-[90vw] break-inside-avoid ${
        all ? "xl:min-h-[218px]" : ""
      }`}
    >
      {isDistressed && !status ? (
        <DistressRate rate={distressedLevel} />
      ) : (
        <div className="flex gap-5 text-[#999999]  pr-3">
          <StatusTag
            status={status || "Unknown"}
            where={where}
            date={statusDate || ""}
          />
          {mls_number && <p className="text-[14px]">MLS ID: {mls_number}</p>}
        </div>
      )}

      <div className="min-h-[42px] max-w-[90vw] mb-3 lg:mb-8">
        <Link
          target={isDetails ? "_blank" : "_self"}
          to={isDetails ? mapLink : null}
        >
          <div className="flex  mb-3 group  w-fit ">
            <p className=" mt-[auto] font-semibold text-[20px] text-[#666666] text-wrap	 cursor-pointer align-baseline  ">
              <span
                className="hover:text-[#F27935] leading-5 align-bottom"
                onClick={() => {
                  openDetails({ id });
                }}
              >{`${title}`}</span>
              <span className=" group-hover:inline ml-2 hover:text-[#F27935] align-bottom ">
                {" "}
                <SvgMapSolidV20
                  className=" text-[#666666] hover:text-[#F27935] fill-[#F27935] cursor-pointer align-bottom inline"
                  onClick={() => {
                    const aTag = document.createElement("a");
                    aTag.setAttribute("target", `_blank`);
                    aTag.href = mapLink;

                    document.body.appendChild(aTag);
                    aTag.click();
                    aTag.remove();
                  }}
                />{" "}
                <SvgZillow
                  onClick={() => {
                    const aTag = document.createElement("a");
                    aTag.setAttribute("target", `_blank`);
                    aTag.href = `https://www.zillow.com/homes/${title.replace(
                      " ",
                      "-"
                    )}`;

                    document.body.appendChild(aTag);
                    aTag.click();
                    aTag.remove();
                  }}
                />{" "}
              </span>
            </p>
          </div>
        </Link>

        <div className=" max-w-[90vw] min-h-[16px] flex gap-3 flex-wrap">
          <InfoIcon
            gap="8px"
            icon={<Bed />}
            text={`${beds ? beds : "--"} Bed`}
          />
          <Divider />
          <InfoIcon
            gap="8px"
            icon={<Bath />}
            text={`${bath ? bath : "--"} Bath`}
          />
          <Divider />
          <InfoIcon
            gap="8px"
            icon={<Grid />}
            text={`${
              SqrFeet
                ? SqrFeet.toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2, // You can adjust this based on your requirements
                  })
                : "--"
            }  Sq Ft`}
          />
          <Divider />
          <InfoIcon
            gap="8px"
            icon={<Tree />}
            text={` LtSz ${lotSize ? lotSize : "--"}`}
          />
          <Divider />
          <InfoIcon
            gap="8px"
            icon={<CalendarClock />}
            text={`Built ${buildDate ? buildDate : "--"}`}
          />
          <Divider />
          <InfoIcon
            gap="8px"
            icon={<Building />}
            text={`${type ? type : "--"} `}
          />
        </div>
      </div>
      {all && (
        <div className="flex md:gap-[30px] gap-3  max-w-[90vw] flex-col sm:flex-row">
          <div className="flex md:gap-[30px] gap-3 ">
            <InfoText
              title={auctionDate ? "List Price" : "Price"}
              info={price || "--"}
              color={"text-[#666666]"}
            />
            {hasDom && <InfoText title={"DOM"} info={dom} />}
            {publisheDAte && (
              <InfoText title={"Publish Date"} info={publisheDAte} />
            )}
            {auctionDate && (
              <InfoText title={"Auction Date"} info={auctionDate} />
            )}
          </div>

          <div className="flex md:gap-[30px] gap-3 ">
            <InfoIconButton
              count={notes.count}
              title={"Notes"}
              icon={<ViewEditNote style={{ height: "8px" }} />}
              buttonText={
                notes.length && notes[0]?.note && notes[0]?.note !== " "
                  ? `${notes[0]?.note.substring(0, 20)}${
                      notes[0]?.note.length > 20 ? "..." : ""
                    }`
                  : "Add Note"
              }
              func={() => {
                openNotes();
              }}
              edit={notes.length && notes[0]?.note && notes[0]?.note !== " "}
            />
            <InfoIconButton
              count={tags.count}
              title={"Tags"}
              icon={<Tag style={{ height: "8px" }} />}
              buttonText={
                tags.length
                  ? tags.slice(0, 2).join(" ").substring(0, 20)
                  : "Add Tag"
              }
              func={() => {
                openTags();
              }}
              edit={tags.length}
            />
          </div>
        </div>
      )}
    </div>
  );
};

function capitalizeWords(inputString) {
  if (inputString)
    return inputString
      .toLowerCase()
      .replace(/(^|\s)\S/g, (match) => match.toUpperCase());
}

const OwnerContacts = ({ owner }) => {
  return (
    <div className="lg:flex   flex-col h-full lg:py-6 py-3  md:w-[216px]">
      <p className=" text-[14px] text-[#666666] lg:leading-6 font-semibold  lg:mb-4">
        Owner's Information
      </p>
      {owner?.full_name && owner?.full_name !== " " ? (
        <p className=" text-[16px] text-[#666666] lg:leading-6 lg:mb-3 mb-1">
          {capitalizeWords(owner?.full_name)}
        </p>
      ) : (
        <p className=" text-[16px] text-[#666666] leading-6 mb-3 italic">
          Owner's Name not found
        </p>
      )}
      {owner?.property?.street_address ? (
        <p className=" text-[14px] text-[#999999] leading-[14px] mb-[13px]">
          {owner?.property.street_address
            ? `${owner?.property?.street_address || "--"}, ${
                owner?.property?.city || "--"
              }, ${owner?.property?.state || "--"} ${
                owner?.property?.postal_code || "--"
              }`
            : "--"}
        </p>
      ) : (
        <p className=" text-[14px] text-[#999999] leading-[14px] mb-[13px] italic">
          No address found
        </p>
      )}
      {/* <div className="flex gap-2 max-h-4 mb-2">
        <Phone />
        <p className=" leading-[14px] text-[14px] text-[#999999]">
          {owner?.phone || "--"}
        </p>
      </div> */}
      <div className="flex gap-2 max-h-4 mb-2">
        <Mail />
        {owner?.email ? (
          <p className=" leading-[14px] text-[14px] text-[#999999] underline cursor-pointer text-ellipsis overflow-hidden">
            <a href={`mailto:${owner?.email}`}>{owner?.email}</a>
          </p>
        ) : (
          <p className=" leading-[14px] text-[14px] text-[#999999] italic">
            No email found
          </p>
        )}
      </div>
      <HDivider />
      <div className="h-4 mt-2 flex gap-[14px]">
        <p className="font-semibold text-[12px] text-[#999999] leading-[14px]">
          Socials:
        </p>

        <div className="flex gap-[20px] items-center">
          {owner?.facebook ? (
            <NavLink to={owner?.facebook} target="blank">
              <FacebookActive />{" "}
            </NavLink>
          ) : (
            <FacebookDisabled />
          )}
          {owner?.instagram ? (
            <NavLink to={owner?.facebook} target="blank">
              {" "}
              <InstagramActive />
            </NavLink>
          ) : (
            <InstagramDisabled />
          )}
          {owner?.twitter ? (
            <NavLink to={owner?.twitter} target="blank">
              <XActive />
            </NavLink>
          ) : (
            <XDisabled />
          )}
          {owner.Linkedin ? (
            <NavLink to={owner?.Linkedin} target="blank">
              <LinkedInActive />
            </NavLink>
          ) : (
            <LinkedInDisabled />
          )}
        </div>
      </div>
    </div>
  );
};

// const formatPhoneNumber = (phoneNumber) => {
//  phoneNumber = "520312XXXX";
//   const cleaned = phoneNumber?.replace(/\D/g, "");
//   const formatted = cleaned?.replace(/^(\d{3})(\d{3})(\d{4})$/, "$1-$2-$3");
//   return formatted;
// };

const formatPhoneNumber = (phoneNumber) => {
  // phoneNumber = "520312XXXX";
  // First, we'll check if the input is a string and not empty
  if (typeof phoneNumber !== "string" || phoneNumber.trim() === "") {
    return "";
  }

  // Remove all non-digit and non-X characters
  const cleaned = phoneNumber.replace(/[^\dX]/g, "");

  // Check if the cleaned string has exactly 10 characters
  if (cleaned.length !== 10) {
    return cleaned; // Return as is if it doesn't match the expected length
  }

  // Use a single regex to format both masked and non-masked numbers
  return cleaned.replace(/^(\d{3})(\d{3})(\d{4}|XXXX)$/, "$1-$2-$3");
};

const formatDate = (inputDate) => {
  if (inputDate) {
    const dateParts = inputDate?.split("-" || "/");
    const month = parseInt(dateParts[1], 10);
    const day = parseInt(dateParts[2], 10);
    const year = parseInt(dateParts[0], 10);

    const date = new Date(year, month - 1, day); // Note: Months are zero-indexed in JavaScript Date object

    const formattedDate = date.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    return formattedDate;
  }
};

const timeAgo = (date) => {
  const currentTime = new Date();
  const timeDifference = currentTime - new Date(date); // Difference in milliseconds
  const seconds = timeDifference / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;
  const months = days / 30;
  const years = days / 365;

  if (seconds < 60) {
    return "Just now";
  } else if (minutes < 60) {
    return `${Math.floor(minutes)} minute${
      Math.floor(minutes) > 1 ? "s" : ""
    } ago`;
  } else if (hours < 24) {
    return `${Math.floor(hours)} hour${Math.floor(hours) > 1 ? "s" : ""} ago`;
  } else if (days < 30) {
    return `${Math.floor(days)} day${Math.floor(days) > 1 ? "s" : ""} ago`;
  } else if (months < 12) {
    return `${Math.floor(months)} month${
      Math.floor(months) > 1 ? "s" : ""
    } ago`;
  } else {
    return `${Math.floor(years)} year${Math.floor(years) > 1 ? "s" : ""} ago`;
  }
};

function removeParamFromString(str, key, value) {
  // Escape special RegExp characters in key and value to avoid errors in regex
  const escapedKey = escapeRegExp(key);
  const escapedValue = escapeRegExp(value);

  // Create a dynamic regex that matches &key=value
  const regex = new RegExp("&" + escapedKey + "=" + escapedValue, "g");

  // Replace the matching pattern with an empty string
  return str.replace(regex, "");
}

// Helper function to escape RegExp special characters
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

const copyTextToClipboard = async (textToCopy) => {
  if ("clipboard" in navigator) {
    try {
      await navigator.clipboard.writeText(textToCopy);
    } catch (err) {
      console.error("Failed to copy text: ", err);
      alert("Failed to copy text");
    }
  } else {
    // Fallback for older browsers
    const textarea = document.createElement("textarea");
    textarea.value = textToCopy;
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Failed to copy text: ", err);
      alert("Failed to copy text");
    }
    document.body.removeChild(textarea);
  }
};

function formatTimestamp(timestamp) {
  // Convert timestamp to Date object
  const date = new Date(timestamp);

  // Get the month, day, and year from the date object
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Pad with leading zeros if necessary
  const day = date.getDate().toString().padStart(2, "0"); // Pad with leading zeros if necessary
  const year = date.getFullYear();

  // Combine into mm/dd/yyyy format and return
  return `${month}/${day}/${year}`;
}

const SvgMapSolidV20 = ({ title, titleId, ...props }) => (
  <svg
    height="20px"
    viewBox="0 0 24 22"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
    className=" fill-[#666666] hover:fill-[#F27935] cursor-pointer inline"
    //{...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M17 5.333c0 2.275-3.046 6.33-4.383 7.963-.321.437-.913.437-1.234 0C10.046 11.663 7 7.608 7 5.333a5 5 0 1 1 10 0Zm-5 1.334a1.666 1.666 0 1 0 0-3.333 1.666 1.666 0 0 0 0 3.333Zm5.733 1.158c.021-.05.042-.1.063-.154l4.833-1.933A1 1 0 0 1 24 6.667V17.95c0 .408-.25.775-.63.93l-6.037 2.412V8.683c.146-.287.28-.57.4-.858Zm-11.466 0c.12.288.254.57.4.858v10.475L1.37 21.28C.715 21.504 0 21.06 0 20.35V9.067c0-.409.249-.813.629-.93l5.104-2.041c.1.592.304 1.179.534 1.729Zm7.391 6.304A35.162 35.162 0 0 0 16 10.96v10.387l-8-2.288v-8.1a35.156 35.156 0 0 0 2.342 3.171 2.074 2.074 0 0 0 3.316 0Z" />
  </svg>
);

const SvgZillow = ({ title, titleId, ...props }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
    className="ml-2 fill-[#666666] hover:fill-[#1277E1] cursor-pointer inline"
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path d="M10.028 4.315c.073-.017.105.008.15.057.252.28 1.058 1.264 1.277 1.532.04.049.012.102-.02.13C9.808 7.303 7.998 9.1 6.99 10.385c-.02.03-.004.03.012.025 1.757-.757 5.884-1.965 7.743-2.302V5.754L7.47 0 .184 5.75v2.573c2.256-1.34 7.477-3.42 9.844-4.008Z" />
    <path d="M4.108 14.203c-.061.033-.11.024-.16-.024L2.592 12.56c-.037-.045-.041-.07.008-.138 1.05-1.537 3.192-3.932 4.558-4.945.025-.016.017-.036-.012-.024-1.423.464-5.469 2.204-6.965 3.05V16H14.74v-5.294c-1.984.337-7.929 2.106-10.633 3.497Z" />
  </svg>
);

const SvgMobile = ({ className }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M17.25 0H6.75C5.09297 0 3.75 1.34297 3.75 3V21C3.75 22.657 5.09297 24 6.75 24H17.25C18.907 24 20.25 22.657 20.25 21V3C20.25 1.34297 18.9047 0 17.25 0ZM18 21C18 21.4135 17.6635 21.75 17.25 21.75H6.75C6.33656 21.75 6 21.4125 6 21V3C6 2.58647 6.33647 2.25 6.75 2.25H17.25C17.6625 2.25 18 2.58656 18 3V21ZM13.5 18.75H10.5C10.0858 18.75 9.75 19.0858 9.75 19.5C9.75 19.9142 10.0858 20.25 10.5 20.25H13.5C13.9143 20.25 14.25 19.9142 14.25 19.5C14.25 19.0858 13.9125 18.75 13.5 18.75Z" />
  </svg>
);

const SvgBusiness = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.33333 18.6666H8C8.73417 18.6666 9.33333 18.0674 9.33333 17.3333V2.66659C9.33333 1.93242 8.73417 1.33325 8 1.33325H5.33333C4.6 1.33325 4 1.93242 4 2.66659V17.3333C4 18.0666 4.6 18.6666 5.33333 18.6666ZM22 2.66659H10.6667V17.3333C10.6667 18.802 9.46875 19.9999 8 19.9999H5.33333C3.86458 19.9999 2.66667 18.802 2.66667 17.3333V2.66659H2C0.895833 2.66659 0 3.56242 0 4.66659V20.6666C0 21.7708 0.895833 22.6666 2 22.6666H22C23.1042 22.6666 24 21.7708 24 20.6666V4.66659C24 3.56242 23.1042 2.66659 22 2.66659ZM16 19.3333C16 19.703 15.7031 19.9999 15.3333 19.9999H14C13.6302 19.9999 13.3333 19.703 13.3333 19.3333V17.9999C13.3333 17.6301 13.6302 17.3333 14 17.3333H15.3333C15.7031 17.3333 16 17.6301 16 17.9999V19.3333ZM16 13.9999C16 14.3697 15.7031 14.6666 15.3333 14.6666H14C13.6292 14.6666 13.3333 14.3708 13.3333 13.9999V12.6666C13.3333 12.2958 13.6292 11.9999 14 11.9999H15.3333C15.7042 11.9999 16 12.2958 16 12.6666V13.9999ZM21.3333 19.3333C21.3333 19.703 21.0365 19.9999 20.6667 19.9999H19.3333C18.9635 19.9999 18.6667 19.703 18.6667 19.3333V17.9999C18.6667 17.6301 18.9635 17.3333 19.3333 17.3333H20.6667C21.0365 17.3333 21.3333 17.6301 21.3333 17.9999V19.3333ZM21.3333 13.9999C21.3333 14.3697 21.0365 14.6666 20.6667 14.6666H19.3333C18.9625 14.6666 18.6667 14.3708 18.6667 13.9999V12.6666C18.6667 12.2958 18.9625 11.9999 19.3333 11.9999H20.6667C21.0375 11.9999 21.3333 12.2958 21.3333 12.6666V13.9999ZM21.3333 9.33325H13.3333V5.33325H21.3333V9.33325Z"
      fill="#666666"
    />
  </svg>
);

const SvgHome = ({ className }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.6458 9.90421C24.0667 10.2625 24.1208 10.8917 23.7625 11.3125C23.4042 11.7334 22.775 11.7875 22.3542 11.4292L21.3333 10.5625V18.3334C21.3333 20.175 19.8417 21.6667 18 21.6667H6C4.15916 21.6667 2.66667 20.175 2.66667 18.3334V10.5625L1.64708 11.4292C1.22583 11.7875 0.594998 11.7334 0.237498 11.3125C-0.119793 10.8917 -0.0680851 10.2625 0.35304 9.90421L11.3542 0.570874C11.725 0.254207 12.275 0.254207 12.6458 0.570874L23.6458 9.90421ZM6 19.6667H8V13.3334C8 12.4125 8.74583 11.6667 9.66667 11.6667H14.3333C15.2542 11.6667 16 12.4125 16 13.3334V19.6667H18C18.7375 19.6667 19.3333 19.0709 19.3333 18.3334V8.86671L12 2.64462L4.66667 8.86671V18.3334C4.66667 19.0709 5.2625 19.6667 6 19.6667ZM10 19.6667H14V13.6667H10V19.6667Z"
      fill="#666666"
    />
  </svg>
);

export {
  InfoIcon,
  Divider,
  InfoText,
  InfoIconButton,
  HDivider,
  ContactCard,
  MlsTitleAmenities,
  StatusTag,
  OwnerContacts,
  formatPhoneNumber,
  formatDate,
  timeAgo,
  removeParamFromString,
  formatTimestamp,
  DistressRate,
};
