import React, { useEffect, useState } from "react";
import { SecondaryButtons } from "./buttons";
import { useAuth } from "../utils/auth/assist/authContext";
import useAPI from "./UseApi";

const LoginAs = ({ setLoginAs = () => {} }) => {
  const { user } = useAuth();
  const { get, getWithStatus } = useAPI();
  const [impersonante, setImpersonante] = useState(false);
  const getUser = async () => {
    const user = await getWithStatus("/api/core/account");
    if (user?.headers["x-impersonate"]?.toLowerCase() === "true")
      setImpersonante(true);
    setLoginAs(true);
    console.log(user);
  };

  // getUser();

  useEffect(() => {
    // Read data from session storage
    const storedData = sessionStorage.getItem("loginAs");
    getUser();
    if (storedData) {
      setLoginAs(JSON.parse(storedData));
    }
    // eslint-disable-next-line
  }, []);

  const stopImpersonation = async () => {
    await get("/api/impersonate/stop", () => {});
  };
  return (
    <>
      {impersonante === true && (
        <div className="flex justify-between items-center h-[60px] w-screen top-0 left-0 fixed bg-red-700 text-white px-5 z-[5]">
          <div></div>
          <p>
            Logged in as:{" "}
            <span className=" cursor-pointer  italic">
              {user?.first_name} {user?.last_name}
            </span>
          </p>

          <SecondaryButtons
            label={"Stop"}
            onClick={() => {
              setImpersonante(!true);
              setLoginAs(!true);
              stopImpersonation();
            }}
          />
        </div>
      )}
    </>
  );
};

export default LoginAs;
