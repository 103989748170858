import pdfDownload from "../common/pdfDownload";

const sortingOptions = [
  { name: "Price (High to Low)" },
  { name: "Price (Low to High)" },
  { name: "Published Date (Newest)" },
  { name: "Published Date (Oldest)" },
  // { name: "Status" },
  { name: "Status Date (Newest)" },
  { name: "Status Date (Oldest)" },
  { name: "Days on Market (High to Low)" },
  { name: "Days on Market (Low to High)" },
  { name: "City" },
  { name: "State" },
  // { name: "Beds (High to Low)" },
  // { name: "Beds (Low to High)" },
  // { name: "Baths (High to Low)" },
  // { name: "Baths (Low to High)" },
  { name: "ZIP (High to Low)" },
  { name: "ZIP (Low to High)" },
  { name: "Sq Ft (High to Low)" },
  { name: "Sq Ft (Low to High)" },
  { name: "Lot Size (High to Low)" },
  { name: "Lot Size (Low to High)" },
  { name: "Year Built (Newest)" },
  { name: "Year Built (Oldest)" },
  { name: "Auction Date (Newest)" },
  { name: "Auction Date (Oldest)" },
];

const distressedSortingOptions = [
  { name: "Price (High to Low)" },
  { name: "Price (Low to High)" },
  { name: "Published Date (Newest)" },
  { name: "Published Date (Oldest)" },
  { name: "City" },
  { name: "State" },
  { name: "ZIP (High to Low)" },
  { name: "ZIP (Low to High)" },
  { name: "Sq Ft (High to Low)" },
  { name: "Sq Ft (Low to High)" },
  { name: "Lot Size (High to Low)" },
  { name: "Lot Size (Low to High)" },
  { name: "Year Built (Newest)" },
  { name: "Year Built (Oldest)" },
  { name: "Distressed level (High to Low)" },
  { name: "Distressed level (Low to High)" },
];

const DistressedNamesToRemove = [
  "Days on Market (High to Low)",
  "Days on Market (Low to High)",
  "Auction Date (Newest)",
  "Auction Date (Oldest)",
  "Status Date (Newest)",
  "Status Date (Oldest)",
];

const leadFilterOptions = [
  { name: "Show all " },
  { name: "Show only with phone numbers" },
  { name: "Show only without phone numbers" },
  { name: "Show only with email" },
];

const searchDate = [
  { name: "Lead Delivery Date" },
  { name: "Original Status Date" },
];

const distressedLevel = [
  { name: "0 - Not Distressed" },
  { name: "1 - Somewhat Distressed" },
  { name: "2 - Fairly Distressed" },
  { name: "3 - Highly Distressed" },
  { name: "4 - Extremely Distressed" },
];

const PreforeclosureStatusType = [
  { name: "Lis Pendens", code: "L" },
  { name: "Notice of Default", code: "D" },
  { name: "Notice of Sale", code: "S" },
  { name: "Notice of Trustee Sale", code: "T" },
  { name: "Bank Owned (REO)", code: "B" },
];

const showActive = [{ name: "Show Actves" }, { name: "Do Not Show Actives" }];

const bedsList = [
  { name: "Any" },
  { name: "2+" },
  { name: "3+" },
  { name: "4+" },
  { name: "5+" },
  { name: "6+" },
  { name: "7+" },
  { name: "10+" },
];

const bathsList = [
  { name: "Any" },
  { name: "2+" },
  { name: "3+" },
  { name: "4+" },
  { name: "5+" },
  { name: "8+" },
];

const squareFeet = [
  { name: "Any" },
  { name: "1000+" },
  { name: "1500+" },
  { name: "2000+" },
  { name: "2500+" },
  { name: "3000+" },
  { name: "4000+" },
  { name: "6000+" },
];

const PropertyTypeEnum = {
  S: "Single Family",
  H: "Home",
  C: "Condo",
  W: "Twin",
  T: "Townhouse",
  M: "Mobile",
  R: "Recreational",
  G: "Acreage",
  A: "Apartment",
  O: "Other",
  X: "Multiplex",
};

const StatusTypeEnum = {
  A: "Active",
  P: "Pending",
  S: "Sold",
  X: "Expired",
  W: "Withdrawn",
  C: "Canceled",
  O: "Off Market",
  U: "Unknown",
  null: "FSBO",
};

const StatusTypeEnumWOFsbo = {
  A: "Active",
  P: "Pending",
  S: "Sold",
  X: "Expired",
  W: "Withdrawn",
  C: "Canceled",
  O: "Off Market",
};

const PreforeclosureStatusTypeEnum = {
  A: "Active",
  D: "Notice of Default",
  L: "Lis Pendens",
  T: "Notice of Trustee Sale",
  S: "Notice of Sale",
  B: "Bank Owned",
  U: "Unknown",
  null: "FSBO",
};

const downLoadFunc = [
  {
    name: "Download As CSV",
    clickableFunction: () => {
      pdfDownload("/api/expired/listing/csv", "expiredlisting", "csv");
    },
  },
  {
    name: "Print All Records",
    clickableFunction: () => {},
  },
  //{ name: "Print 1 Record/Page", clickableFunction: () => {} },
];

// Sorting functions
// const sortByPriceHighToLow = (a, b) =>
//   parseFloat(b?.list_price || b?.price) - parseFloat(a?.list_price || a?.price);
// const sortByPriceLowToHigh = (b, a) =>
//   parseFloat(b?.list_price || b?.price) - parseFloat(a?.list_price || a.price);

// const sortByDaysOnMarketHighToLow = (b, a) =>
//   a?.days_on_market - b?.days_on_market;
// const sortByDaysOnMarketLowToHigh = (b, a) =>
//   b?.days_on_market - a?.days_on_market;

// const sortByCity = (a, b) => {
//   const cityA = (a.property.city || a.city).toUpperCase();
//   const cityB = (b.property.city || b.city).toUpperCase();

//   if (cityA < cityB) {
//     return -1;
//   } else if (cityA > cityB) {
//     return 1;
//   } else {
//     return 0;
//   }
// };
// const sortByState = (a, b) => {
//   const state = (a?.property?.state || a.stste).toUpperCase();
//   const state2 = (b?.property?.state || b.state).toUpperCase();

//   if (state < state2) {
//     return -1;
//   } else if (state > state2) {
//     return 1;
//   } else {
//     return 0;
//   }
// };

// const sortByZIPHighToLow = (a, b) =>
//   parseInt(b?.property?.postal_code || b?.postal_code) -
//   parseInt(a?.property?.postal_code || a?.postal_code);

// const sortByZIPLowToHigh = (a, b) =>
//   parseInt(a?.property?.postal_code || a?.postal_code) -
//   parseInt(b?.property?.postal_code || b?.postal_code);

// const sortByStatus = (a, b) => {
//   const statusA = StatusTypeEnum[a.status || a.listing_type];
//   const statusb = StatusTypeEnum[b.status || b.listing_type];

//   if (statusA < statusb) {
//     return -1;
//   } else if (statusA > statusb) {
//     return 1;
//   } else {
//     return 0;
//   }
// };

// const sortByListDateNewest = (a, b) =>
//   new Date(b?.publish_date).getTime() - new Date(a?.publish_date).getTime();

// const sortByListDateOldest = (a, b) =>
//   new Date(a?.publish_date).getTime() - new Date(b?.publish_date).getTime();

// const sortByStatusDateNewest = (a, b) =>
//   new Date(b?.status_date).getTime() - new Date(a?.status_date).getTime();

// const sortByStatusDateOldest = (a, b) =>
//   new Date(a?.status_date).getTime() - new Date(b?.status_date).getTime();

// const sortBySqFtHighToLow = (a, b) => b?.square_feet - a?.square_feet;
// const sortBySqFtLowToHigh = (a, b) => a?.square_feet - b?.square_feet;

// const sortByLotSizeHighToLow = (a, b) =>
//   parseFloat(b?.acres) - parseFloat(a?.acres);
// const sortByLotSizeLowToHigh = (a, b) =>
//   parseFloat(a?.acres) - parseFloat(b?.acres);

// const sortByYearBuiltNewest = (a, b) => b?.year_built - a?.year_built;
// const sortByYearBuiltOldest = (a, b) => a?.year_built - b?.year_built;

const sortListings = (criterion) => {
  let sortvar = "";
  switch (criterion) {
    case "Price (High to Low)":
      return (sortvar = "sort_dir=desc&sort=price");
    case "Price (Low to High)":
      return (sortvar = "sort_dir=asc&sort=price");

    case "Days on Market (High to Low)":
      return (sortvar = "sort_dir=desc&sort=dom");
    case "Days on Market (Low to High)":
      return (sortvar = "sort_dir=asc&sort=dom");

    case "City":
      return (sortvar = "sort_dir=asc&sort=city");

    case "Status":
      return (sortvar = "sort_dir=desc&sort=year_built");
    case "State":
      return (sortvar = "sort_dir=desc&sort=state");

    case "ZIP (High to Low)":
      return (sortvar = "sort_dir=desc&sort=postal_code");
    case "ZIP (Low to High)":
      return (sortvar = "sort_dir=asc&sort=postal_code");

    case "Distressed level (High to Low)":
      return (sortvar = "sort_dir=desc&sort=distressed_level");
    case "Distressed level (Low to High)":
      return (sortvar = "sort_dir=asc&sort=distressed_level");

    case "Status Date (Newest)":
      return (sortvar = "sort_dir=desc&sort=status_date");
    case "Status Date (Oldest)":
      return (sortvar = "sort_dir=asc&sort=status_date");

    case "Published Date (Newest)":
      return (sortvar = "sort_dir=desc&sort=date");

    case "Published Date (Oldest)":
      return (sortvar = "sort_dir=asc&sort=date");

    case "Sq Ft (High to Low)":
      return (sortvar = "sort_dir=desc&sort=sq_ft");

    case "Sq Ft (Low to High)":
      return (sortvar = "sort_dir=asc&sort=sq_ft");

    case "Lot Size (High to Low)":
      return (sortvar = "sort_dir=desc&sort=lot_size");

    case "Lot Size (Low to High)":
      return (sortvar = "sort_dir=ascc&sort=lot_size");

    case "Year Built (Newest)":
      return (sortvar = "sort_dir=desc&sort=year_built");

    case "Year Built (Oldest)":
      // eslint-disable-next-line
      return (sortvar = "sort_dir=asc&sort=year_built");

    case "Auction Date (Newest)":
      return (sortvar = "sort_dir=desc&sort=auction_date");

    case "Auction Date (Oldest)":
      // eslint-disable-next-line
      return (sortvar = "sort_dir=asc&sort=auction_date");

    default:
      return null;
  }
};

const handlePrintAll = () => {
  window.print();
};

const generateRandomString = (length) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export {
  sortingOptions,
  distressedSortingOptions,
  leadFilterOptions,
  searchDate,
  showActive,
  bedsList,
  bathsList,
  squareFeet,
  PropertyTypeEnum,
  StatusTypeEnum,
  downLoadFunc,
  PreforeclosureStatusTypeEnum,
  distressedLevel,
  sortListings,
  handlePrintAll,
  generateRandomString,
  DistressedNamesToRemove,
  PreforeclosureStatusType,
  StatusTypeEnumWOFsbo,
};
