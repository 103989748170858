import { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";

const useAPI = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(false);
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [CSRFToken, setCSRFToken] = useState("0");

  const fetchData = async (status = false, url, method = "GET", body = {}) => {
    setLoading(true);
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-CSRFToken": CSRFToken,
      };

      const response = await axios({
        method,
        url,
        data: body,
        headers: headers,
      });

      const headerValue =
        response.headers["x-impersonate"] ||
        response.headers["x-impersonate"]?.toLowerCase() === "true" ||
        response.headers["x-impersonate"] === true
          ? true
          : false;

      if (headerValue === true) {
        sessionStorage.setItem("loginAs", JSON.stringify({ state: true }));
        console.log("is true");
      } else {
        sessionStorage.setItem("loginAs", JSON.stringify({ state: false }));
        console.log("is false");
      }

      setData({ ...response.data, status: response.status });
      setStatus(response.status);
      setError(false);
      if (status)
        return {
          ...response.data,
          status: response.status,
          headers: response.headers,
        };
      if (!status) return response.data;
    } catch (error) {
      setError(true);
      throw error; // Re-throwing the error for better error handling in components
    } finally {
      setLoading(false);
    }
  };

  const getWithStatus = async (url, callback) => {
    try {
      const result = await fetchData(true, url, "GET");
      if (callback) callback();
      return result;
    } catch (error) {
      // Handle error if needed
    }
  };

  const get = async (url, callback) => {
    try {
      const result = await fetchData(false, url, "GET");
      if (callback) callback();
      return result;
    } catch (error) {
      // Handle error if needed
    }
  };

  const post = async (url, body, callback) => {
    try {
      const result = await fetchData(false, url, "POST", body);
      if (callback) callback();
      return result;
    } catch (error) {
      // Handle error if needed
    }
  };

  const clearErrorMessage = () => {
    setError(false);
  };

  useEffect(() => {
    const cookies = new Cookies();
    const cookieValue = cookies.get("csrftoken");
    setCSRFToken(cookieValue);
  }, []);

  return {
    data,
    error,
    loading,
    get,
    getWithStatus,
    post,
    clearErrorMessage,
    status,
  };
};

export default useAPI;
