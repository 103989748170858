import React from "react";
import { Link } from "react-router-dom";
import pdfDownload from "../../common/pdfDownload";

const OtherScripts = () => {
  const scripts = [
    {
      name: "Discount Broker",
      description: "Responses to requests to discount agent commissions. ",
      link: "https://cdn.landvoice.com/pdf/Discount+Broker.pdf",
    },
    {
      name: "Listing with a discount broker ",
      description: "More responses to requests to discount agent commissions. ",
      link: "https://cdn.landvoice.com/pdf/Listing+with+a+discount+broker.pdf",
    },
    {
      name: "Negotiations ",
      description:
        "Script about the need for an agent to negotiate on the behalf of the property owner ",
      link: "https://cdn.landvoice.com/pdf/Negotiations.pdf",
    },
    {
      name: "We already have an agent ",
      description:
        "Scripts for prospects who already have another agent in mind. ",
      link: "https://cdn.landvoice.com/pdf/We+already+have+an+Agent.pdf",
    },
    {
      name: "Your fee is too high  ",
      description:
        "Script about the need for an agent to negotiate on the behalf of the property owner ",
      link: "https://cdn.landvoice.com/pdf/Your+fee+is+too+high.pdf",
    },
    {
      name: "Same questions",
      description:
        "Script to respond to common statement that “agents are all the same”.",
      link: "https://cdn.landvoice.com/pdf/same_questions.pdf",
    },
  ];
  return (
    <section className=" min-h-[406px] bg-white rounded-[8px] p-[32px] mb-[16px] border-[#EBEBEB] border-[1px]">
      <p className=" text-[#666666] text-[28px] leading-[28px] font-[500] mb-[32px]">
        Other Scripts
      </p>

      <div className="min-h-[282px] ">
        {scripts.map((item, index) => {
          return (
            <div
              key={`other${index}`}
              className=" min-h-[94px] rounded-[8px] border-b-[1px] border-[#EBEBEB] p-[16px] flex justify-between flex-col sm:flex-row"
            >
              <div>
                <Link to={`${item.link}`} target="_blank">
                  {" "}
                  <p className="text-[20px] text-[#666666] font-[600] leading-[28px] md:leading-[28px] cursor-pointer">
                    {item.name}
                  </p>
                </Link>
                <p className="text-[16px] text-[#666666] font-[400] leading-[28px] md:leading-[28px]">
                  {item.description}
                </p>
              </div>

              <Link
                onClick={() => {
                  pdfDownload(item.link, item.name);
                }}
                className=" mt-[16px] md:mt-[0px] h-[40px] w-[99px] bg-[#E8EDF0] rounded-[8px] px-[16px] py-[6px] flex justify-center items-center hover:bg-[#D5DDE1]"
              >
                <p className=" text-[14px] leading-[28px] font-[400] text-[#272829]">
                  {"Download "}
                </p>
              </Link>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default OtherScripts;
