import React, { useState } from "react";

const Tabbar = ({ onStateChange }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const changeSelection = (index) => {
    setSelectedTab(index);
    onStateChange(index);
  };
  const tabs = [
    {
      name: "All Scripts",
    },
    {
      name: "Expired Scripts",
    },
    {
      name: "FSBO Scripts",
    },
    {
      name: "Other Scripts",
    },
  ];
  return (
    <>
      <div className="flex h-[52px] tab overflow-x-scroll max-w-full overflow-y-hidden">
        <div className=" flex min-w-[500px] md:min-w-[550px] md:gap-[16px]">
          {tabs.map((item, index) => {
            return (
              <p
                key={`tab${index}`}
                className={
                  selectedTab !== index ? "tabButton" : "tabButtonSelected "
                }
                onClick={() => changeSelection(index)}
              >
                {item.name}
              </p>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Tabbar;
