import React from "react";
import { OutlineButtons } from "./buttons";
import Image from "../../assets/img/undraw_void_-3-ggu 1.svg";
import { useNavigate } from "react-router-dom";

const NoDataPage = ({ product = "Expireds" }) => {
  const navigate = useNavigate();
  return (
    <section>
      <div className="flex justify-center items-center flex-col gap-[32px] py-3">
        <img className="h-[180px]" src={Image} alt="Description" />
        <div className=" text-center flex flex-col gap-2 items-center">
          <p className=" text-[#999999] text-[24px]  font-semibold leading-6">
            You do not currently have FSBO areas selected.
          </p>
          <p className="text-[16px] leading-6 text-[#999999]">
            {" Click the link below to add FSBO areas to your account. "}
          </p>
          <div className=" w-[150px]">
            <OutlineButtons
              label={"Go to FSBO Areas"}
              onClick={() => {
                navigate("/accountsettings?tab=fsbo");
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default NoDataPage;
