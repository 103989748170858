import React, { useState } from "react";
import Tabbar from "../components/utils/scripts/TabBar";
import ExpiredScripts from "../components/utils/scripts/ExpiredScripts";
import OtherScripts from "../components/utils/scripts/OtherScripts";
import FSBOScripts from "../components/utils/scripts/FSBOScripts";
import PageLayout from "../components/common/PageLayout";

const Scripts = () => {
  const [tabState, setTabState] = useState(0);
  const handleTabStateChange = (State) => {
    setTabState(State);
  };
  return (
    <>
      <PageLayout>
        <div>
          <div className=" mb-[16px]">
            <h1 className=" leading-[57px] font-[600] text-[48px] text-[#666666]  mb-[14px]">
              Scripts
            </h1>
            <p className="leading-[24px] font-[400] text-[16px] text-[#999999]">
              Download our scripts, dialogs, and objection handlers to learn
              approaches to your potential customers.{" "}
            </p>
          </div>

          <Tabbar onStateChange={handleTabStateChange} />
          {tabState === 1 ? (
            <ExpiredScripts />
          ) : tabState === 2 ? (
            <FSBOScripts />
          ) : tabState === 3 ? (
            <OtherScripts />
          ) : (
            <>
              <ExpiredScripts />
              <FSBOScripts />
              <OtherScripts />
            </>
          )}
        </div>
      </PageLayout>
    </>
  );
};

export default Scripts;
