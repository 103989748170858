import React from "react";

const ErrorMessage = ({ message }) => {
  return (
    <div className=" min-h-[44px] w-full bg-[#FAC5C3] mb-8 rounded-md p-3">
      <p className=" text-[14px] font-[400] leading-[20px] text-[#A81E19]">
        {message}
      </p>
    </div>
  );
};

export default ErrorMessage;
