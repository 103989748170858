import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { IconContext } from "react-icons";
import * as FaIcons from "react-icons/fa";
import { useAuth } from "../auth/assist/authContext";

import logoutIcon from "../../../assets/right-from-bracket-solid.svg";
import logo from "../../../assets/Landvoice Logo 2.png";
import Xmark from "../../../assets/xmark.svg";
import Bars from "../../../assets/bars.svg";

const MobileView = () => {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  const { logout, user } = useAuth();

  return (
    <>
      <div className="landvoice-logo logo-M z-[999999] bg-[#212a33] print:bg-transparent print:hidden print:justify-end  ">
        <img
          height={"24px"}
          width={"24px"}
          src={!sidebar ? Bars : Xmark}
          onClick={showSidebar}
          alt=""
        />
        <img height={"24px"} width={"24px"} src={logo} alt="" />{" "}
        <p className={" print:text-[#000000]"}>
          LAND<span>VOICE</span>
        </p>
      </div>
      <IconContext.Provider value={{ color: "undefined" }}>
        <div className=" relative">
          {sidebar && (
            <div
              onClick={showSidebar}
              className="h-screen w-screen fixed  bg-slate-500 opacity-10 z-0  "
            />
          )}
          <nav
            className={
              sidebar ? "nav-menu-M open  relative z-[999999999]" : "nav-menu-M"
            }
          >
            <ul className="nav-menu-items overflow-y-scroll z-10">
              <p className={"nav-text-M title-M"}>Your Lists</p>

              <NavLink to="/expired" className={"nav-text"}>
                <div className="nav-button">
                  <FaIcons.FaUserClock />
                  <span className={""}>Expired</span>
                </div>
              </NavLink>

              {user.expired_upload && (
                <NavLink to="/expireduploads" className={"nav-text"}>
                  <div className="nav-button">
                    <FaIcons.FaClock />
                    <span className={""}>Expired Uploads</span>
                  </div>
                </NavLink>
              )}

              <NavLink to="/OldExpired" className={"nav-text"}>
                <div className="nav-button">
                  <FaIcons.FaUserAltSlash />
                  <span className={""}>Old Expired</span>
                </div>
              </NavLink>

              <NavLink to="/fsbo" className={"nav-text"}>
                <div className="nav-button">
                  {<FaIcons.FaSign />}
                  <span className={""}>{"FSBO"}</span>
                </div>
              </NavLink>

              <NavLink to="/preforeclosure" className={"nav-text"}>
                <div className="nav-button">
                  {<FaIcons.FaHome />}
                  <span className={""}>{"Pre-Foreclosure"}</span>
                </div>
              </NavLink>

              {/* {SidebarData.map((item, index) => {return ();})} */}

              <p className={"nav-text title-M"}>Other Resources</p>

              <NavLink
                to="https://search.landvoice.com/"
                className={"nav-text"}
              >
                <div className="nav-button">
                  {<FaIcons.FaMap />}
                  <span className={""}>{"Neighborhood Search"}</span>
                </div>
              </NavLink>

              <NavLink
                to="https://callcapture.landvoice.com/account/dashboard"
                className={"nav-text"}
              >
                <div className="nav-button">
                  {<FaIcons.FaPhone />}
                  <span className={""}>{"Call Capture"}</span>
                </div>
              </NavLink>

              <NavLink to="/scripts" className={"nav-text"}>
                <div className="nav-button">
                  {<FaIcons.FaFile />}
                  <span className={""}>{"Scripts"}</span>
                </div>
              </NavLink>

              <NavLink
                to="https://www.landvoice.com/help"
                className={"nav-text"}
              >
                <div className="nav-button">
                  {<FaIcons.FaQuestion />}
                  <span className={""}>{"Help"}</span>
                </div>
              </NavLink>
            </ul>

            <ul className=" flex gap-4 mb-4">
              <NavLink to="/accountsettings" className={"nav-text"}>
                <div className="nav-button">
                  {<FaIcons.FaCog />}
                  <span className={""}>{"Account settings"}</span>
                </div>
              </NavLink>

              <li className={"nav-text light-hover"}>
                <Link to="#" onClick={logout}>
                  <img src={logoutIcon} alt=""></img>
                  <span className={""}>{"Log Out"}</span>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </IconContext.Provider>
    </>
  );
};

export default MobileView;
