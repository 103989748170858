import { useEffect, useMemo, useState } from "react";
import { uniqueId } from "lodash";

export const useClickOutside = (ref, callback, includeFocus = false) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    if (includeFocus) document.addEventListener("keyup", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keyup", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, callback]);
};

// See https://usehooks.com/useDebounce for hook refrence
export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

/**
 * Component API consumers shouldn't have to worry about internal `id` usage,
 * so this hook generates one if not given.
 *
 * @param {string} prefix Prefix to add to the id (gets appended with "-").
 * @param {string} givenId Given id.
 * @returns {string} Given id or generated id.
 */
export const useGivenOrGeneratedId = (prefix, givenId) =>
  useMemo(() => givenId || uniqueId(`${prefix}-`), [prefix, givenId]);

export const useInput = (initialValue, useTargetOnChange = true) => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    reset: () => setValue(""),
    bind: {
      value,
      onChange: (event) => {
        let value = event;
        if (useTargetOnChange) {
          value = event.target.value;
        }
        setValue(value);
      },
    },
  };
};

// Generic useState//useEffect combo we see and use everyday.
export const useDependencyWithCleanUp = (dependency, initState = "") => {
  const [something, setSomething] = useState(initState);

  useEffect(() => {
    setSomething(dependency);

    return () => {
      setSomething(initState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependency]);

  return [something, setSomething];
};
