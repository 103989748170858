import React, { useState } from "react";
import Modal from "../../common/Modal";
import useAPI from "../../common/UseApi";

const ChangePassword = ({ handleOpen, open }) => {
  // eslint-disable-next-line
  const { post } = useAPI();

  const [mlsPassword, setmlsPassword] = useState("");
  const [mlsPassword2, setmlsPassword2] = useState("");
  const [mlsOldPassword, setmlsoldPassword] = useState("");

  const handleSetPassword = (event) => {
    setmlsPassword(event.target.value);
  };

  const handleSetConfirmPassword = (event) => {
    setmlsPassword2(event.target.value);
  };

  const handleSetOldPassword = (event) => {
    setmlsoldPassword(event.target.value);
  };
  const changePasswordFunc = async () => {
    await post(
      "/api/auth/password/change/",
      {
        old_password: mlsOldPassword,
        new_password1: mlsPassword,
        new_password2: mlsPassword2,
      },
      () => {
        handleOpen();
      }
    ); // For POST request
  };
  return (
    <>
      <Modal handleOpen={handleOpen} open={open} title={"Change Your Password"}>
        <div>
          <div className="">
            <p className="font-[600] text-[14px] text-[#999999]">
              Old Password <span className=" text-red-700">*</span>
            </p>
            <input
              required
              value={mlsOldPassword}
              onChange={(event) => {
                handleSetOldPassword(event);
              }}
              autoComplete="false"
              title=""
              type="password"
              className="loginInput"
            />
          </div>
          <div className="">
            <p className="font-[600] text-[14px] text-[#999999]">
              New Password <span className=" text-red-700">*</span>
            </p>
            <input
              value={mlsPassword}
              onChange={(event) => {
                handleSetPassword(event);
              }}
              required
              title=""
              type="password"
              placeholder={""}
              className="loginInput"
            />
          </div>

          <div className="">
            <p className="font-[600] text-[14px] text-[#999999]">
              Confirm New Password <span className=" text-red-700">*</span>
            </p>
            <input
              value={mlsPassword2}
              onChange={(event) => {
                handleSetConfirmPassword(event);
              }}
              required
              title=""
              type="password"
              placeholder={""}
              className="loginInput"
            />
          </div>
        </div>

        <div className="flex justify-between">
          <button
            className=" cursor-pointer flex justify-center items-center py-[6px] px-3 w-[124px] h-[40px] bg-[#E8EDF0] rounded-lg mb-4  "
            onClick={() => handleOpen(0, false)}
          >
            <p className="text-[14px] font-[500] leading-[28px] text-[#666666]">
              Cancel
            </p>
          </button>
          <button
            className=" cursor-pointer flex justify-center items-center py-[6px] px-3 min-w-[124px] h-[40px] bg-[#F27935] hover:bg-[#E2631B] rounded-lg mb-4  "
            onClick={changePasswordFunc}
          >
            <p className="text-[14px] font-[500] leading-[28px] text-[#FFFFFF]">
              Change Password
            </p>
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ChangePassword;
