import React, { useEffect, useState } from "react";
import { Bug } from "@landvoice/icons";

const Banner = () => {
  const [showBanner, setShowBanner] = useState(true);
  const bannerId = "1876";

  const handleShowBanner = () => {
    setShowBanner((cur) => !cur);
    localStorage.setItem(
      "banner",
      JSON.stringify({ id: bannerId, show: !true })
    );
  };

  useEffect(() => {
    // Check if the value exists in local storage
    const storedValue = localStorage.getItem("banner");
    // showBanner(storedValue?.show);

    if (!storedValue) {
      // If the value doesn't exist, set it // This is the value you want to set

      localStorage.setItem(
        "banner",
        JSON.stringify({ id: bannerId, show: true })
      );
      setShowBanner(true);
    } else {
      if (bannerId !== JSON.parse(storedValue).id) {
        localStorage.setItem(
          "banner",
          JSON.stringify({ id: bannerId, show: true })
        );
        setShowBanner(true);
      } else {
        setShowBanner(JSON.parse(storedValue).show);
      }
    }
  }, []);
  return (
    <>
      {showBanner && (
        <div className="w-full min-h-[139px] rounded-2xl p-6 bg-[#EBEBEB]  justify-between print:hidden hidden">
          <div className=" flex gap-4 h-full w-full">
            <div className="flex justify-end items-center h-full">
              <Bug />
            </div>
            <div className="text-[#666666]">
              <p className=" font-semibold leading-6 text-[14px]">
                New Year, New Landvoice
              </p>
              <p className=" font-medium leading-5 text-[14px] max-w-[766px]">
                We recently launched our new UI update and are currently in the
                process of refining it to create a better experience for you.
                During this time, you may experience some bugs while we
                fine-tune the product. If you find any new bugs or behavior that
                isn’t working as expected, please let us know at
                support@landvoice.com
              </p>
            </div>
          </div>
          <div className="flex justify-end items-center h-full">
            <p
              className=" font-bold text-[14px] leading-7 text-[#F27935] cursor-pointer hover:underline"
              onClick={handleShowBanner}
            >
              DISMISS
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Banner;
