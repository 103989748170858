import React, { useState } from "react";
import PageLayout from "../components/common/PageLayout";
import logo from "../assets/img/landvoiceLogo.png";
import spinner from "../assets/img/spinner-third.svg";
import ErrorMessage from "../components/common/ErrorMessage";
import useAPI from "../components/common/UseApi";
import ErrorBoundary from "../components/utils/error/ErrorBoundary";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../components/utils/auth/assist/authContext";

const Login = () => {
  const { error, loading, post, clearErrorMessage } = useAPI();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();

  const navigate = useNavigate();

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const loginFunc = () => {
    post("/api/auth/login", { username, password }, () => {
      login({});
      navigate("/expired");
    }); // For POST request
  };
  return (
    <ErrorBoundary>
      <PageLayout hideSideBar={true} pageBG={"bg-hero-pattern"}>
        <div className="flex justify-center items-center h-[60px] w-screen top-0 left-0 fixed bg-[#212A33]">
          <img src={logo} width={165} height={28} alt="landvoice logo" />
        </div>

        <div className="flex justify-center items-center w-full min-h-[calc(100vh)] md:h-[calc(100vh)] pt-[60px] overflow-y-scroll">
          {loading ? (
            <div className="h-[348px] w-[420px]  bg-[#FFFFFF] m-4 md:m-8 md:py-16 py-8  px-4 md:px-8 rounded-lg  border-[1px] border-[#E0E0E0]">
              <div className="w-full flex flex-col items-center gap-2 h-[88px] ">
                <p className=" leading-8 text-[28px] font-[600] text-center text-[#666666]">
                  Logging you in...
                </p>
                <p className="font-[400] text-[16px] leading-[20px] text-[#999999] mb-8">
                  Just a moment! This shouldn’t take long.
                </p>

                <div className="h-[125px] w-full flex justify-center items-center">
                  <img className="animate-spin" src={spinner} alt="spinner" />
                </div>
              </div>
            </div>
          ) : (
            <div className=" w-[420px] min-h-[50px] bg-[#FFFFFF] m-4 md:m-8 md:py-16 py-8  px-4 md:px-8 rounded-lg  border-[1px] border-[#E0E0E0]">
              <div className="w-full flex flex-col items-center gap-2 h-[88px] ">
                <p className=" leading-8 text-[28px] font-[600] text-center text-[#666666]">
                  Welcome back!
                </p>
                <p className="font-[400] text-[16px] leading-[20px] text-[#999999] mb-8">
                  Sign in by entering the information below{" "}
                </p>
              </div>
              {error ? (
                <ErrorMessage
                  message={"Invalid username or password. Please try again."}
                />
              ) : null}
              {error ? (
                <p className="text-[14px] font-[600] leading-[20px] pb-2 text-[#999999]">
                  {" "}
                  Username
                </p>
              ) : null}
              <input
                required
                value={username}
                onChange={(event) => {
                  handleUsernameChange(event);
                  clearErrorMessage(event);
                }}
                title="Username"
                type="text"
                placeholder={"Username"}
                className="loginInput"
              />
              {error ? (
                <p className="text-[14px] font-[600] leading-[20px] pb-2 text-[#999999]">
                  {" "}
                  Password
                </p>
              ) : null}
              <input
                required
                value={password}
                onChange={(event) => {
                  handlePasswordChange(event);
                  clearErrorMessage(event);
                }}
                type="Password"
                placeholder={"Password"}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    loginFunc();
                  }
                }}
                className="loginInput"
              />
              <button
                className=" w-full h-[40px] bg-[#F27935] hover:bg-[#E2631B] rounded-lg mb-4"
                onClick={loginFunc}
              >
                <p className="text-[14px] font-[600] leading-[28px] text-[#FFFFFF]">
                  Login
                </p>
              </button>
              <p className="text-center text-[#999999] text-[16px] font-[400] leading-[20px] mb-8">
                {" "}
                Forgot your password?
                <span className="text-[#F2714D] hover:text-[#E2631B] cursor-pointer">
                  {" "}
                  <NavLink to={"/forgotpassword"}>Reset it here</NavLink>
                </span>
              </p>

              {/* <div className="flex justify-between items-center mb-8">
                <div className="loginDivider" />
                <p className=" mx-6 text-[14px] text-[#999999] font-[400] leading-[20px] mb-0">
                  OR
                </p>
                <div className="loginDivider" />
              </div>

              <button className=" w-full h-[40px] bg-[#FFF] rounded-lg mb-4 border-[1px] hover:border-2 hover:border-[#666666] border-[#666666]">
                <p className="text-[14px] font-[600] leading-[28px] text-[#666666]">
                  Create An Account
                </p>
              </button> */}
            </div>
          )}
        </div>
      </PageLayout>
    </ErrorBoundary>
  );
};

export default Login;
