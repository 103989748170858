import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { UIMODE } from "./utils/constants";

import styleSchema from "./DateSelector.styles";
import { FaChevronRight, FaChevronLeft, FaCaretDown } from "react-icons/fa";

const renderHeader = (uiMode, navigationDate) => {
  switch (uiMode) {
    case UIMODE.DAY:
      return navigationDate.format("MMM YYYY");
    case UIMODE.MONTH:
      return navigationDate.format("YYYY");
    default:
      return "";
  }
};

const arialLabelMonthYearNext = (navigationDate, uiMode) => {
  switch (uiMode) {
    case UIMODE.DAY:
      return `Go to next month: ${navigationDate
        .clone()
        .add(1, "month")
        .format("MMM YYYY")} to select a day`;
    case UIMODE.MONTH:
      return `Go to next year: ${navigationDate
        .clone()
        .add(1, "year")
        .format("YYYY")} to select a month`;
    default:
      return "";
  }
};

const arialLabelMonthYearPrevious = (navigationDate, uiMode) => {
  switch (uiMode) {
    case UIMODE.DAY:
      return `Go to previous month: ${navigationDate
        .clone()
        .subtract(1, "month")
        .format("MMM YYYY")} to select a day`;
    case UIMODE.MONTH:
      return `Go to previous year: ${navigationDate
        .clone()
        .subtract(1, "year")
        .format("YYYY")} to select a month`;
    default:
      return "";
  }
};

const arialLabelMonthYearUI = (uiMode) => {
  switch (uiMode) {
    case UIMODE.DAY:
      return "Change user interface from day select to month select";
    case UIMODE.MONTH:
      return "Change user interface from month select to day select";
    default:
      return "";
  }
};

const testCurrentPeriod = (uiMode, navigationDate) => {
  switch (uiMode) {
    case UIMODE.DAY:
      return navigationDate.format("YYYY-MM");
    case UIMODE.MONTH:
      return navigationDate.format("YYYY");
    default:
      return "";
  }
};

const PeriodNavigation = ({
  navigationDate,
  handleNavigationClick,
  uiMode,
  handleChangeUI,
  minDate,
  maxDate,
}) => {
  const isPrevDisabled = minDate
    ? navigationDate.isSameOrBefore(
        moment(minDate),
        uiMode === UIMODE.MONTH ? "year" : "month"
      )
    : false;
  const isNextDisabled = maxDate
    ? navigationDate.isSameOrAfter(
        moment(maxDate),
        uiMode === UIMODE.MONTH ? "year" : "month"
      )
    : false;

  const handleClickPrevious = () => {
    switch (uiMode) {
      case UIMODE.DAY:
        handleNavigationClick(navigationDate.subtract(1, "month"));
        break;
      case UIMODE.MONTH:
        handleNavigationClick(navigationDate.subtract(1, "year"));
        break;
      default:
        handleNavigationClick(navigationDate.subtract(1, "month"));
    }
  };

  const handleClickNext = () => {
    switch (uiMode) {
      case UIMODE.DAY:
        handleNavigationClick(navigationDate.add(1, "month"));
        break;
      case UIMODE.MONTH:
        handleNavigationClick(navigationDate.add(1, "year"));
        break;
      default:
        handleNavigationClick(navigationDate.add(1, "month"));
    }
  };

  return (
    <div className="flex flex-row justify-between pb-[8px]">
      <button
        type="button"
        className={styleSchema.navigation}
        onClick={() => handleClickPrevious()}
        aria-label={arialLabelMonthYearPrevious(navigationDate, uiMode)}
        data-test-navigation-previous
        disabled={isPrevDisabled}
      >
        <FaChevronLeft size="12px" />
      </button>
      <button
        type="button"
        className={styleSchema.navigationMode}
        onClick={() => handleChangeUI()}
        aria-label={arialLabelMonthYearUI(uiMode)}
        data-test-navigation-current-period={testCurrentPeriod(
          uiMode,
          navigationDate
        )}
        data-test-ui-mode={uiMode}
      >
        {renderHeader(uiMode, navigationDate)}{" "}
        <FaCaretDown size="12px" className="ml-[4px]" />
      </button>
      <button
        type="button"
        className={styleSchema.navigation}
        onClick={() => handleClickNext()}
        aria-label={arialLabelMonthYearNext(navigationDate, uiMode)}
        data-test-navigation-next
        disabled={isNextDisabled}
      >
        <FaChevronRight size="12px" />
      </button>
    </div>
  );
};

PeriodNavigation.propTypes = {
  navigationDate: PropTypes.instanceOf(moment),
  handleNavigationClick: PropTypes.func,
  handleChangeUI: PropTypes.func,
  uiMode: PropTypes.oneOf(Object.values(UIMODE)),
  minDate: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]),
  maxDate: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.string,
  ]),
};

PeriodNavigation.defaultProps = {
  navigationDate: moment(),
  handleNavigationClick: () => {},
  handleChangeUI: () => {},
  uiMode: UIMODE.DAY,
  minDate: null,
  maxDate: null,
};

export default PeriodNavigation;
