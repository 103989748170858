import React, { useEffect } from "react";
import { Fragment, useState, useRef } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SearchNselect = ({
  list,
  mls = false,
  onValueChange,
  zIndex = "z-[0]",
}) => {
  const selectionType = list;

  const [inputValue, setInputValue] = useState("");
  const [selected, setSelected] = useState(selectionType[0]);

  const inputRef = useRef(null);

  const [showInput, setShowInput] = useState(false);

  const filteredSelection = selectionType.filter((word) =>
    word?.name?.toLowerCase().includes(inputValue?.toLocaleLowerCase())
  );

  const handleMouseOver = () => {
    inputRef.current.focus();
  };

  // Handle input blur event
  //const handleBlur = () => setShowInput(false);

  useEffect(() => {
    if (onValueChange) onValueChange(selected);
    // eslint-disable-next-line
  }, [selected]);

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className={`relative ${zIndex}`}>
            {!showInput ? (
              <Listbox.Button
                onClick={() => {
                  setShowInput(true);
                }}
                className="w-full  flex justify-between cursor-default rounded-sm h-9 bg-[#F5F7F7] items-center border-[1px] border-[#E0E0E0]   text-[14px] font-[400] leading-[20px] text-[#666666]  "
              >
                <span className="flex items-center">
                  <span className="ml-3 block truncate">
                    {mls
                      ? `${selected?.state}: ${selected?.name}`
                      : selected?.name}
                  </span>
                </span>
                <span className="pointer-events-none  inset-y-0 right-0 ml-3 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
            ) : null}

            <div
              className={
                !showInput
                  ? " hidden"
                  : "w-full   flex justify-between cursor-default rounded-sm h-9 bg-[#F5F7F7] items-center border-[1px] border-[#E0E0E0]   text-[14px] font-[400] leading-[20px] text-[#666666]  "
              }
            >
              <input
                //onBlur={handleBlur} // Set isFocused to false when input loses focus
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOver}
                onMouseMove={handleMouseOver}
                ref={inputRef}
                id="searchSelect"
                placeholder={selected?.name}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                className="flex items-center bg-transparent w-full outline-none ml-3 selection:bg-transparent"
              ></input>
              <span className="pointer-events-none  inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </div>

            <Transition
              show={showInput}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={
                  "absolute mt-1 max-h-56 w-full  overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" +
                  zIndex
                }
              >
                {filteredSelection.map((person) => (
                  <Listbox.Option
                    onClick={() => setShowInput(false)}
                    key={person.id}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-[#F27935] text-white" : "text-[#666666]",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "ml-3 block truncate"
                            )}
                          >
                            {mls
                              ? `${person?.state}: ${person?.name}`
                              : person?.name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-[#F27935]",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default SearchNselect;
