const pdfDownload = (
  url,
  fileName,
  extention = "pdf",
  onComplete = () => {}
) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const blobUrl = window.URL.createObjectURL(new Blob([blob]));
      const aTag = document.createElement("a");
      aTag.setAttribute("download", `${fileName}.${extention}`);
      aTag.href = blobUrl;

      // Add an event listener for when the download is initiated
      aTag.addEventListener("click", () => {
        onComplete(); // Call the onComplete callback when download starts
      });

      document.body.appendChild(aTag);
      aTag.click();
      aTag.remove();
    })
    .catch(() => {});
};

export default pdfDownload;
