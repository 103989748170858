import React, { useEffect, useState } from "react";
import Selection from "./select";
import useAPI from "../../common/UseApi";
import ChangePassword from "./changePassword";

const MyAccount = ({ accountData, userPartner, partnerList, ptrIndex }) => {
  const { post } = useAPI();
  const [isActive, setIsActive] = useState(false);
  //const [oldexpireds, setOldexpireds] = useState(false);
  //const [preforeclosures, setPreforeclosures] = useState(false);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [disable_zip_filter, setDisableZipFilter] = useState(false);
  //const [fsbos, setFsbos] = useState(false);
  const [mobile_phone, setMobilePhone] = useState("");
  const [enable_duplicate_filter, setEnableDuplicateFilter] = useState(false);
  const [disable_daily_email, setDisableDailyEmail] = useState(false);
  //const [expireds, setExpireds] = useState(false);
  const [partnerCred, setPartnerCred] = useState("");
  const [partnerName, setPartnerName] = useState("");

  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const handleChangePasswordOpen = () => setChangePasswordOpen((cur) => !cur);

  useEffect(() => {
    updateUSerDataStateFromApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountData]);

  const updateUSerDataStateFromApi = () => {
    //setOldexpireds(accountData?.oldexpireds);
    //setPreforeclosures(accountData?.preforeclosures);
    setFirstName(accountData?.first_name);
    setLastName(accountData?.last_name);
    setDisableZipFilter(accountData?.disable_zip_filter);
    //setFsbos(accountData?.fsbos);
    setMobilePhone(accountData?.mobile_phone);
    setEnableDuplicateFilter(accountData?.enable_duplicate_filter);
    setDisableDailyEmail(accountData?.disable_daily_email);
    //setExpireds(accountData?.expireds);
    setPartnerName(userPartner?.partner);
    setPartnerCred(userPartner?.credential);
  };

  const partnerNameEnum = {
    Mojo: "Mojo Posting Pin",
    SalesDialers: "Sales Dialers Username",
    "Wise Agent": "Wise Agent API Key",
    FUEL: "Engage API Key",
    Landvoice: "Engage API Key",
    "Top Producer": "Top Producer Username",
    iList: "iList Access Token",
  };

  const changeDailyEmail = () => {
    setDisableDailyEmail((cur) => !cur);
  };

  const changeDisabledZipFilter = () => {
    setDisableZipFilter((cur) => !cur);
  };

  const changeEnableDupilcateFilter = () => {
    setEnableDuplicateFilter((cur) => !cur);
  };

  const updateAccount = async () => {
    await post(
      "/api/core/account",
      {
        first_name,
        last_name,
        mobile_phone,
        disable_zip_filter,
        enable_duplicate_filter,
        disable_daily_email,
      },
      () => {}
    );
    if (
      partnerList.find((parter) => parter?.name === partnerName)?.internal_name
    ) {
      post(
        "/api/core/account/partner",
        {
          partner: partnerList.find((parter) => parter?.name === partnerName)
            ?.internal_name,
          credential: partnerCred,
        },
        () => {}
      );
    }
  };

  return (
    <section className=" min-h-[406px] bg-white rounded-[8px] p-[16px] md:p-[32px] mb-[16px] border-[#EBEBEB] border-[1px]">
      <ChangePassword
        handleOpen={handleChangePasswordOpen}
        open={changePasswordOpen}
      />
      <p className="whitespace-nowrap text-[#666666] text-[28px] leading-[28px] font-[500] mb-[32px]">
        My Account
      </p>

      <section className="flex w-full gap-8 md:flex-row flex-col">
        <div className="flex-[2]">
          <div className="flex w-full gap-4 h-7 items-center mb-6 ">
            <div className=" h-7 ">
              <p className=" whitespace-nowrap text-[#666666] font-[700] text-[16px] ">
                Account Info
              </p>
            </div>

            <div className="h-[0px]  min-w-[200] w-full  border-t border-[#E0E0E0]"></div>
          </div>
          <div className="flex gap-4 w-full ">
            <div className="flex-[1] ">
              <p className="text-[14px] font-[600] leading-[20px] pb-2 text-[#999999]">
                {" "}
                First Name
              </p>
              <input
                required
                value={first_name}
                onChange={(event) => {
                  setFirstName(event.target.value);
                }}
                title="First Name"
                type="text"
                placeholder={"First Name"}
                className="loginInput "
              />
            </div>

            <div className="flex-[1]">
              <p className="text-[14px] font-[600] leading-[20px] pb-2 text-[#999999]">
                {" "}
                Last Name
              </p>
              <input
                required
                value={last_name}
                onChange={(event) => {
                  setLastName(event.target.value);
                }}
                title=" Last Name"
                type="text"
                placeholder={" Last Name"}
                className="loginInput"
              />
            </div>
          </div>
          <div className="">
            <p className="text-[14px] font-[600] leading-[20px] pb-2 text-[#999999]">
              {" "}
              Phone Number
            </p>
            <input
              required
              value={mobile_phone}
              onChange={(event) => {
                setMobilePhone(event.target.value);
              }}
              title="Phone Number"
              type="tel"
              placeholder={"EX. 111-111-1111"}
              className="loginInput"
            />
          </div>
          <button
            className=" w-[160px] h-[40px] bg-[#FFF] rounded-lg mb-4 border-[1px] hover:border-2 hover:border-[#666666] "
            onClick={handleChangePasswordOpen}
          >
            <p className="text-[14px] font-[600] leading-[28px] text-[#666666]">
              Change Password
            </p>
          </button>
        </div>

        <div className="flex-[2]">
          <div className="flex w-full gap-4 h-7 items-center mb-6 ">
            <div className="min-w-[124px] h-7 ">
              <p className=" text-[#666666] font-[700] text-[16px] leading-6 whitespace-nowrap">
                Data Integrations
              </p>
            </div>

            <div className="h-[0px]  min-w-[200] w-full  border-t border-[#E0E0E0]"></div>
          </div>
          <div className="">
            <p className="text-[14px] font-[600] leading-[20px] pb-2 text-[#999999]">
              {" "}
              Data Partner
            </p>
            <div className="h-8">
              <Selection
                setIsActive={setIsActive}
                list={partnerList}
                initialSelection={ptrIndex}
                onValueChange={setPartnerName}
              />
            </div>
            <p className="font-[400] text-[12px] leading-4 text-[#999999] whitespace-pre-wrap mt-1">
              Use “None” unless you need data sent to a partner company
            </p>

            <div className={isActive ? "mt-6 md:mt-[18px]" : "hidden"}>
              <p className="text-[14px] font-[600] leading-[20px] pb-2 text-[#999999]">
                {" "}
                {partnerNameEnum[partnerName]}
              </p>
              <input
                required
                value={partnerCred}
                onChange={(event) => {
                  setPartnerCred(event.target.value);
                }}
                title=""
                type="text"
                placeholder={""}
                className="loginInput"
              />
            </div>
          </div>
        </div>
      </section>

      <div className=" mt-12">
        <div className="flex w-full gap-4 h-7 items-center mb-6 ">
          <div className=" h-7 ">
            <p className=" whitespace-nowrap text-[#666666] font-[700] text-[16px] ">
              Other Settings
            </p>
          </div>

          <div className="h-[0px]  min-w-[200] w-full  border-t border-[#E0E0E0]"></div>
        </div>

        <div className=" flex flex-wrap gap-3">
          <div className=" items-center min-h-11 md:h-11 min-w-[187px] flex gap-[6px] bg-[#F5F7F7] rounded-lg px-4 py-3">
            <input
              type="checkbox"
              checked={disable_daily_email}
              onChange={changeDailyEmail}
            />
            <p className=" text-[14px] font-[400] font-sans text-[#666666] leading-5">
              Turn off daily emails
            </p>
          </div>
          <div className=" items-center min-h-11 md:h-11 min-w-[187px] flex gap-[6px] bg-[#F5F7F7] rounded-lg px-4 py-3">
            <input
              checked={disable_zip_filter}
              onChange={changeDisabledZipFilter}
              type="checkbox"
              name=""
              id=""
            />
            <p className=" text-[14px] font-[400] font-sans text-[#666666] leading-5">
              Ignore Zip code filter
            </p>
          </div>
          <div className=" min-h-[44px] md:h-11 min-w-[187px] flex gap-[6px] bg-[#F5F7F7] rounded-lg px-4 py-3 items-center">
            <input
              checked={enable_duplicate_filter}
              onChange={changeEnableDupilcateFilter}
              type="checkbox"
              name=""
              id=""
            />
            <p className=" text-[14px] font-[400] font-sans text-[#666666] leading-5">
              Remove duplicates from Expired Uploads
            </p>
          </div>
        </div>
      </div>

      <div className="flex gap-3 justify-end mt-6">
        <button
          className=" py-[6px] px-3 w-[121px] h-[40px] bg-[#E8EDF0] rounded-lg mb-4  "
          onClick={updateUSerDataStateFromApi}
        >
          <p className="text-[14px] font-[500] leading-[28px] text-[#666666]">
            Revert Changes
          </p>
        </button>
        <button
          className=" py-[6px] px-3 w-[121px] h-[40px] bg-[#F27935] hover:bg-[#E2631B] rounded-lg mb-4 "
          onClick={updateAccount}
        >
          <p className="text-[14px] font-[500] leading-[28px] text-[#FFFFFF]">
            Save Changes
          </p>
        </button>
      </div>
    </section>
  );
};

export default MyAccount;
