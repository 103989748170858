import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./App.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";

import Settings from "./routes/Settings";
import Expired from "./routes/ExpiredLeads";
import Fsbo from "./routes/FsboLeads";
import Scripts from "./routes/Scripts";
import { SidebarProvider } from "./components/utils/sidebar/SideBarContext";
import Login from "./routes/Login";
import ForgotPassword from "./routes/ForgotPassword";
import ErrorBoundary from "./components/utils/error/ErrorBoundary";
import Preforeclosures from "./routes/PreforeclosuresLeads";
import OldExpired from "./routes/OldExpired";
import { AuthProvider } from "./components/utils/auth/assist/authContext";
import ExpiredUploadPage from "./routes/ExpiredUploadPage";
import ResetPassword from "./routes/ResetPassword";
import FouroFour from "./routes/FouroFour";
import Distressed from "./routes/DistressedData";

const AppLayout = () => <Outlet className={"hideScrollBar"} />;

const router = createBrowserRouter([
  {
    element: <AppLayout className={"hideScrollBar"} />,
    children: [
      {
        path: "/signin/",
        element: <Login />,
      },
      {
        path: "/forgotpassword",
        element: <ForgotPassword />,
      },
      {
        path: "/",
        element: <Expired />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "/expired",
        element: <Expired />,
      },
      {
        path: "/expireduploads",
        element: <ExpiredUploadPage />,
      },
      {
        path: "/oldexpired",
        element: <OldExpired />,
      },
      {
        path: "/fsbo",
        element: <Fsbo />,
      },
      {
        path: "/preforeclosure",
        element: <Preforeclosures />,
      },
      {
        path: "/Distressed",
        element: <Distressed />,
      },
      {
        path: "/neighborhoodsearch",
        element: <Preforeclosures />,
      },
      {
        path: "/scripts",
        element: <Scripts />,
      },
      {
        path: "/help",
        element: <Fsbo />,
      },
      {
        path: "/accountsettings",
        element: <Settings />,
      },
      {
        path: "/forgotpassword/:token",
        element: <ResetPassword />,
      },
      {
        path: "*",
        element: <FouroFour />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ErrorBoundary>
    {/* <React.StrictMode> */}
    <AuthProvider>
      <SidebarProvider>
        <RouterProvider router={router} />
      </SidebarProvider>
    </AuthProvider>
    {/* </React.StrictMode> */}
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
