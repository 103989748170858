import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import { HDivider } from "./amenities";
import Checkbox from "./customCheckBox";
import Selection from "../utils/account/select";
import { PrimaryButtons, SecondaryButtons } from "./buttons";
import {
  bathsList,
  bedsList,
  searchDate,
  squareFeet,
  distressedLevel,
  PreforeclosureStatusType,
} from "../utils/selectionData";
import DatePicker from "../DatePicker";
import { PrimaryInput } from "./inputFields";
import { formattedDate, todaysDate } from "./currentDateFormated";
import useAPI from "./UseApi";
import MultiSelectDropdown from "./multiSelect";

const FilterPage = ({
  handleOpen,
  open,
  status = true,
  hasSearchDate = true,
  mls = true,
  price = true,
  title,
  expiredStatuses,
  setDistressedLevel,
  setPrefotype,
  filter,
  params,
}) => {
  const { get } = useAPI();
  const [expiredStatus, setExpiredStatus] = useState(true);
  const [pendingStatus, setPendingStatus] = useState(!true);
  const [withdrawnStatus, setWithdrawnStatus] = useState(true);
  const [unavailableStatus, setUnavailableStatus] = useState(false);
  const [offMarketStatus, setOffMarketStatus] = useState(false);
  const [canceledStatus, setCanceledStatus] = useState(!false);
  const [releasedStatus, setReleasedStatus] = useState(false);
  const [activeStatus, setActiveStatus] = useState(false);
  const [rentedStatus, setRentedStatus] = useState(false);
  const [soldStatus, setSoldStatus] = useState(false);

  const [searchDateType, setSearchDateType] = useState("Lead Delivery Date");
  const [searchDateState, setSearchDateState] = useState();
  const [from_date, setfrom_date] = useState(formattedDate);
  const [to_date, setto_date] = useState(todaysDate);
  const [mlsREcord, setMlsRecord] = useState("");
  const [mlsRecordState, setMlsRecordState] = useState("");
  const [beds, setbeds] = useState("");
  const [bedState, setBedState] = useState("");
  const [baths, setbaths] = useState("");
  const [bathState, setbathState] = useState("");
  const [min_price, setmin_price] = useState("");
  const [max_price, setmax_price] = useState("");
  const [sq_ft, setsq_ft] = useState("");
  const [sq_ftState, setsq_ftState] = useState("");
  const [postalCode, setPostslCode] = useState("");
  const [distressed_level, setDistressed_level] = useState("");
  const [distressedLevelState, setDistressedLevelState] = useState("");
  const [preFoState, setPreFoState] = useState("");
  const [preFoStateState, setPreFoStateState] = useState([]);
  const [showActives, setShowActives] = useState(true);
  //const [showActivesState, setShowActivesState] = useState("");
  const [mlsList, setMlsList] = useState([
    { state: "All", name: "All", customer_mls_id: NaN },
  ]);

  // eslint-disable-next-line
  const [tags, setTags] = useState("");

  const [statuses, setStatuses] = useState([]);

  useEffect(() => {}, [distressed_level]);

  const handleDistressed = (levels = []) => {
    const distressedLevel = levels;
    setDistressed_level(distressedLevel);
  };

  const handleShowActives = (state) => {
    setShowActives(state);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await get("/api/core/account/mls");
        setMlsList([...mlsList, ...result]);
      } catch {}
    };
    fetchData();

    window.addEventListener("keydown", handleKeyPress);

    // Removing the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyPress = (event) => {
    // For example, checking if the pressed key is Enter

    if (open && event.key === "Enter") {
      event.preventDefault();
      setFIlter();
      handleOpen();
    }
  };

  const queryParams = [];
  useEffect(() => {
    let selectedStatus = [];
    if (expiredStatus) selectedStatus.push("X");

    if (pendingStatus) selectedStatus.push("P");

    if (withdrawnStatus) selectedStatus.push("W");

    if (unavailableStatus) selectedStatus.push("N");

    if (offMarketStatus) selectedStatus.push("O");

    if (canceledStatus) selectedStatus.push("C");

    if (releasedStatus) selectedStatus.push("R");

    if (activeStatus) selectedStatus.push("A");

    if (rentedStatus) selectedStatus.push("T");

    if (soldStatus) selectedStatus.push("S");
    setStatuses(selectedStatus);

    // eslint-disable-next-line
  }, [
    expiredStatus,
    pendingStatus,
    withdrawnStatus,
    unavailableStatus,
    offMarketStatus,
    canceledStatus,
    releasedStatus,
    activeStatus,
    rentedStatus,
    soldStatus,
  ]);

  // Iterate through all state variables
  const stateVariables = {
    date_search_type:
      searchDateType === "Lead Delivery Date"
        ? "file_upload_date"
        : searchDateType === "Original Status Date"
        ? "listing_status_date"
        : "",
    from_date,
    to_date,
    mls: mlsList.find((obj) => obj.name === mlsREcord)?.customer_mls_id,
    beds,
    baths,
    min_price,
    max_price,
    sq_ft,
    postal_codes: postalCode,
    tags,
    show_mls_active: showActives,
  };
  //  status: statuses
  //       .map((status, index) => {
  //         const str =
  //           index === statuses.length - 1 ? `${status}` : `${status}&status=`;
  //         return str;
  //       })
  //       .join(""),
  for (const key in stateVariables) {
    const value = stateVariables[key];

    if (
      value !== undefined &&
      value !== null &&
      value !== "Any" &&
      value !== "All" &&
      // eslint-disable-next-line
      value !== NaN &&
      value !== ""
    ) {
      if (key !== "postal_codes" && key !== "tags" && key !== "mls") {
        queryParams.push(
          `${key}=${encodeURIComponent(String(value).replace(/ /g, "_"))}`
        );
      } else if (key === "postal_codes" || key === "tags") {
        queryParams.push(`${key}=${encodeURIComponent(String(value))}`);
      } else if (key === "show_mls_active" && !value) {
        queryParams.push(`${key}=${encodeURIComponent(String(false))}`);
        // eslint-disable-next-line
      } else if (key === "mls" && value && value !== NaN) {
        queryParams.push(`${key}=${encodeURIComponent(String(value))}`);
      }
    }
  }
  const queryString = queryParams.join("&");
  const link = `${queryString}`;

  const setFIlter = () => {
    filter([
      { from: from_date },
      { to: to_date },
      { beds },
      { baths },
      { "min price": min_price },
      { "max price": max_price },
      { "spr feet": sq_ft },
      { "postal codes": postalCode },
      {
        "distressed Level":
          title === "Distressed Filters"
            ? distressed_level?.map((level) => level.split(" ")[0])
            : null,
      },
      { status: status ? statuses : null },
      { mls: mlsList.find((obj) => obj.name === mlsREcord)?.name },
      { tags },
      {
        "show active":
          (title === "Distressed Filters" ||
            title === "Pre-Foreclosure Filters" ||
            title === "FSBO Filters") &&
          showActives
            ? "Yes"
            : (title === "Distressed Filters" ||
                title === "Pre-Foreclosure Filters" ||
                title === "FSBO Filters") &&
              !showActives
            ? "No"
            : null,
      },
    ]);
    params(link);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <Modal
      handleOpen={handleOpen}
      open={open}
      mxWidth={"max-w-[620px]"}
      title={title}
    >
      <HDivider />

      <div
        id="focusableDiv"
        className=" overscroll-x-none md:max-h-[calc(70vh-32px)] max-h-[calc(70vh-132px)] overflow-y-scroll max-w-[100vw]"
      >
        <div className=" flex gap-4 flex-grow pt-4 flex-col sm:flex-row">
          <div className=" h-16 gap-1 flex-1">
            <p className="text-[#999999] text-[14px] font-semibold mb-2">
              From
            </p>
            <DatePicker onValueChange={setfrom_date} value={from_date} />
          </div>
          <div className=" h-16 gap-1 flex-1">
            <p className="text-[#999999] text-[14px] font-semibold mb-2">To</p>
            <DatePicker onValueChange={setto_date} value={to_date} />
          </div>
        </div>
        <div className="py-4">
          {status === true && (
            <p className=" font-semibold text-[14px] leading-5 text-[#999999]">
              MLS Status:
            </p>
          )}
          <div className="flex gap-8 mt-3  md:w-[552px] flex-col">
            {status === true && (
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 w-full">
                <Checkbox
                  title={"Expired"}
                  state={expiredStatus}
                  stateChange={setExpiredStatus}
                />
                <Checkbox
                  title={"Pending"}
                  state={pendingStatus}
                  stateChange={setPendingStatus}
                />
                <Checkbox
                  title={"Withdrawn"}
                  state={withdrawnStatus}
                  stateChange={setWithdrawnStatus}
                />
                <Checkbox
                  title={"Off Market"}
                  state={offMarketStatus}
                  stateChange={setOffMarketStatus}
                />
                <Checkbox
                  title={"Canceled"}
                  state={canceledStatus}
                  stateChange={setCanceledStatus}
                />
                <Checkbox
                  title={"Released"}
                  state={releasedStatus}
                  stateChange={setReleasedStatus}
                />
                <Checkbox
                  title={"Active"}
                  state={activeStatus}
                  stateChange={setActiveStatus}
                />
                <Checkbox
                  title={"Rented"}
                  state={rentedStatus}
                  stateChange={setRentedStatus}
                />
                <Checkbox
                  title={"Sold"}
                  state={soldStatus}
                  stateChange={setSoldStatus}
                />
                <Checkbox
                  title={"Unavailable"}
                  state={unavailableStatus}
                  stateChange={setUnavailableStatus}
                />
              </div>
            )}

            {(title === "Distressed Filters" ||
              title === "Pre-Foreclosure Filters" ||
              title === "FSBO Filters") && (
              <div className=" -mb-4">
                <p className="text-[#999999] text-[14px] font-semibold mb-2">
                  MLS Status:
                </p>
                {/* <Selection
                  rounded={"4px"}
                  SelectionColor={true}
                  selecionHeight={"36px"}
                  setIsActive={() => {}}
                  list={showActive}
                  initialSelection={0}
                  onValueChange={handleShowActives}
                  globalSelected={showActivesState}
                  setGlobalSelected={setShowActivesState}

                  //flowWidth={true}
                /> */}
                {
                  <Checkbox
                    title={"Show Actives"}
                    state={showActives}
                    stateChange={handleShowActives}
                  />
                }
              </div>
            )}

            {title === "Distressed Filters" && (
              <div className=" ">
                <p className="text-[#999999] text-[14px] font-semibold mb-2">
                  Level Of Distress
                </p>
                <MultiSelectDropdown
                  rounded={"4px"}
                  SelectionColor={!true}
                  selecionHeight={"36px"}
                  setIsActive={() => {}}
                  list={distressedLevel}
                  initialSelection={[]}
                  onValueChange={handleDistressed}
                  globalSelected={distressedLevelState}
                  setGlobalSelected={setDistressedLevelState}

                  //flowWidth={true}
                />
              </div>
            )}

            {title === "Pre-Foreclosure Filters" && (
              <div className=" ">
                <p className="text-[#999999] text-[14px] font-semibold mb-2">
                  Pre Foreclosure Status
                </p>
                <MultiSelectDropdown
                  rounded={"4px"}
                  SelectionColor={!true}
                  selecionHeight={"36px"}
                  setIsActive={() => {}}
                  list={PreforeclosureStatusType}
                  initialSelection={PreforeclosureStatusType}
                  onValueChange={setPreFoState}
                  globalSelected={preFoStateState}
                  setGlobalSelected={setPreFoStateState}

                  //flowWidth={true}
                />
              </div>
            )}

            {price && (
              <div className=" flex gap-4 flex-grow flex-col sm:flex-row">
                <div className=" h-16 gap-1 flex-1">
                  <p className="text-[#999999] text-[14px] font-semibold mb-2">
                    Min Price
                  </p>
                  <PrimaryInput
                    placeHolder={"0"}
                    type={"number"}
                    onValueChange={setmin_price}
                  />
                </div>
                <div className=" h-16 gap-1 flex-1">
                  <p className="text-[#999999] text-[14px] font-semibold mb-2">
                    Max Price
                  </p>
                  <PrimaryInput
                    placeHolder={"0"}
                    type={"number"}
                    onValueChange={setmax_price}
                  />
                </div>
              </div>
            )}
            <div className="w-full">
              <p className="text-[#999999] text-[14px] font-semibold mb-2">
                Tags
              </p>
              <PrimaryInput
                onValueChange={setTags}
                placeHolder={"Ex. tag"}
                value={tags}
              />
              <p className="text-[12px] leading-4 text-[#999999]">
                Separate tags with spaces to match all, or commas to match any
              </p>
            </div>

            <div className=" flex gap-4 flex-grow flex-col sm:flex-row">
              <div className=" h-16 gap-1 flex-1">
                <p className="text-[#999999] text-[14px] font-semibold mb-2">
                  Beds
                </p>
                <Selection
                  rounded={"4px"}
                  SelectionColor={false}
                  selecionHeight={"36px"}
                  setIsActive={() => {}}
                  list={bedsList}
                  initialSelection={0}
                  flowWidth={true}
                  onValueChange={setbeds}
                  globalSelected={bedState}
                  setGlobalSelected={setBedState}
                />
              </div>
              <div className=" h-16 gap-1 flex-1">
                <p className="text-[#999999] text-[14px] font-semibold mb-2">
                  Bath
                </p>
                <Selection
                  rounded={"4px"}
                  SelectionColor={false}
                  selecionHeight={"36px"}
                  setIsActive={() => {}}
                  list={bathsList}
                  initialSelection={0}
                  flowWidth={true}
                  onValueChange={setbaths}
                  globalSelected={bathState}
                  setGlobalSelected={setbathState}
                />
              </div>
            </div>

            <div className=" h-16 gap-1">
              <p className="text-[#999999] text-[14px] font-semibold mb-2">
                Square Feet
              </p>
              <Selection
                rounded={"4px"}
                SelectionColor={false}
                selecionHeight={"36px"}
                setIsActive={() => {}}
                list={squareFeet}
                initialSelection={0}
                flowWidth={true}
                onValueChange={setsq_ft}
                globalSelected={sq_ftState}
                setGlobalSelected={setsq_ftState}
              />
            </div>

            <div className=" h-16 mb-4  w-full flex flex-col">
              <p className="text-[#999999] text-[14px] font-semibold mb-2">
                Postal Code(s)
              </p>
              <PrimaryInput
                placeHolder={"Ex. 84043"}
                onValueChange={setPostslCode}
                value={postalCode}
              />
              <p className="text-[12px] leading-4 text-[#999999]">
                Enter Postal codes separated by commas
              </p>
            </div>
            {hasSearchDate && (
              <div className=" h-16 gap-1">
                <p className="text-[#999999] text-[14px] font-semibold mb-2">
                  Search Date
                </p>
                <Selection
                  rounded={"4px"}
                  SelectionColor={true}
                  selecionHeight={"36px"}
                  setIsActive={() => {}}
                  list={searchDate}
                  initialSelection={0}
                  onValueChange={setSearchDateType}
                  globalSelected={searchDateState}
                  setGlobalSelected={setSearchDateState}
                  //flowWidth={true}
                />
              </div>
            )}

            {mls && (
              <div className=" h-16 gap-1 mb-[48px]">
                <p className="text-[#999999] text-[14px] font-semibold mb-2">
                  MLS
                </p>
                <Selection
                  rounded={"4px"}
                  SelectionColor={false}
                  selecionHeight={"36px"}
                  setIsActive={() => {}}
                  list={mlsList}
                  initialSelection={0}
                  flowWidth={true}
                  onValueChange={setMlsRecord}
                  globalSelected={mlsRecordState}
                  setGlobalSelected={setMlsRecordState}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <HDivider />
      <div className=" h-10 my-8 flex justify-between ">
        <SecondaryButtons label={"Cancel"} onClick={handleOpen} />
        <PrimaryButtons
          label={"Apply Filter"}
          onClick={(e) => {
            handleOpen();
            setFIlter();
            if (expiredStatuses && status === true) expiredStatuses(statuses);

            if (setDistressedLevel)
              setDistressedLevel(
                distressed_level[0] === "Show all " ? "" : distressed_level
              );

            if (setPrefotype)
              setPrefotype(preFoState[0] === "Show all " ? "" : preFoState);
          }}
        />
      </div>
    </Modal>
  );
};

export default FilterPage;
