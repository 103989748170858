import React from "react";
import { Link } from "react-router-dom";
import pdfDownload from "../../common/pdfDownload";

const FSBOScripts = () => {
  const scripts = [
    {
      name: "FSBO Approach Script ",
      description: "Step-by-step script for approaching FSBOs.",
      link: "https://cdn.landvoice.com/pdf/FSBO+Approach+Script.pdf",
    },
    {
      name: "FSBO Fallacies  ",
      description: "Common misunderstandings of FSBOs.",
      link: "https://cdn.landvoice.com/pdf/FSBO+Fallacies.pdf",
    },
    {
      name: "We’ve sold properties on our own ",
      description: "Script for FSBOs who have sold their own property before.",
      link: "https://cdn.landvoice.com/pdf/We've+sold+our+own+properties+before.pdf",
    },
    {
      name: "Conversational Selling ",
      description: "Tips for conversation with working with FSBOs.",
      link: "https://cdn.landvoice.com/pdf/Conversational+selling.pdf",
    },
    {
      name: "Bring me a buyer ",
      description:
        "Responses for sellers who request you bring them a buyer before you can list their house.",
      link: "https://cdn.landvoice.com/pdf/Bring+me+a+buyer.pdf",
    },
    {
      name: "Easy FSBO Script for Call Capture",
      description: "Generate more buyers and sellers in your area.",
      link: "https://cdn.landvoice.com/pdf/more-buyers-sellers-in-area.pdf",
    },
  ];

  return (
    <section className=" min-h-[406px] bg-white rounded-[8px] p-[32px] mb-[16px] border-[#EBEBEB] border-[1px]">
      <p className=" text-[#666666] text-[28px] leading-[28px] font-[500] mb-[32px]">
        FSBO Scripts
      </p>

      <div className="min-h-[282px] ">
        {scripts.map((item, index) => {
          return (
            <div
              key={`FSBO${index}`}
              className=" min-h-[94px] rounded-[8px] border-b-[1px] border-[#EBEBEB] p-[16px] flex justify-between flex-col sm:flex-row"
            >
              <div>
                <Link to={`${item.link}`} target="_blank">
                  {" "}
                  <p className="text-[20px] text-[#666666] font-[600] leading-[28px] md:leading-[28px] cursor-pointer">
                    {item.name}
                  </p>
                </Link>
                <p className="text-[16px] text-[#666666] font-[400] leading-[28px] md:leading-[28px]">
                  {item.description}
                </p>
              </div>

              <Link
                onClick={() => {
                  pdfDownload(item.link, item.name);
                }}
                className=" mt-[16px] md:mt-[0px] h-[40px] w-[99px] bg-[#E8EDF0] rounded-[8px] px-[16px] py-[6px] flex justify-center items-center hover:bg-[#D5DDE1]"
              >
                <p className=" text-[14px] leading-[28px] font-[400] text-[#272829]">
                  {"Download "}
                </p>
              </Link>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default FSBOScripts;
