import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const Tabbar = ({ onStateChange }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const query = useQuery();
  const searchTerm = query.get("tab");

  useEffect(() => {
    if (searchTerm === "fsbo") changeSelection(3);
    // eslint-disable-next-line
  }, [searchTerm]);

  const changeSelection = (index) => {
    setSelectedTab(index);
    onStateChange(index);
  };
  const tabs = [
    {
      name: "My Account",
    },
    {
      name: "MLS",
    },
    {
      name: "Expired",
    },
    {
      name: "FSBO",
    },
  ];
  return (
    <>
      <div className="flex h-[52px] tab overflow-x-scroll max-w-full overflow-y-hidden">
        <div className=" flex min-w-[340px] md:min-w-[550px] md:gap-[16px]">
          {tabs.map((item, index) => {
            return (
              <p
                key={`tab${index}`}
                className={
                  selectedTab !== index ? "tabButton" : "tabButtonSelected "
                }
                onClick={() => changeSelection(index)}
              >
                {item.name}
              </p>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Tabbar;
