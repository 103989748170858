import React from "react";
import { OutlineButtons } from "./buttons";
import Image from "../../assets/img/undraw_void_-3-ggu 1.svg";

const NoAccessPage = ({ product = "Expireds" }) => {
  const handleCall = () => {
    // Use window.location to initiate the phone call
    window.location.href = `tel:${8886780905}`;
  };
  return (
    <section>
      <div className="flex justify-center items-center flex-col gap-[32px] py-3">
        <img className="h-[180px]" src={Image} alt="Description" />
        <div className=" text-center flex flex-col gap-2 items-center">
          <p className=" text-[#999999] text-[24px]  font-semibold leading-6">
            You are not currently subscribed to {product}
          </p>
          <p className="text-[16px] leading-6 text-[#999999]">
            To gain access to this data please contact a Landvoice Sales
            Representative at 888-678-0905.
          </p>
          <div className=" w-[140px]">
            <OutlineButtons label={"Call Support"} onClick={handleCall} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default NoAccessPage;
