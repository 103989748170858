const calendarPositionClassNames = (position) => {
  switch (position) {
    case "bottom-left":
      return `absolute top-[0px] left-[0px]`;
    case "bottom":
      return `absolute top-[0px] left-[calc(50%-120px)]`;
    case "default":
      return `absolute top-[0px] left-[calc(50%-120px)]`;
    default:
      return "";
  }
};

export default calendarPositionClassNames;
