import React, { useState } from "react";
import PageLayout from "../components/common/PageLayout";
import logo from "../assets/img/landvoiceLogo.png";
import ErrorMessage from "../components/common/ErrorMessage";
import useAPI from "../components/common/UseApi";
import { NavLink } from "react-router-dom";
import spinner from "../assets/img/spinner-third.svg";
import { OutlineButtons } from "../components/common/buttons";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const { error, post, clearErrorMessage, loading } = useAPI();
  const [done, setDone] = useState(false);

  const reset = () => {
    post(
      "/api/auth/password/reset",
      {
        email,
      },
      () => {
        setDone(true);
      }
    );
  };

  return (
    <PageLayout hideSideBar={true} pageBG={"bg-hero-pattern"}>
      <div className="flex justify-center items-center h-[60px] w-screen top-0 left-0 fixed bg-[#212A33]">
        <img src={logo} width={165} height={28} alt="landvoice logo" />
      </div>

      <div className="flex justify-center items-center w-full min-h-[calc(100vh)] md:h-[calc(100vh)] pt-[60px] overflow-y-scroll">
        {loading ? (
          <div className="h-[348px] w-[420px]  bg-[#FFFFFF] m-4 md:m-8 md:py-16 py-8  px-4 md:px-8 rounded-lg  border-[1px] border-[#E0E0E0]">
            <div className="w-full flex flex-col items-center gap-2 h-[88px] ">
              <p className=" leading-8 text-[28px] font-[600] text-center text-[#666666]">
                Sending email to Reset your password...
              </p>
              <p className="font-[400] text-[16px] leading-[20px] text-[#999999] mb-8">
                Just a moment! This shouldn’t take long.
              </p>

              <div className="h-[125px] w-full flex justify-center items-center">
                <img className="animate-spin" src={spinner} alt="spinner" />
              </div>
            </div>
          </div>
        ) : done ? (
          <div className="h-[348px] w-[420px]  bg-[#FFFFFF] m-4 md:m-8 md:py-16 py-8  px-4 md:px-8 rounded-lg  border-[1px] border-[#E0E0E0]">
            <div className="w-full flex flex-col items-center gap-2 h-[88px] ">
              <p className=" leading-8 text-[28px] font-[600] text-center text-[#666666]">
                Email Sent successful
              </p>
              <p className="font-[400] text-[16px] leading-[20px] text-[#999999] mb-8">
                We’ve sent a link and instructions to set a new password to the
                submitted email. If you have not received an email within 10
                minutes, please check your junk folder.
              </p>

              {/* <div className="h-[125px] w-full flex justify-center items-center">
                  <img className="animate-spin" src={spinner} alt="spinner" />
                </div> */}
              <NavLink to={"/signin/"}>
                <OutlineButtons
                  className={"bg-red500"}
                  label={"Go Back To Login"}
                />
              </NavLink>
            </div>
          </div>
        ) : (
          <div className=" w-[420px] min-h-[500px] bg-[#FFFFFF] m-4 md:m-8 md:py-16 py-8  px-4 md:px-8 rounded-lg  border-[1px] border-[#E0E0E0]">
            <div className="w-full flex flex-col items-center gap-2 h-[88px] mb-8">
              <p className=" leading-8 text-[28px] font-[600] text-center text-[#666666]">
                Password Reset Request
              </p>
              <p className="font-[400] text-[16px] text-center leading-[20px] text-[#999999] ">
                Please enter your email address below to reset your password.
              </p>
            </div>
            {error ? <ErrorMessage message={"Email not found."} /> : null}
            {error ? (
              <p className="text-[14px] font-[600] leading-[20px] pb-2 text-[#999999]">
                {" "}
                Email Address
              </p>
            ) : null}
            <input
              required
              value={email}
              onChange={(event) => {
                handleEmailChange(event);
                clearErrorMessage(event);
              }}
              onVolumeChange={clearErrorMessage}
              title="Email Address"
              type="text"
              placeholder={"Email Address"}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  reset();
                }
              }}
              className="loginInput"
            />

            <button className=" w-full h-[40px] bg-[#F27935] hover:bg-[#E2631B] rounded-lg mb-8">
              <p
                onClick={(event) => {
                  reset();
                }}
                className="text-[14px] font-[600] leading-[28px] text-[#FFFFFF]"
              >
                Submit
              </p>
            </button>

            <div className="flex justify-between items-center mb-8">
              <div className="loginDivider" />
              <p className=" mx-6 text-[14px] text-[#999999] font-[400] leading-[20px] mb-0">
                OR
              </p>
              <div className="loginDivider" />
            </div>
            <NavLink to={"/signin/"}>
              <button className=" w-full h-[40px] bg-[#FFF] rounded-lg mb-4 border-[1px] hover:border-2 hover:border-[#666666] border-[#666666]">
                <p className="text-[14px] font-[600] leading-[28px] text-[#666666]">
                  Go back to login
                </p>
              </button>
            </NavLink>
          </div>
        )}
      </div>
    </PageLayout>
  );
};

export default ForgotPassword;
