import React from "react";

const PrimaryButtons = ({
  label,
  onClick,
  downloading = false,
  width = "min-w-[60px]",
  isDisable = false,
}) => {
  return (
    <button
      className={`${width} h-10 rounded-lg bg-[#F27935] disabled:bg-[#836e61f9] hover:bg-[#E2631B] selection:bg-[#C04E0E] py-[6px] px-4 cursor-pointer disabled:cursor-default`}
      onClick={onClick}
      disabled={isDisable ? isDisable : downloading}
    >
      {downloading ? (
        <div className=" w-full flex justify-center">
          <div className="w-4 h-4 border-2  border-solid  border-t-0 border-r-0 border-white rounded-full animate-spin"></div>
        </div>
      ) : (
        <p className="text-[#FFFFFF] text-[14px] font-semibold leading-7 ">
          {label}
        </p>
      )}
    </button>
  );
};

const SecondaryButtons = ({ label, onClick }) => {
  return (
    <div
      className=" min-w-[60px] h-10 rounded-lg bg-[#E8EDF0] hover:bg-[#D5DDE1] selection:bg-[#BFC7CC] py-[6px] px-4 cursor-pointer"
      onClick={onClick}
    >
      <p className="text-[#666666] text-[14px] font-semibold leading-7 ">
        {label}
      </p>
    </div>
  );
};

const OutlineButtons = ({ label, onClick }) => {
  return (
    <div
      className=" outline outline-1 min-w-[60px] w-full h-10 rounded-lg  hover:bg-[#D5DDE1] selection:bg-[#BFC7CC] py-[6px] px-4 cursor-pointer"
      onClick={onClick}
    >
      <p className="text-[#666666] text-[14px] font-semibold leading-7 text-center ">
        {label}
      </p>
    </div>
  );
};

export { PrimaryButtons, SecondaryButtons, OutlineButtons };
