import React from "react";

import Desktopsidebar from "./Desktopsidebar.js";
import MobileView from "./MobileView.js";
import useMediaQuery from "../../common/UseMediaQuery.js";

const Sidebar = ({ Outlet, openDetails }) => {
  const isMobile = useMediaQuery("(max-width: 767px)");
  return isMobile ? (
    <MobileView />
  ) : (
    <Desktopsidebar openDetails={openDetails} />
  );
};

export default Sidebar;
