import React, { useEffect, useState } from "react";
import * as FaIcons from "react-icons/fa";
import Selection from "../utils/account/selectForPagination";

const Pagination = ({
  pages = [],
  currentPage = 6,

  onPageChange,
  perPage,
}) => {
  const [lastPage, setLastPage] = useState(0);
  useEffect(() => {
    setLastPage(pages.length);
  }, [perPage.value, pages.length]);
  return (
    <>
      <div className="flex justify-end gap-1 items-center">
        <Selection
          currentPage={currentPage}
          lastPage={lastPage}
          list={[]}
          setPage={onPageChange}
        />
        <div className="flex gap-4 items-center -space-x-px h-10 text-base">
          <div>
            <button
              className="flex cursor-pointer items-center justify-center px-2 h-10 ms-0 leading-tight text-gray-500 rounded-s-lg hover:bg-gray-100 hover:text-gray-700"
              onClick={(e) => {
                if (currentPage > 1) onPageChange(currentPage - 1);
              }}
            >
              <span className="sr-only">Previous</span>
              <FaIcons.FaChevronLeft />
            </button>
          </div>

          {/* {currentPage - 2 > 1 && <div className="cursor-pointer">{"..."}</div>}
        {lastPage === currentPage && currentPage - 4 >= 3 && (
          <div
            className="cursor-pointer"
            onClick={(e) => {
              onPageChange(currentPage - 4);
            }}
          >
            {currentPage - 4}
          </div>
        )}
        {lastPage <= currentPage + 1 && currentPage - 3 >= 2 && (
          <div
            className="cursor-pointer"
            onClick={(e) => {
              onPageChange(currentPage - 3);
            }}
          >
            {currentPage - 3}
          </div>
        )}
        {currentPage - 2 >= 1 && (
          <div
            className="cursor-pointer"
            onClick={(e) => {
              onPageChange(currentPage - 2);
            }}
          >
            {currentPage - 2}
          </div>
        )}
        {currentPage - 1 >= 1 && (
          <div
            className="cursor-pointer"
            onClick={(e) => {
              onPageChange(currentPage - 1);
            }}
          >
            {currentPage - 1}
          </div>
        )}
        <div className="z-10 flex items-center justify-center w-10 h-10 leading-tight text-black border rounded-full  bg-white hover:bg-[#fa7532] hover:text-blue-700 cursor-pointer">
          {currentPage}
        </div>
        {lastPage >= currentPage + 1 && (
          <div
            className="cursor-pointer"
            onClick={(e) => {
              onPageChange(currentPage + 1);
            }}
          >
            {currentPage + 1}
          </div>
        )}
        {lastPage >= currentPage + 2 && (
          <div
            className="cursor-pointer"
            onClick={(e) => {
              onPageChange(currentPage + 2);
            }}
          >
            {currentPage + 2}
          </div>
        )}
        {currentPage + 3 <= 5 && currentPage + 3 < lastPage && (
          <div
            className="cursor-pointer"
            onClick={(e) => {
              onPageChange(currentPage + 3);
            }}
          >
            {currentPage + 3}
          </div>
        )}
        {lastPage >= currentPage + 4 && currentPage + 4 <= 5 && currentPage && (
          <div
            className="cursor-pointer"
            onClick={(e) => {
              onPageChange(currentPage + 4);
            }}
          >
            {currentPage + 4}
          </div>
        )}
        {currentPage + 2 < lastPage && (
          <div className="cursor-pointer">{"..."}</div>
        )}
        */}

          <button
            className="flex cursor-pointer items-center justify-center px-2 h-10 ms-0 leading-tight text-gray-500 rounded-s-lg hover:bg-gray-100 hover:text-gray-700"
            onClick={(e) => {
              if (currentPage < lastPage) onPageChange(currentPage + 1);
            }}
          >
            <span className="sr-only">Next</span>
            <FaIcons.FaChevronRight />
          </button>
        </div>
      </div>
    </>
  );
};

export default Pagination;
