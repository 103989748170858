import React, { useState, useEffect } from "react";
import PageLayout from "../components/common/PageLayout";
import Tabbar from "../components/utils/account/TabBar";
import MyAccount from "../components/utils/account/myAccount";

import ExpiredFilters from "../components/utils/account/expiredFilters";
import FsboFilter from "../components/utils/account/fsboFilter";
import MlsCredentials from "../components/utils/account/mlsCredentials";
import useAPI from "../components/common/UseApi";

function Settings() {
  const [tabState, setTabState] = useState(0);
  const handleTabStateChange = (State) => {
    setTabState(State);
  };

  const { get, post } = useAPI();

  const [accountData, setAccountData] = useState({});
  const [partnerList, setPartnerList] = useState([]);
  const [userPartner, setUserPartner] = useState("");
  const [ptrIndex, setPtrIndex] = useState("");
  const [mlsCredentials, setMlsCredentials] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const promises = [
          get("/api/core/account"),
          get("/api/core/partner"),
          get("/api/core/account/mls"),
          get("/api/core/account/partner"),
        ];

        Promise.all(promises)
          .then(([userData, partners, _mlsCredentials, userPartnerData]) => {
            partners.unshift({ name: "None", internal_name: "none" });

            // Now you can use the results here

            setAccountData(userData);
            setUserPartner(userPartnerData);
            setPartnerList(partners);
            const ptrIndex = partners.findIndex(
              (item) => item.internal_name === userPartnerData.partner
            );
            setPtrIndex(ptrIndex);
            setMlsCredentials(_mlsCredentials);
          })
          .catch((error) => {
            // Handle errors here
            console.error(error);
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetch = async () => {
      await post("/api/core/account/mls/credentials", {
        customer_mls_id: mlsCredentials.customer_mls_id,
      });
    };

    fetch();
    // eslint-disable-next-line
  }, [mlsCredentials]);

  return (
    <PageLayout>
      <div className="min-h-[120px] mb-[8px]">
        <h1 className=" leading-[57px] font-[600] text-[48px] text-[#666666]  mb-[14px]">
          Account Settings
        </h1>
        <p className="leading-[24px] font-[400] text-[16px] text-[#999999]">
          If you have any questions, please contact Customer Support at (888)
          678-0905.
        </p>
      </div>
      <Tabbar onStateChange={handleTabStateChange} />

      {tabState === 0 && (
        <MyAccount
          accountData={accountData}
          userPartner={userPartner}
          partnerList={partnerList}
          ptrIndex={ptrIndex}
        />
      )}
      {tabState === 1 && (
        <MlsCredentials
          partnerList={partnerList}
          mlsCredentials={mlsCredentials}
        />
      )}
      {tabState === 2 && <ExpiredFilters />}
      {tabState === 3 && <FsboFilter />}
    </PageLayout>
  );
}

export default Settings;
