import React from "react";
import { Mail, Editbutton } from "@landvoice/icons";
import {
  ContactCard,
  HDivider,
  InfoText,
  MlsTitleAmenities,
  formatDate,
} from "../../common/amenities";
import { PropertyTypeEnum } from "../selectionData";
const PrintDetails = ({ data, index, where, isDistressed = false }) => {
  return (
    <div
      className={
        "w-full z-[10] md:px-12 px-4  " + index === 0
          ? ""
          : " break-before-page"
      }
    >
      <MlsTitleAmenities
        all={false}
        isDistressed={isDistressed}
        distressedLevel={data?.distressed_level}
        title={` ${data?.property?.street_address || data?.street_address}, ${
          data?.property?.city || data?.city
        }, ${data?.property?.state || data?.state} ${
          data?.property?.postal_code || data?.postal_code
        }`}
        bath={data?.baths || data?.bathrooms}
        beds={data?.bedrooms}
        SqrFeet={data?.square_feet || data?.square_footage}
        lotSize={data?.acres || data?.property_size}
        buildDate={data?.year_built}
        type={
          PropertyTypeEnum[
            data?.property_type | data?.property_features?.property_type
          ]
        }
        close={true}
        isDetails={true}
        mapLink={`https://www.google.com/maps/place/${data?.property?.street_address}+${data?.property?.city}+${data?.property?.state}+${data?.property?.postal_code}
      `}
      />
      <div>
        <div>
          <HDivider />
          <div className="flex gap-[40px] flex-wrap items-center py-[auto] my-2">
            <InfoText
              title={"Price"}
              info={parseFloat(
                data?.list_price || data?.auction_min_bid || 0
              )?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
              })}
            />

            <InfoText
              title={"Published Date"}
              info={formatDate(data?.date_recorded)}
            />
            {where !== "distress" && (
              <InfoText
                title={"Auction Date"}
                info={formatDate(data?.auction_date)}
              />
            )}

            <div className="h-11 flex flex-col gap-2 justify-center">
              <p className="text-[14px] leading-[14px] text-[#999999]">
                Landvoice Pre-Foreclosure ID: {data?.id}
              </p>
              <p className="text-[14px] leading-[14px] text-[#999999]">
                Date Processed: {formatDate(data?.publish_date)}
              </p>
            </div>
          </div>
          <HDivider />
        </div>
      </div>
      <div className=" flex flex-col gap-3 mt-3">
        <section className="break-inside-avoid">
          <div className="md:max-w-[729px] flex gap-8 flex-row">
            <div className="  w-full ">
              <div className="flex gap-4 h-7 items-center   w-full">
                <p className="whitespace-nowrap text-[18px] font-bold leading-6 text-[#666666]">
                  Notes{" "}
                  {data?.notes?.length > 0 ? (
                    <span className="font-[500] text-[12px] text-[#999999]">
                      Last modified {formatDate(data?.notes[0]?.modify_date)}
                    </span>
                  ) : (
                    <span className="font-[500] text-[12px] text-[#999999]">
                      no notes were added
                    </span>
                  )}
                </p>{" "}
                <div className="h-[2px] w-full   min-w-[25px]   bg-[#EBEBEB]"></div>
                <div className="w-[20px] cursor-pointer">
                  <Editbutton className="text-[20px] w-5 h-5" />
                </div>
              </div>
              {data?.notes?.length > 0 ? (
                <p className="text-[14px] leading-[21px] text-[#666666] cursor-pointer">
                  {data?.notes[0]?.note}
                </p>
              ) : (
                <p className="text-[14px] leading-[21px] text-[#C2C2C2]">
                  No notes added.{" "}
                  <span className=" underline cursor-pointer">
                    Click to add notes.
                  </span>
                </p>
              )}
            </div>

            <div className=" max-w-[260px] ">
              <div className="flex w-full gap-4 h-7 items-center  ">
                <p className="whitespace-nowrap text-[18px] font-bold leading-6 text-[#666666]">
                  Tags{" "}
                </p>{" "}
                <div className="h-[2px] w-full    min-w-[200px]   bg-[#EBEBEB]"></div>
                <div className="w-[20px]">
                  <Editbutton className="text-[20px] w-5 h-5" />
                </div>
              </div>
              {data?.tags?.length ? (
                <div className=" flex flex-wrap gap-2">
                  {data?.tags?.map((tag, index) => {
                    return (
                      <div className="flex items-center justify-between h-6 min-w-[50px] bg-[#EBEBEB] px-4 gap-1 rounded-[15.56px]">
                        <p className="text-[11px] font-bold text-[#666666] leading-4">
                          {tag}
                        </p>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p className="text-[14px] leading-[21px] text-[#C2C2C2]">
                  No tags found.{" "}
                  <span className=" underline cursor-pointer">
                    Click to add tags.
                  </span>
                </p>
              )}
            </div>
          </div>
        </section>

        <section className="break-inside-avoid">
          <div className="max-w-[729px">
            <div className="flex gap-4 h-7 items-center mb-1 ">
              <p className="whitespace-nowrap text-[18px] font-bold leading-6 text-[#666666]">
                Owner Information{" "}
                <span className="font-[500] text-[12px] text-[#999999]"></span>
              </p>{" "}
              <div className="h-[2px] w-full  max-w-[562px]  min-w-[25px]   bg-[#EBEBEB]"></div>
            </div>
            <div className="grid grid-cols-3 max-h-[60px] ">
              <dir>
                <div className="min-h-[50px] flex flex-col justify-between">
                  <p className=" font-semibold text-[14px] leading-5 text-[#666666] ">
                    Owners Name
                  </p>
                  {data?.owner_name ? (
                    <p className="  text-[14px] leading-5 text-[#666666] ">
                      {data?.owner_name}
                    </p>
                  ) : (
                    <p className="  text-[14px] leading-5 text-[#999999] italic">
                      No Information found
                    </p>
                  )}
                </div>
              </dir>
              <div className="h-[50px] flex flex-col justify-between max-w-[220px]">
                <p className=" font-semibold text-[14px] leading-5 text-[#666666] ">
                  Owners Address
                </p>
                {data?.owner_information?.property?.street_address ? (
                  <p className="  text-[14px] leading-5 text-[#999999] ">
                    {`${data?.owner_information?.property?.street_address}, ${data?.owner_information?.property.city}, ${data?.owner_information?.property?.state} ${data?.owner_information?.property?.postal_code}`}{" "}
                  </p>
                ) : (
                  <p className="  text-[14px] leading-5 text-[#999999] italic">
                    No Owner address found
                  </p>
                )}
              </div>
              <div className="h-[50px] flex flex-col justify-between max-w-[220px]">
                <p className=" font-semibold text-[14px] leading-5 text-[#666666] ">
                  Owners Email
                </p>
                <div className="flex gap-3 items-center">
                  <Mail className="text-[16px]" />{" "}
                  {data?.social_media?.email ? (
                    <p className="  text-[14px] leading-5 text-[#999999] ">
                      jonesmatty13@hotmail.com
                    </p>
                  ) : (
                    <p className="  text-[14px] leading-5 text-[#999999] italic">
                      No email found
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="break-inside-avoid">
          <div className="max-w-[729px]">
            <div className="flex gap-4 h-7 items-center mb-1 ">
              <p className="whitespace-nowrap text-[18px] font-bold leading-6 text-[#666666]">
                Phone Numbers Found{" "}
                <span className="font-[500] text-[12px] text-[#999999]"></span>
              </p>{" "}
              <div className="h-[2px] w-full  max-w-[562px]  min-w-[25px]   bg-[#EBEBEB]"></div>
            </div>
            <div className=" grid grid-cols-3 max-h-[60px] ">
              {data?.contacts?.map((contact, index) => (
                <ContactCard
                  id={index}
                  name={`${contact?.first_name} ${contact?.last_name}`}
                  number={contact?.phone}
                  dnc={contact?.phone_dnc}
                  icon="Owner"
                  hidden={{
                    hidden: contact?.hidden,
                    tell: contact.phone,
                  }}
                  list={"preforeclosure"}
                  property_id={data?.property?.id}
                />
              ))}
            </div>
          </div>
        </section>

        <section className="break-inside-avoid">
          <div className="max-w-[729px]">
            <div className="flex gap-4 h-7 items-center mb-1 ">
              <p className="whitespace-nowrap text-[18px] font-bold leading-6 text-[#666666]">
                Information{" "}
                <span className="font-[500] text-[12px] text-[#999999]"></span>
              </p>{" "}
              <div className="h-[2px] w-full  max-w-[562px]  min-w-[25px]   bg-[#EBEBEB]"></div>
            </div>
            <div className=" flex justify-between gap-3  min-w-[40px] flex-row ">
              <div className="text-[#666666]  flex-1">
                <div className="flex justify-between gap-3  min-w-[40px] text-[#666666]  ">
                  <p className="text-[14px] font-semibold leading-5">
                    Mortgage Holder:
                  </p>
                  <p className="text-[14px] leading-5">
                    {data?.mortgage_holder}
                  </p>
                </div>
                <div className="flex justify-between gap-3  min-w-[40px]   ">
                  <p className="text-[14px] font-semibold leading-5">
                    Loan Amount:
                  </p>
                  <p className="text-[14px] leading-5"> {data?.loan_amount}</p>
                </div>
                <div className="flex justify-between gap-3  min-w-[40px]">
                  <p className="text-[14px] font-semibold leading-5">
                    County Recorded:
                  </p>
                  <p className="text-[14px] leading-5">
                    {data?.county_recorded}
                  </p>
                </div>
                <div className="flex justify-between gap-3  min-w-[40px]">
                  <p className="text-[14px] font-semibold leading-5">
                    Attorney Name:
                  </p>
                  <p className="text-[14px] leading-5">{data?.attorney_name}</p>
                </div>
              </div>

              <div className=" flex-1">
                <div className="flex justify-between gap-3  min-w-[40px]">
                  <p className="text-[14px] font-semibold leading-5 text-[#666666] ">
                    Mortgage Date:
                  </p>
                  <p className="text-[14px] leading-5 text-[#666666] ">
                    {formatDate(data?.mortgage_date)}
                  </p>
                </div>
                <div className="flex justify-between gap-3  min-w-[40px] text-[#666666] ">
                  <p className="text-[14px] font-semibold leading-5">
                    Delinquent Amount:
                  </p>
                  <p className="text-[14px] leading-5">
                    {parseFloat(data?.delinquent_amount || 0).toLocaleString(
                      "en-US",
                      {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 2,
                      }
                    )}
                  </p>
                </div>
                <div className="flex justify-between gap-3  min-w-[40px] text-[#666666] ">
                  <p className="text-[14px] font-semibold leading-5">
                    Date Recorded:
                  </p>
                  <p className="text-[14px] leading-5">
                    {formatDate(data?.date_recorded)}
                  </p>
                </div>

                <div className="flex justify-between gap-3  min-w-[40px] text-[#666666] ">
                  <p className="text-[14px] font-semibold leading-5">
                    Attorney Phone:
                  </p>
                  <p className="text-[14px] leading-5">
                    {data?.list_agent_company}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {where !== "distress" && (
          <section className="break-inside-avoid">
            <div className="max-w-[729px]">
              <div className="flex gap-4 h-7 items-center mb-1 ">
                <p className="whitespace-nowrap text-[18px] font-bold leading-6 text-[#666666]">
                  Auction Information
                  <span className="font-[500] text-[12px] text-[#999999]"></span>
                </p>{" "}
                <div className="h-[2px] w-full  max-w-[562px]  min-w-[25px]   bg-[#EBEBEB]"></div>
              </div>
              <div className=" flex justify-between gap-3  min-w-[40px] flex-row ">
                <div className="text-[#666666]  flex-1">
                  <div className="flex justify-between gap-3  min-w-[40px] text-[#666666]  ">
                    <p className="text-[14px] font-semibold leading-5">
                      Trustee Sale Number:
                    </p>

                    <p className="text-[14px] leading-5">
                      {data?.trustee_sale_number}
                    </p>
                  </div>
                  <div className="flex justify-between gap-3  min-w-[40px]">
                    <p className="text-[14px] font-semibold leading-5">
                      Auction Location:
                    </p>

                    <p className="text-[14px] leading-5">
                      {data?.auction_location}
                    </p>
                  </div>
                  <div className="flex justify-between gap-3  min-w-[40px]">
                    <p className="text-[14px] font-semibold leading-5">
                      Min Bid:
                    </p>

                    <p className="text-[14px] leading-5 max-w-[200px] text-right">
                      {parseFloat(data?.auction_min_bid).toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 2,
                        }
                      )}
                    </p>
                  </div>
                </div>

                <div className=" flex-1">
                  <div className="flex justify-between gap-3  min-w-[40px]">
                    <p className="text-[14px] font-semibold leading-5 text-[#666666] ">
                      Auction Date:
                    </p>
                    <p className="text-[14px] leading-5 text-[#666666] ">
                      {formatDate(data?.auction_date)}
                    </p>
                  </div>
                  <div className="flex justify-between gap-3  min-w-[40px] text-[#666666] ">
                    <p className="text-[14px] font-semibold leading-5">
                      Owner Occupied:
                    </p>
                    <p className="text-[14px] leading-5">
                      {data?.owner_information?.owner_occupied ? "yes" : "No"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {where !== "distress" && (
          <section className="break-inside-avoid">
            <div className="max-w-[729px]">
              <div className="flex gap-4 h-7 items-center mb-1 ">
                <p className="whitespace-nowrap text-[18px] font-bold leading-6 text-[#666666]">
                  Ad Text
                  <span className="font-[500] text-[12px] text-[#999999]"></span>
                </p>{" "}
                <div className="h-[2px] w-full  max-w-[562px]  min-w-[25px]   bg-[#EBEBEB]"></div>
              </div>

              <p className="text-[11px] text-[#666666] ">{data?.ad_text}</p>
            </div>
          </section>
        )}
      </div>
    </div>
  );
};

export default PrintDetails;
