import { schemaGenerator } from "../utils";

export const calendarContainerCommon =
  "flex flex-col flex-nowrap py-[12px] px-[12px] w-[240px] min-h-[240px] select-none bg-white";
const calendarDayCommon =
  "justify-center cursor-pointer flex items-center rounded-[4px] font-['Open_Sans'] text-[14px]";
const monthSelectorCommon =
  "h-[32px] w-[40px] text-center border-0 bg-transparent rounded-[4px] justify-center items-center";

export const dateInputClassNames = (
  showTimePicker,
  showEndTimePicker,
  dateFormatError
) => {
  if (
    (showTimePicker && showEndTimePicker) ||
    (!showTimePicker && !showEndTimePicker)
  ) {
    return dateFormatError ? "w-full mb-[12px]" : "w-full";
  }

  return dateFormatError ? "w-[104px] mb-[12px]" : "w-[104px]";
};

const dictionary = {
  dayDisabled: {
    base: [calendarDayCommon],
    default: ["text-semantic-red-10 w-[31px] h-[28px]", "!cursor-default"],
  },
  daySelected: {
    base: [calendarDayCommon],
    default: ["bg-[#F27935] text-white w-[31px] h-[28px]"],
  },
  dayTempToday: {
    default: ["text-white"],
  },
  dayToday: {
    base: [calendarDayCommon],
    default: [
      "border-[#F27935] text-[#F27935] border-[1px] border-solid m-auto w-[24px] h-[20px] justify-center cursor-pointer flex items-center rounded-[4px]",
    ],
  },
  dayOutside: {
    base: [calendarDayCommon],
    default: ["text-[#C2C2C2] w-[31px] h-[28px]"],
    hover: ["hover:bg-[#EBEBEB]"],
  },
  dayDefault: {
    base: [calendarDayCommon],
    default: ["text-[#666] w-[31px] h-[28px]"],
    hover: ["hover:bg-[#EBEBEB]"],
  },
  monthSelected: {
    base: [monthSelectorCommon],
    default: ["text-[#666] font-['Open_Sans'] font-bold"],
    hover: ["hover:bg-[#EBEBEB]"],
    active: ["active:bg-[#E0E0E0]"],
  },
  monthDefault: {
    base: [monthSelectorCommon],
    default: ["text-[#666]"],
    hover: ["hover:bg-[#EBEBEB]"],
    active: ["active:bg-[#E0E0E0]"],
  },
  navigation: {
    base: [
      "font-bold  font-['Open_Sans'] flex border-0 bg-transparent rounded-[4px] items-center p-[4px]",
    ],
    default: ["text-[#666]"],
    hover: ["hover:bg-[#EBEBEB]"],
    active: ["active:bg-[#E0E0E0]"],
    disabled: [
      "disabled:text-neutral-gray-30",
      "hover:bg-transparent",
      "active:bg-transparent",
    ],
  },
  navigationMode: {
    base: [
      "font-bold  font-['Open_Sans'] text-[14px] flex border-0 bg-transparent rounded-[4px] items-center py-[1px] pl-[6px] pr-[4px]",
    ],
    default: ["text-[#666666]"],
    hover: ["hover:bg-[#EBEBEB]"],
    active: ["active:bg-[#E0E0E0]"],
  },
  closeTimePicker: {
    default: [
      "text-[#999999] flex p-[6px] border-0 bg-transparent rounded-[4px] items-center ml-auto",
    ],
    hover: ["hover:bg-[#666666]"],
  },
};

const schema = schemaGenerator(dictionary);

export default schema;
