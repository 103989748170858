import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Selection({
  onValueChange,
  selecionHeight = "",
  headding = "",
  SelectionColor = true,
  rounded = "2px",
  setIsActive,
  list = [],
  initialSelection,
  flowWidth = false,
  above = false,
  value = 1,
  isFunctional = false,
  check = true,
  positionLeft = true,
  isDownload = false,
  isDoenloading = false,
  globalSelected,
  setGlobalSelected = () => {},
}) {
  const [selected, setSelected] = useState({});

  useEffect(() => {
    if (selected?.name === "None" || !selected) {
      setIsActive(false);
    } else {
      setIsActive(!false);
    }

    onValueChange(selected?.name?.split("+")[0]);
    setGlobalSelected(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, list]);

  useEffect(() => {
    let selected = globalSelected || list[initialSelection];
    //if (value !== 1) selected = list[value];

    setSelected(selected);
    setGlobalSelected(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSelection, list]);

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className=" relative">
            <Listbox.Button
              className={`rounded-[${rounded}]  w-full  flex justify-between gap-2 cursor-default  h-9 bg-[#F5F7F7] items-center border-[1px] border-[#E0E0E0]   text-[14px] font-[400] leading-[20px] text-[#666666]  `}
            >
              <span className="flex items-center">
                <span className="ml-3 block truncate">{` ${headding}  ${
                  isFunctional
                    ? ""
                    : globalSelected?.name || selected?.name || list[0]?.name
                }`}</span>
              </span>
              {isDoenloading && isDownload && (
                <div className="w-4 h-4 border-2  border-solid  border-t-blue-500  border-[#F27935] rounded-full animate-spin"></div>
              )}

              <span className="pointer-events-none  inset-y-0 right-0  flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className={
                above
                  ? "absolute mb-1 w-full rounded-md bg-white shadow-lg bottom-full"
                  : ""
              }
            >
              <Listbox.Options
                className={
                  flowWidth
                    ? `selectDropDown min-w-200 ${
                        positionLeft ? "" : "right-0"
                      }`
                    : `selectDropDown w-full ${positionLeft ? "" : "right-0"}`
                }
              >
                {list?.map((person) => (
                  <Listbox.Option
                    key={person.id}
                    className={({ active }) =>
                      classNames(
                        active && SelectionColor
                          ? "bg-[#F27935] text-white"
                          : "text-[#666666]",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <div
                          className="flex items-center"
                          onClick={
                            person.clickableFunction
                              ? person.clickableFunction
                              : () => {}
                          }
                        >
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "ml-3 block truncate"
                            )}
                          >
                            {person.name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active && SelectionColor
                                ? "text-white"
                                : "text-[#F27935]",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            {check && (
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
