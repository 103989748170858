import React, { useRef, useState, useEffect } from "react";

import PropTypes from "prop-types";
import moment from "moment";

import { formatDate } from "../DateSelector/utils/helpers";
import { DATE_FORMAT } from "../DateSelector/utils/constants";

import { getValidDateFormats, isDateValid } from "./utils/helpers";
import { useClickOutside } from "../hookHelpers";

import DateSelector from "../DateSelector";

import calendarPositionClassNames from "./DatePicker.styles";
import { PrimaryButtons, SecondaryButtons } from "../common/buttons";
import { PrimaryInput } from "../common/inputFields";

const isDisabled = ({ date, minDate, maxDate }) => {
  const isBeforeMinDate =
    minDate && moment(date.validTemporary).isBefore(moment(minDate));
  const isAfterMaxDate =
    maxDate && moment(date.validTemporary).isAfter(moment(maxDate));
  return isBeforeMinDate || isAfterMaxDate || false;
};

/**
 * @summary DatePicker component. It's possible to enable up to 2 timepickers.
 * @param {string} value - The datepicker initial value. Provide a string in the following formats YYYY-MM-DD | YYYY-MM-DD HH:MM AM/PM | YYYY-MM-DD HH:MM AM/PM HH:MM AM/PM
 * @param {string} position - Provide the position of the calendar.
 * @param {func} onChange - It's triggered when the user clicks on "done" or outside the datepicker. It returns a string based on dateFormat. Default format is MM/DD/YYYY
 * @param {func} dateFormat - Provide a momentjs date format to override the default one (MM-DD-YYYY). See https://momentjs.com/docs/#/displaying/format/ for available formats.
 * @param {object} inputProps - This object will be spread on the trigger input (outside). Useful to apply custom HTML attributes.
 * @param {string} containerClassName - className for the outermost HTML element.
 * */
const DatePicker = (args) => {
  const {
    onChange,
    value,
    position,
    dateFormat,
    inputProps,
    containerClassName,
    minDate,
    maxDate,
    onValueChange,
    ...otherProps
  } = args;

  const [isOpen, setIsOpen] = useState(false);

  const initialDate = moment(value, getValidDateFormats(dateFormat)).isValid()
    ? value
    : "";

  // final:  Date shown on TextComboInput. "ValidTemporary" becomes "final" when the user clicks on the "Done" button or outside.
  // impureTemporary: User input. it might be wrong, correct or empty. Do not rely on it. It might be anything.
  // validTemporary: It's a valid impureTemporary date. Necessary to send only valid dates to the DateSelector component.
  const [date, setDate] = useState({
    final: initialDate,
    impureTemporary: initialDate,
    validTemporary: initialDate,
  });
  const disabled = isDisabled({ date, minDate, maxDate });
  const [error, setError] = useState(false);

  const datepickerRef = useRef(null);
  const oldValueRef = useRef(initialDate);

  const handleDone = () => {
    if (date.validTemporary !== "" && !isDateValid(date, dateFormat)) {
      setError(true);
    } else {
      setDate((oldDate) => ({
        ...oldDate,
        final: date.validTemporary,
      }));
      setIsOpen(false);
      setError(false);
    }
  };

  useClickOutside(datepickerRef, handleDone);

  useEffect(() => {
    // an object breaks the datepicker, and in consequence, the page
    if (typeof value !== "string") return;

    const validDate = moment(value, getValidDateFormats(dateFormat)).isValid()
      ? value
      : "";

    setDate({
      final: validDate,
      impureTemporary: validDate,
      validTemporary: validDate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (oldValueRef.current === date.final) return;
    oldValueRef.current = date.final;
    if (onChange) {
      onChange(date.final);
      onValueChange(formatDate(date.final, dateFormat));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date.final]);

  useEffect(() => {
    if (date.impureTemporary !== "" && isDateValid(date, dateFormat)) {
      setDate((oldDate) => ({
        ...oldDate,
        validTemporary: date.impureTemporary,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date.impureTemporary]);

  return (
    <div className={`flex flex-col relative ${containerClassName}`}>
      <PrimaryInput
        value={formatDate(date.final, dateFormat)}
        onClick={() => setIsOpen((oldValue) => !oldValue)}
        readOnly
        // trailing={
        //   <Calendar
        //     className={`mt-[4px] ${
        //       isOpen
        //         ? "text-theme-dateselector-secondary-color"
        //         : "text-neutral-gray-30"
        //     }`}
        //     size="l"
        //   />
        // }
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...inputProps}
        data-test-date-trigger
      />
      {isOpen && (
        <div
          ref={datepickerRef}
          className={`${calendarPositionClassNames(
            position
          )} bg-white fixed  z-[99999999] w-[240px] px-[12px] shadow-[0px_6px_20px_2px_rgba(51,51,51,0.16)] flex flex-col justify-center items-center`}
        >
          <DateSelector
            onChange={(dateSelectorState) => {
              setDate((oldDate) => ({
                ...oldDate,
                ...dateSelectorState, // necessary for edge case: leap year in date formats without year
                impureTemporary: dateSelectorState.formattedDate,
              }));
            }}
            value={date.validTemporary}
            error={error}
            dateFormat={dateFormat}
            minDate={minDate}
            maxDate={maxDate}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...otherProps}
          />
          <hr className="mt-0 mb-0 pt-[5px] pb-[5px] bg-white border-[#EBEBEB] w-full" />
          <div className="flex flex-row justify-between w-full bg-white pb-4">
            <SecondaryButtons
              className="mr-auto px-0"
              onClick={() => {
                setDate((oldDate) => ({
                  ...oldDate,
                  validTemporary: "",
                  impureTemporary: "",
                }));
              }}
              type="button"
              schema="default"
              size="small"
              data-test-clear
              label={"Clear"}
            />

            <PrimaryButtons
              onClick={handleDone}
              schema="primary"
              size="small"
              data-test-done
              disabled={disabled}
              label={"Done"}
            />
          </div>
        </div>
      )}
    </div>
  );
};

DatePicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  enableTimePicker: PropTypes.bool,
  enableEndTimePicker: PropTypes.bool,
  dateFormat: PropTypes.string,
  enableDateInput: PropTypes.bool,
  position: PropTypes.oneOf(["default", "bottom", "bottom-left"]),
  // eslint-disable-next-line react/forbid-prop-types
  inputProps: PropTypes.object,
  containerClassName: PropTypes.string,
};

DatePicker.defaultProps = {
  value: moment().format(DATE_FORMAT),
  onChange: () => {},
  enableTimePicker: false,
  enableEndTimePicker: false,
  dateFormat: "",
  enableDateInput: true,
  position: "default",
  inputProps: {},
  containerClassName: "",
};

export default DatePicker;
